
import { SET_LEAGUES_RANKINGS, SET_LEAGUE_RANKING } from "./actionTypes";
import { BASE_URL } from '../../Common/config/config';
import { authGetToken } from '../../Common/actions/token';
import { setError } from '../../Common/actions/errors';

const processIcons = rankings => {
  rankings.forEach(ranking => {
        if (ranking.change === 'down') {
          ranking.iconName = 'md-arrow-dropdown';
          ranking.color = 'red';
        } else if (ranking.change === 'up') { 
          ranking.iconName = 'md-arrow-dropup';
          ranking.color = 'green';
        } else if (ranking.change === 'same') { 
          ranking.iconName = 'md-arrow-dropright';
          ranking.color = 'orange';
        }
    });
}

export const getLeagueRanking = id => {
  return (dispatch, getState) => {
    dispatch(authGetToken())
    .catch(err => {
        console.log('No auth token exists!');
        alert("Please login again, your session is expired!");
    })
    .then(token => {
        let page = 1;
        if (getState().leaguesRankings.currentLeagueId === id)
           page = getState().leaguesRankings.currentPage + 1;
        return fetch(BASE_URL + '/users/leagues/'+id+'/rankings?page='+page, {
            headers: {
                "Content-Type": "application/json; charset=utf-8",
                "Authorization": token
            }
        });
    })
    .catch(err => console.log(err))
    .then(res => res.json())
    .then(res => {
      if (res.has_error && res.status === 503) {
        dispatch(setError('Кръга е затворен!', 503));
        return;
      }

      processIcons([res.data.loggedUser]);
      processIcons(res.data.rankings);
      dispatch(setLeagueRanking(res.data, id))
    });
  };
}

export const setLeagueRanking = (leagueRanking, id) => {
  return {
    type: SET_LEAGUE_RANKING,
    leagueRanking,
    id
  };
};

export const getLeaguesRankings = () => {
  return dispatch => {
    dispatch(authGetToken())
    .catch(err => {
        console.log('No auth token exists!');
        alert("Please login again, your session is expired!");
    })
    .then(token => {
        return fetch(BASE_URL + '/users/leagues', {
            headers: {
                "Content-Type": "application/json; charset=utf-8",
                "Authorization": token
            }
        });
    })
    .catch(err => console.log(err))
    .then(res => res.json())
    .then(res => {
      if (res.has_error && res.status === 503) {
        dispatch(setError('Кръга е затворен!', 503));
        return;
      }

      processIcons(res.data);
      dispatch(setLeaguesRankings(res.data))
    });
  };
}

export const setLeaguesRankings = leaguesRankings => {
  return {
    type: SET_LEAGUES_RANKINGS,
    leaguesRankings
  };
};