import { LEAGUE_CREATED, LEAGUE_JOINED } from "./actionTypes";
import { BASE_URL, CHAMPIONSHIP_ID } from '../../Common/config/config';
import { authGetToken } from '../../Common/actions/token';
import { getLeaguesRankings } from '../actions/leaguesRankings';
import { setError } from '../../Common/actions/errors';

export const joinLeague = code => {
  return dispatch => {
    dispatch(authGetToken())
    .catch(err => {
        console.log('No auth token exists!');
        alert("Please login again, your session is expired!");
    })
    .then(token => {
        return fetch(BASE_URL + '/users/leagues/private/join', {
            method: 'POST',
            headers: {
                "Content-Type": "application/json; charset=utf-8",
                "Authorization": token
            },
            body: JSON.stringify({ code, championship_id: CHAMPIONSHIP_ID })
        });
    })
    .catch(err => console.log(err))
    .then(res => res.json())
    .then(res => {
      if (res.has_error && res.status === 503) {
        dispatch(setError('Кръга е затворен!', 503));
        return;
      }

      dispatch(leagueJoined(res.message));
      dispatch(getLeaguesRankings());
    });
  };
}

export const leagueJoined = message => {
  return {
    type: LEAGUE_JOINED,
    message
  };
};



export const createLeague = name => {
  return dispatch => {
    dispatch(authGetToken())
    .catch(err => {
        console.log('No auth token exists!');
        alert("Please login again, your session is expired!");
    })
    .then(token => {
        return fetch(BASE_URL + '/users/leagues', {
            method: 'POST',
            headers: {
                "Content-Type": "application/json; charset=utf-8",
                "Authorization": token
            },
            body: JSON.stringify({league_name: name})
        });
    })
    .catch(err => console.log(err))
    .then(res => res.json())
    .then(res => {
      if (res.has_error && res.status === 503) {
        dispatch(setError('Кръга е затворен!', 503));
        return;
      }

      dispatch(leagueCreated(res.data))
    });
  };
}

export const leagueCreated = league => {
  return {
    type: LEAGUE_CREATED,
    league
  };
};
