import React from 'react';
import { StyleSheet, Text, View, Image, Button, TouchableOpacity, ScrollView, Linking, StatusBar } from 'react-native';
import Playground from '../../Common/components/Playground';
import LoadingScreen from '../../Common/components/LoadingScreen';
import Background from '../../Common/components/Background';
import BudgetContainer from '../../Common/components/BudgetContainer';
import PlayerPopup from '../components/PlayerPopup';
import TabBar from '../../Common/components/TabBar';
import HeaderWithBack from '../../Common/components/HeaderWithBack';
import WildCardPopup from "../components/WildCardPopup";

import transferIcon from '../../assets/transfers-arrow.svg.png';
import { connect } from 'react-redux';
import { getTeam, useWildCard } from '../actions/team';
import { increaseBudget, decreaseBudget } from '../../Home/actions/home';
import { getAllTeams } from '../../Common/actions/index';


class Transfers extends React.Component {

  state = {
    selectedPlayer: null,
    selectedForSale: [],
    badTeamId: null,
    wildCardPopupOpen: false
  }

  static navigationOptions = {
    title: 'Трансфери',
  };

  componentDidMount() {
    this.props.getTeam();
    this.props.getAllTeams(window.localStorage.getItem("championshipId"));
  }

  componentDidUpdate(prevProps) {
    if (this.props.errorCode === 503) {
      this.props.navigation.navigate('Maintenance');
      return;
    }
    if (!this.props.championshipInfo) {
      this.props.navigation.navigate('Home');
      return null;
    }


    const { userTeam, championshipInfo } = this.props;
    if (prevProps.userTeam !== userTeam) {
      this.setState({ selectedForSale: [] });

      let allPlayers = [...userTeam.reserves];
      userTeam.groups.forEach(g => allPlayers = allPlayers.concat(g.players));

      let hasMoreThan3 = false;
      let badTeamId = null;
      allPlayers.forEach(p => {
        let count = 1;
        allPlayers.forEach(p1 => {
          if (p !== p1 && p.teamId === p1.teamId) count++;
        });

        if (count > championshipInfo.user_team_max_players_from_same_team) {
          hasMoreThan3 = true;
          badTeamId = p.teamId;
        }
      });
      if (badTeamId !== null) {
        alert("Имате повече от 3-ма играчи от 1 и същ отбор! Ще можете да трансферирате само тях!");
      }
      this.setState({ badTeamId });
    }
    if (this.props.navigation !== prevProps.navigation) {
      this.props.getTeam();
      this.props.getAllTeams();
    }

  }

  showPlayerPopup = player => {
    const { badTeamId } = this.state;
    if (badTeamId && player.teamId !== badTeamId) {
      const badTeamName = this.props.realTeams.find(t => t.id === badTeamId).name;
      alert("Можете да избирате само играчи от " + badTeamName + " !");
      return;
    }
    this.setState({ selectedPlayer: player })
  }

  hidePlayerPopup = () => {
    this.setState({ selectedPlayer: null })
  }

  onSelectForSale = () => {
    if (!this.isForCancel()) {
      if (this.state.selectedForSale.length >= 2) {
        alert("Максимум до 2-ма играчи за трансфер наведнъж!");
        return;
      }
      this.setState(prevState => {
        this.props.increaseBudget(prevState.selectedPlayer.price);
        return {
          selectedForSale: [...prevState.selectedForSale, prevState.selectedPlayer],
          selectedPlayer: null,
        }
      });
    } else {
      this.setState(prevState => {
        this.props.decreaseBudget(prevState.selectedPlayer.price);
        return {
          selectedForSale: prevState.selectedForSale.filter(p => p.playerId !== prevState.selectedPlayer.playerId),
          selectedPlayer: null,
        }
      });
    }
  }

  onSell = () => {
    // this.props.navigation.navigate('MakeTransfer', {
    //   selectedForSale: this.state.selectedForSale,
    //   budget: this.props.budget
    // });
    // this.setState({selectedForSale: []});
    window.sessionStorage.setItem('forSale', JSON.stringify(this.state.selectedForSale));
    this.props.navigation.navigate('MakeTransfer');
    this.setState({ selectedForSale: [] });
  }

  isForCancel = () => this.state.selectedForSale.find(p => p.playerId === this.state.selectedPlayer.playerId);
  onUseWildCard = () => this.props.useWildCard();

  render() {
    if (!this.props.userTeam) return <LoadingScreen />;
    if (!this.props.championshipInfo) {
      this.props.navigation.navigate('Home');
      return null;
    }

    const ads = this.props.ads.filter(ad => ad.position.id === 7 || ad.position.id === 8);

    let wildCardStyle = styles.headerButton;
    let wildCardText = 'УАЙЛДКАРД';
    let canUseWildCard = true;

    if (this.props.userTeam.numberOfWildCards === 0 &&
      !this.props.userTeam.isWildCardActivated) {
      wildCardStyle = { ...wildCardStyle, backgroundColor: '#BA1C19', borderWidth: 0 };
      wildCardText = 'ИЗПОЛЗВАН';
      canUseWildCard = false;
    }

    if (this.props.userTeam.isWildCardActivated) {
      wildCardStyle = { ...wildCardStyle, backgroundColor: '#479D2D', borderWidth: 0 };
      wildCardText = 'АКТИВИРАН';
      canUseWildCard = false;
    }

    return (
      <ScrollView contentContainerStyle={styles.container} style={styles.scrollView}>
        <Background />
        <HeaderWithBack title={"Трансфери"} hideBack={true} navigation={this.props.navigation} />
        {this.state.selectedPlayer &&
          <PlayerPopup playerId={this.state.selectedPlayer.playerId}
            onDismiss={this.hidePlayerPopup}
            navigation={this.props.navigation}
            onConfirm={this.onSelectForSale}
            buttonText={this.isForCancel() ? "ОТКАЖИ" : "ПРОДАЙ"} />
        }
        {this.state.wildCardPopupOpen &&
          <WildCardPopup
            onDismiss={() => this.setState({ wildCardPopupOpen: false })}
            onConfirm={() => {
              this.setState({ wildCardPopupOpen: false });
              this.onUseWildCard();
            }}
          />
        }
        <View style={styles.headerView}>
          <View style={styles.statContainer}>
            <View style={styles.statWithIcon}>
              {//<Image source={transferIcon} style={styles.headerIcon} />
              }
              <Text style={styles.statText}>{this.props.userTeam.isWildCardActivated ? 'неограничени' : this.props.userTeam.availableTransfers}</Text>
            </View>
            <Text style={styles.statDescription}>Трансфери</Text>
          </View>
          {!this.props.userTeam.isWildCardActivated &&
            <View style={styles.statContainer}>
              <View style={styles.statWithIcon}>
                <Text style={styles.statText}>{this.props.userTeam.penaltyPoints}</Text>
              </View>
              <Text style={styles.statDescription}>Наказателни точки</Text>
            </View>}
          <BudgetContainer budget={this.props.budget} />
        </View>
        <View style={styles.pointsHeaderView}>
          <View style={styles.roundContainer}>
            <Text style={styles.roundText}> {this.props.userTeam.roundName} </Text>
          </View>

          <TouchableOpacity style={wildCardStyle} onPress={() => canUseWildCard ? this.setState({ wildCardPopupOpen: true }) : null}>
            <Text style={styles.buttonText}>
              {wildCardText}
            </Text>
          </TouchableOpacity>

          {this.state.selectedForSale.length > 0 &&
            <TouchableOpacity
              style={[styles.headerButton, { backgroundColor: '#E1CB4C', borderColor: '#EDDE81' }]}
              onPress={this.onSell}>
              <Text style={[styles.buttonText, { color: 'black' }]}>
                ПРОДАЙ
                </Text>
            </TouchableOpacity>
          }
        </View>

        <Playground
          onSelectPlayer={this.showPlayerPopup}
          selectedPlayers={this.state.selectedForSale}
          {...this.props.userTeam}
          showPrice={true}
          isReady={true}
          ads={ads} />
        <TabBar navigation={this.props.navigation} />
      </ScrollView>
    );
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getTeam: () => dispatch(getTeam()),
    useWildCard: () => dispatch(useWildCard()),
    decreaseBudget: sum => dispatch(decreaseBudget(sum)),
    increaseBudget: sum => dispatch(increaseBudget(sum)),
    getAllTeams: (id) => dispatch(getAllTeams(id))
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    homeInfo: state.home.info,
    userTeam: state.transfers.userTeam,
    budget: state.home.info ? state.home.info.budget : 0,
    championshipInfo: state.championship.championshipInfo,
    realTeams: state.realTeams.realTeams,
    errorCode: state.errors.code,
    ads: state.ads.ads
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Transfers);

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  scrollView: {
    width: '100%',
  },
  headerView: {
    height: 70,
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-around',
    backgroundColor: '#CCD4E1'
  },
  headerText: {
    fontSize: 17
  },
  headerIcon: {
    width: 20,
    height: 20,
    marginRight: 2
  },
  statWithIcon: {
    flexDirection: 'row',
  },
  statContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1
  },
  statText: {
    fontWeight: 'bold',
    fontSize: 18
  },
  statDescription: {
    fontSize: 14,
    textAlign: 'center'
  },
  pointsHeaderView: {
    height: 70,
    width: '100%',
    backgroundColor: 'rgba(0,0,0,0.5)',
    position: 'absolute',
    top: 120,
    zIndex: 2,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-around'
  },
  roundContainer: {
    flexDirection: 'row',
    width: 90
  },
  roundText: {
    color: 'white',
    fontSize: 20
  },
  headerButton: {
    padding: 10,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#2053A4',
    borderRadius: 10,
    borderColor: '#3474D5',
    borderWidth: 1
  },
  buttonText: {
    color: 'white',
    fontWeight: 'bold',
    fontSize: 15
  }
});
