export const TRY_AUTH = "TRY_AUTH";

export const SEND_EMAIL = "SEND_EMAIL";
export const MAIL_SENT = "MAIL_SENT";

export const AUTH_SET_TOKEN = "AUTH_SET_TOKEN";

export const REGISTER_USER = "REGISTER_USER";
export const USER_READY = "USER_READY";
export const JOIN_CHAMPIONSHIP = "JOIN_CHAMPIONSHIP";
export const REMOVE_JOIN = "REMOVE_JOIN";