import React from 'react';
import { StyleSheet, Image, Dimensions, View } from 'react-native';
import Background from './Background';
import loadingBgImage from '../../assets/loading-screen.gif';

const loadingScreen = (props) => {
    return (
        <View style={styles.container}>
           <Image style={[styles.loadingImage, props.style]} source={loadingBgImage} />
        </View>
    );
}

const styles = StyleSheet.create({
  container: {
    zIndex: 200,
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    alignItems: "center",
    justifyContent: 'center',
    width: '100%',
    height: "100vh",
    backgroundColor: 'black'
  },
  loadingImage: {
    width: "50%",
    height: "50%",
    resizeMode: 'contain'
  }
});

export default loadingScreen;