import { createStore, combineReducers, compose, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import authReducer from "../Login/reducers/auth";
import adsReducer from "../Common/reducers/ads";
import registerReducer from '../Register/reducers/register';
import uiReducer from "../Common/reducers/ui";
import realTeamsReducer from "../Common/reducers/teams";
import errorsReducer from "../Common/reducers/errors";
import championshipReducer from '../Login/reducers/championship';
import userTeamReducer from '../Team/reducers/team';
import playerInfoReducer from '../Transfers/reducers/players';
import teamPlayerInfoReducer from '../Team/reducers/players';
import homeReducer from '../Home/reducers/home';
import pointsReducer from '../Points/reducers/team';
import roundsReducer from '../Points/reducers/rounds';
import transfersReducer from '../Transfers/reducers/team';
import resultsReducer from '../Results/reducers/results';
import leagueTableReducer from '../LeagueTable/reducers/leagueTable';
import positionsReducer from '../Stats/reducers/positions';
import leaguesRankingsReducer from '../Leagues/reducers/leaguesRankings';
import ownLeaguesReducer from '../Leagues/reducers/ownLeagues';
import userProfileReducer from '../EditProfile/reducers/userProfile';
import invitationsReducer from '../InviteFriend/reducers/invitations';
import newsReducer from '../News/reducers/news';

const rootReducer = combineReducers({
  realTeams: realTeamsReducer,
  auth: authReducer,
  register: registerReducer,
  ads: adsReducer,
  ui: uiReducer,
  errors: errorsReducer,
  championship: championshipReducer,
  userTeam: userTeamReducer,
  playerInfo: playerInfoReducer,
  teamPlayerInfo: teamPlayerInfoReducer,
  home: homeReducer,
  points: pointsReducer,
  rounds: roundsReducer,
  transfers: transfersReducer,
  results: resultsReducer,
  leagueTable: leagueTableReducer,
  positions: positionsReducer,
  leaguesRankings: leaguesRankingsReducer,
  ownLeagues: ownLeaguesReducer,
  userProfile: userProfileReducer,
  invitations: invitationsReducer,
  news: newsReducer
});


const configureStore = () => {
  return createStore(rootReducer, compose(applyMiddleware(thunk)));
};

export default configureStore;
