import { SET_NEWS } from "./actionTypes";

export const getNews =  (category) => {
  return async (dispatch, getState) => {
    let url = "https://gong.bg/_api_whiz?key=09hfFDonIW4PMZQowaJEGV6uqhNkCcRz&action=get_items&cat_id=73&links=1";
    if (category === 2)   
        url = "https://gong.bg/_api_whiz?key=09hfFDonIW4PMZQowaJEGV6uqhNkCcRz&action=get_items&cat_id=11&links=1";
    if (category === 3) {  
        const userTeam = getState().home.info.favouriteTeamName; 
        // const userTeam = 'Лудогорец';
        let allCategories = await fetch('https://gong.bg/_api_whiz?key=09hfFDonIW4PMZQowaJEGV6uqhNkCcRz&action=get_categories&cat_id=1000000').then(res => res.json());
        allCategories = allCategories.results_array;
        let id = "1000001";
        let userCategory = allCategories.find(c => {
            return c.title.toLowerCase().includes(userTeam.toLowerCase()) || 
                    userTeam.toLowerCase().includes(c.title.toLowerCase());
        });
        if (userCategory) {
            id = userCategory.id;
        }
        url = "https://gong.bg/_api_whiz?key=09hfFDonIW4PMZQowaJEGV6uqhNkCcRz&action=get_items&links=1&cat_id="+id;
    }
    console.log(url);
    fetch(url, {
        headers: {
            "Content-Type": "application/json; charset=utf-8",
        }
    })
    .catch(err => console.log(err))
    .then(res => res.json())
    .then(res => {
      const results = res.results_array.map(r => {
          return {
              id: r.id,
              link: r.url,
              imageUrl: res.root + r.i,
              title: r.t
          }
      });
      
      dispatch(setNews(results))
    });
  };
}

export const setNews = news => {
  return {
    type: SET_NEWS,
    news
  };
};