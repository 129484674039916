import React from 'react';
import { StyleSheet, Text, View, Image, TouchableOpacity, ScrollView, StatusBar } from 'react-native';
import Background from '../../Common/components/Background';
import HeaderWithBack from '../../Common/components/HeaderWithBack';

class About extends React.Component {

    render() {
        return (
            <ScrollView style={styles.scrollView} contentContainerStyle={styles.container}>
                <Background />
                <HeaderWithBack title="За приложението" navigation={this.props.navigation} />
                <Text style={styles.headingText}>За приложението</Text>
                <View style={styles.aboutContainer}>
                    <Text style={styles.aboutText}>
                        BAMF Fantasy Manager е виртуален футболен мениджър отразяващ реалното представяне на
                        състезателите в лигите на БАМФ София. Започваме с &quot;Mussala Premier league&quot; и &quot;Mussala Super
                        Premier league &quot; на ИТ Лигата. Мениджърите селектират свой собствен виртуален отбор от
                        футболистите, картотекирани в различните отбори от съответнат дивизия, и събират точки на база
                        показателите им в реалните мачове от кръга. След всеки изминал кръг мениджърите имат право
                        на трансфери според правилата на играта. Води се генерално класиране с награди за тримата
                        призьори и седмична награда с победител на кръга за всеки отделен шампионат.
                </Text>
                    <Text style={styles.aboutText}>
                        Очаквайте скоро за Android и IOS.
                </Text>
                    <Text style={styles.aboutText}>
                        Регистрирай се, съревновавай се с другите мениджъри и спечели много футболни награди.
                </Text>
                    {/* <Text style={styles.version}>Версия: 3.0</Text> */}
                </View>
            </ScrollView>
        )
    }
}

export default About;

const styles = StyleSheet.create({
    scrollView: {
        width: '100%',
        height: '100%'
    },
    container: {
        width: '100%',
        minHeight: '100%',
        alignItems: 'center',
    },
    headingText: {
        fontWeight: 'bold',
        color: 'white',
        fontSize: 24,
        marginBottom: 12,
        marginTop: 50,
        marginLeft: 15,
        width: '100%'
    },
    aboutContainer: {
        backgroundColor: '#E7EAF9',
        width: '100%',
        padding: 10,
    },
    aboutText: {
        fontSize: 17,
        marginVertical: 15
    },
    version: {
        fontWeight: 'bold',
        fontSize: 17,
    }
});