import { 
  SET_LEAGUES_RANKINGS, 
  SET_LEAGUE_RANKING,
  LEAGUE_JOINED
} from "../actions/actionTypes";
 
const initialState = {
    leaguesRankings: null,
    joinedLeagueMessage: null,
    leagueRanking: [],
    currentPage: 0,
    currentLeagueId: 0,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {

    case SET_LEAGUES_RANKINGS:
      return {
        ...state,
        leaguesRankings: action.leaguesRankings
      };

    case SET_LEAGUE_RANKING:
      let leagueRanking = {...state.leagueRanking};
      leagueRanking.name = action.leagueRanking.name;
      if (action.id === state.currentLeagueId) {
        leagueRanking.rankings = [...state.leagueRanking.rankings, ...action.leagueRanking.rankings];
      } else {
        leagueRanking = action.leagueRanking;
      }
      return {
        ...state,
        leagueRanking,
        currentLeagueId: action.id,
        currentPage: action.id === state.currentLeagueId ? state.currentPage+1 : 1
      };
      
    case LEAGUE_JOINED:
      return {
        ...state,
        joinedLeagueMessage: action.message
      };

    default:
      return state;
  }
};

export default reducer;