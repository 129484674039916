import React from 'react';
import { 
  StyleSheet,
  Text, 
  View, 
  Image, 
  TouchableOpacity 
} from 'react-native';
import Button from '../../Common/components/Button';
import Input from '../../Common/components/Input';
import InfoPopup from '../../Home/components/InfoPopup';
import Background from '../../Common/components/Background';
import HeaderWithBack from '../../Common/components/HeaderWithBack';

import logo from '../../assets/login-bam-logo.png';
import emailIcon from '../../assets/email.png';
import passIcon from '../../assets/pass.png';

import { connect } from 'react-redux';
import { resetPassword } from '../actions/auth';

class ForgottenPassword extends React.Component {

  state = {
    email : '',
    showSuccessPopup: false
  }

  onSendPassword = () => {
    this.props.onPasswordReset(this.state.email); 
  }

  componentDidUpdate(prevProps) {
        if (prevProps.message !== this.props.message) {            
            this.setState({showSuccessPopup : true})
        }
    }

  render() {
    return (
      <View style={styles.container}>
         <InfoPopup title={this.props.message && this.props.message.toUpperCase()}
                isVisible={this.state.showSuccessPopup} 
                onDismiss={() => this.setState({showSuccessPopup: false}) }/>
        <Background />
        <HeaderWithBack title={"ЗАБРАВЕНА ПАРОЛА"} navigation={this.props.navigation} 
            style={{top: 0, left: 0, position: 'absolute'}} />

        <Image style={styles.logo} source={logo} />

        <View style={styles.formContainer}>
            <Input placeholder='Имейл адрес'                         
                image={emailIcon}
                keyboardType='email-address'
                onChangeText={(val) => this.setState({email: val})}
                value={this.state.email}
                />

            <Button title='ИЗПРАТИ' style={styles.loginButton} onPress={this.onSendPassword} />
        </View>
      </View>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    message: state.errors.error
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onPasswordReset: email => dispatch(resetPassword(email))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ForgottenPassword);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center'
  },
  logo: {
    width: '60%',
    height: 140,
    resizeMode: 'contain'
  },
  loginButton: {
    width: '100%',
    marginTop: 40
  },
  formContainer: {
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '85%',
      marginTop: 40
  },
  forgottenPasswordText: {
    marginTop: 10,
    color: '#EAB845',
    fontSize: 20,
    fontWeight: 'bold'
  }
});
