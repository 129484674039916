import { TRY_AUTH, AUTH_SET_TOKEN, MAIL_SENT } from "./actionTypes";
import { BASE_URL } from '../../Common/config/config';
import { uiStartLoading, uiStopLoading } from "../../Common/actions/ui";
import { setError } from '../../Common/actions/errors';
import { AsyncStorage } from 'react-native';


export const resetPassword = email => {
  return dispatch => {
    fetch(BASE_URL + '/users/forgotpass', {
      method: "POST",
      body: JSON.stringify({ email }),
      headers: {
        "Content-Type": "application/json"
      }
    })
    .catch(err => {
      console.log(err);
      dispatch(setError('Грешен имейл!'));
      dispatch(uiStopLoading());
    })
    .then(async (res) => {
      dispatch(setError(res.message));
    });
  };
}

export const tryAuth = authData => {
  return dispatch => {
    dispatch(uiStartLoading());
    fetch(BASE_URL + '/auth/login', {
      method: "POST",
      body: JSON.stringify(authData),
      headers: {
        "Content-Type": "application/json"
      }
    })
    .catch(err => {
      console.log(err);
      dispatch(setError('* Грешен мейл или парола'));
      dispatch(uiStopLoading());
    })
    .then(async (res) => {      
      dispatch(uiStopLoading());        
      if (!res || !res.headers.get('authorization')) {
        dispatch(setError('* Грешен мейл или парола'));
      } else {
        await AsyncStorage.setItem('token', res.headers.get('authorization'));
        dispatch(authSetToken(res.headers.get('authorization')));
      }
    });
  };
};

export const mailSent = () => {
  return {
    type: MAIL_SENT
  };
};

export const authSetToken = token => {
  return {
    type: AUTH_SET_TOKEN,
    token: token
  };
};