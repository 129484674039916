import React from "react";
import {
  StyleSheet,
  Text,
  View,
  Image,
  Button,
  TouchableOpacity,
  ScrollView,
  Linking,
  StatusBar,
  FlatList,
  Dimensions
} from "react-native";
import EStyleSheet from 'react-native-extended-stylesheet';
import PlayerPopup from "../components/PlayerPopup";
import PlayerView from "../../Common/components/PlayerView";
import LoadingScreen from "../../Common/components/LoadingScreen";
import BudgetContainer from "../../Common/components/BudgetContainer";
import budgetIcon from "../../assets/budget-transfers.png";
import okIcon from "../../assets/approve-transfer.svg.png";
import cancelIcon from "../../assets/cancel-transfer.svg.png";
import banIcon from '../../assets/banned-player.svg.png';
import injuredIcon from '../../assets/injured-player.svg.png';
import transferedIcon from '../../assets/transfered-player.svg.png';
import MdArrowDropdown from 'react-ionicons/lib/MdArrowDropdown';
import PickerListPopup from '../../Common/components/PickerListPopup';

import { connect } from "react-redux";
import {
  getAvailablePlayersForTransfer,
  removeAvailablePlayerForTransfer,
  restoreAvailablePlayerForTransfer,
  makeTransfer
} from "../actions/players";
import { getAllTeams } from "../../Common/actions/teams";
import { increaseBudget, decreaseBudget } from "../../Home/actions/home";

class MakeTransfer extends React.Component {
  state = {
    currentTeam: { key: 0, label: "Всички отбори" },
    currentSort: null,
    selectedForBuy: [],
    selectedIndex: 0
  };

  static navigationOptions = {
    title: "Трансфери"
  };

  refreshPlayer() {
    const selectedForSale = JSON.parse(window.sessionStorage.getItem('forSale'));
    this.props.getAvailablePlayersForTransfer(
      selectedForSale[this.state.selectedIndex].positionId, null, null, window.localStorage.getItem("championshipId")
    );
  }

  componentDidMount() {
    if (!this.props.userTeam) {
      this.props.navigation.navigate('Home');
      return;
    }
    this.props.getAllRealTeams(window.localStorage.getItem("championshipId"));
    this.refreshPlayer();
  }

  onSelectForBuy = () => {
    const selectedForSale = JSON.parse(window.sessionStorage.getItem('forSale'));

    if (this.state.selectedForBuy.length >= selectedForSale.length) {
      alert("Позволен брой играчи " + selectedForSale.length);
      return;
    }

    let playerToBuy = {
      ...this.props.availablePlayers.find(
        p => p.playerId === this.state.selectedPlayerId
      )
    };
    playerToBuy.name = playerToBuy.name.split(" ")[1];
    let countOfSameTeam = 0;
    this.props.userTeam.groups.forEach(g => {
      g.players.forEach(p => {
        if (p && p.teamId === playerToBuy.teamId) countOfSameTeam++;
      });
    });
    this.props.userTeam.reserves.forEach(p => {
      if (p && p.teamId === playerToBuy.teamId) countOfSameTeam++;
    });
    this.state.selectedForBuy.forEach(p => {
      if (p && p.teamId === playerToBuy.teamId) countOfSameTeam++;
    });
    selectedForSale.forEach(p => {
      if (p && p.teamId === playerToBuy.teamId) countOfSameTeam--;
    });

    if (
      countOfSameTeam >=
      this.props.championshipInfo.user_team_max_players_from_same_team
    ) {
      alert(
        "Максимум " +
          this.props.championshipInfo.user_team_max_players_from_same_team +
          "-ма играчи от един и същ отбор!"
      );
      return;
    }

    this.setState(prevState => {
      this.props.decreaseBudget(playerToBuy.price);
      playerToBuy.moints = playerToBuy.points;
      delete playerToBuy.points;
      return {
        selectedForBuy: [...prevState.selectedForBuy, playerToBuy],
        selectedPlayerId: null
      };
    });
    this.props.removeAvailablePlayerForTransfer(playerToBuy.playerId);
  };

  restorePlayer = player => {
    player.points = player.moints;
    this.props.restoreAvailablePlayerForTransfer(player);
    this.props.increaseBudget(player.price);

    this.setState(prevState => {
      return {
        selectedForBuy: prevState.selectedForBuy.filter(
          p => p.playerId != player.playerId
        ),
        selectedPlayerId: null
      };
    });
  };

  showPlayerPopup = player => {
    this.setState({ selectedPlayerId: player.playerId });
  };

  hidePlayerPopup = () => {
    this.setState({ selectedPlayerId: null });
  };

  getPlayerColor = shape => {
    if (shape > 75) {
      return "green";
    }
    if (shape > 50) {
      return "#A6EA9A";
    }
    if (shape > 25) {
      return "#efa42b";
    }
    return "red";
  };

  scrollHeader = event => {
    const xOffset = event.nativeEvent.contentOffset.x;
    this.headerRef.scrollTo({ x: xOffset, y: 0, animated: false });
  };

  scrollFixedColumn = event => {
    const yOffset = event.nativeEvent.contentOffset.y;
    this.fixedColumnRef.scrollToOffset({ offset: yOffset, animated: false });
  };

  scrollOtherColumns = event => {
    const yOffset = event.nativeEvent.contentOffset.y;
    this.statsColumnsRef.scrollToOffset({ offset: yOffset, animated: false });
  }

  filterByTeam = team => {
    const selectedForSale = JSON.parse(window.sessionStorage.getItem('forSale'));
    this.props.getAvailablePlayersForTransfer(
      selectedForSale[this.state.selectedIndex].positionId,
      team.key,
      null,
      window.localStorage.getItem("championshipId")
    );
    this.setState({ currentTeam: team, currentSort: null });
  };

  changeSort = criteria => {
    let { currentSort } = this.state;

    if (currentSort && currentSort.criteria == criteria) {
      currentSort = { ...currentSort };
      currentSort.order = currentSort.order == "asc" ? "desc" : "asc";
    } else {
      currentSort = { criteria: criteria, order: "asc" };
    }

    const selectedForSale = JSON.parse(window.sessionStorage.getItem('forSale'));
    this.props.getAvailablePlayersForTransfer(
      selectedForSale[this.state.selectedIndex].positionId,
      this.state.currentTeam.key,
      currentSort.criteria + (currentSort.order == "desc" ? "_desc" : ""),
      window.localStorage.getItem("championshipId")
    );
    this.setState({ currentSort });
  };

  getSortIcon = criteria => {
    if (!this.state.currentSort) return String.fromCharCode(9660);
    if (
      this.state.currentSort.criteria === criteria &&
      this.state.currentSort.order == "asc"
    ) {
      return String.fromCharCode(9650);
    }
    return String.fromCharCode(9660);
  };

  makeTransfer = () => {
    const selectedForSale = JSON.parse(window.sessionStorage.getItem('forSale'));
    if (this.state.selectedForBuy.length !== selectedForSale.length) {
      alert(
        "Броя на играчите, които купувате и продавате е различен"
      );
      return;
    }
    if (this.props.budget < 0) {
      alert(
        "Нямате достатъчно средства за осъществяване на трансфера"
      );
      return;
    }

    this.props.makeTransfer(selectedForSale, this.state.selectedForBuy);
    this.props.navigation.goBack();
  };

  changeSellIndex = i => this.setState({selectedIndex: i}, this.refreshPlayer);

  renderTableRow = ({ item }) => {
    return (
      <TouchableOpacity
        style={styles.tableRow}
        onPress={() => this.showPlayerPopup(item)}>
        <View style={styles.tableColumn}>
          <Text>{item.price}</Text>
        </View>
        <View style={styles.tableColumn}>
          <Text>{item.points}</Text>
        </View>
        <View style={styles.tableColumn}>
          <Text>{item.shape}</Text>
        </View>
        <View style={styles.tableColumn}>
          <Text>{item.chosenBy}%</Text>
        </View>
        <View style={styles.tableColumn}>
          <Text>{item.pointsLastRound}</Text>
        </View>
      </TouchableOpacity>
    );
  };

  renderPlayerName = ({ item }) => {
    return (
        <TouchableOpacity
        style={styles.tableRow}
        onPress={() => this.showPlayerPopup(item)}>
        <View style={styles.playerNameContainer}>
            <Image source={{ uri: item.iconUrl }} style={styles.iconStyle} />
            { item.isBanned && <Image source={banIcon} style={styles.specialIconStyle} /> }
            { item.isInjured && <Image source={injuredIcon} style={styles.specialIconStyle} /> }
            { item.isTransfered && <Image source={transferedIcon} style={styles.specialIconStyle} /> }

            <View style={styles.playerForm}>
              <Text>{item.name}</Text>
              {
              // <ProgressBar
              //     progress={item.shape / 100}
              //     width={100}
              //     borderWidth={0}
              //     color={this.getPlayerColor(item.shape)}
              //     unfilledColor="#B9C5DB"
              //     style={{ marginVertical: 10 }} />
              }
              <Text style={{ color: "#9497A7" }}>{item.team}</Text>
            </View>
        </View>
        </TouchableOpacity>
    );
  }

  render() {
    if (!this.props.availablePlayers) {
      return <LoadingScreen />;
    }
    if (!this.props.championshipInfo) {
      this.props.navigation.navigate('Home');
      return null;
    }
    const allTeams = [
      { key: 0, label: "Всички отбори" },
      ...this.props.allTeams.map(team => {
        return {
          key: team.id,
          label: team.name
        };
      })
    ];
    const selectedForSale = JSON.parse(window.sessionStorage.getItem('forSale'));
    return (
      <React.Fragment>
        {this.state.selectedPlayerId && (
          <PlayerPopup
            playerId={this.state.selectedPlayerId}
            onDismiss={this.hidePlayerPopup}
            navigation={this.props.navigation}
            onConfirm={this.onSelectForBuy}
            buttonText="КУПИ"/>
        )}
        <PickerListPopup
        data={allTeams} 
        open={this.state.teamsOpen}
        title='Избери отбор' 
        onClose={team => {this.setState({teamsOpen: false}); team && this.filterByTeam(team)}} />

        <View style={styles.container}>
          <View style={styles.headerView}>
            <View style={styles.teamPickContainer}>
                <TouchableOpacity style={styles.teamContainer} onPress={() => this.setState({teamsOpen: true})}>
                  <Text style={styles.headerText}>
                    {this.state.currentTeam.label}{" "}
                  </Text>
                  <MdArrowDropdown fontSize="17px" style={{marginRight: 5, marginTop: 3}} />
                </TouchableOpacity>
            </View>
            <BudgetContainer budget={this.props.budget} />
          </View>
          <View style={styles.transferContainer}>
            {selectedForSale.map((player, i) => (
              <PlayerView
                key={player.playerId}
                onPress={() => this.changeSellIndex(i)}
                containerStyle={{ width: 80, marginRight: 12 }}
                isForSale
                selected={this.state.selectedIndex === i}
                showPrice={true}
                iconStyle={styles.iconStyle}
                {...player}
              />
            ))}

            {this.state.selectedForBuy.map(player => (
              <PlayerView
                key={player.playerId}
                onPress={() => this.restorePlayer(player)}
                containerStyle={{ width: 80 }}
                isForBuy
                showPrice={true}
                canRemoveFromList={true}
                iconStyle={styles.iconStyle}
                {...player}
              />
            ))}
          </View>
          <View style={{ flexDirection: "row", width: "100%" }}>
            <TouchableOpacity
              style={styles.tableHeader}
              onPress={() => this.changeSort("name")}>
              <Text style={styles.playerNameHeader}>
                {"Име на играч" + this.getSortIcon("name")}
              </Text>
            </TouchableOpacity>
            <ScrollView
              style={styles.tableHeader}
              horizontal={true}
              scrollEnabled={false}
              contentContainerStyle={{alignItems: 'center'}}
              showsHorizontalScrollIndicator={false}
              ref={ref => (this.headerRef = ref)}>
              <TouchableOpacity onPress={() => this.changeSort("price")}>
                <Text style={styles.headerText}>
                  {"Цена" + this.getSortIcon("price")}
                </Text>
              </TouchableOpacity>
              <TouchableOpacity onPress={() => this.changeSort("points")}>
                <Text style={styles.headerText}>
                  {"Точки" + this.getSortIcon("points")}
                </Text>
              </TouchableOpacity>
              <TouchableOpacity onPress={() => this.changeSort("shape")}>
                <Text style={styles.headerText}>
                  {"Форма" + this.getSortIcon("shape")}
                </Text>
              </TouchableOpacity>
              <TouchableOpacity onPress={() => this.changeSort("chosenBy")}>
                <Text style={styles.headerText}>
                  {"Избран от" + this.getSortIcon("chosenBy")}
                </Text>
              </TouchableOpacity>
              <TouchableOpacity
                onPress={() => this.changeSort("pointsLastRound")}>
                <Text style={styles.headerText}>
                  {"Точки П.К." + this.getSortIcon("pointsLastRound")}
                </Text>
              </TouchableOpacity>
            </ScrollView>
          </View>
          <View>
            <View style={{width: '100%', flexDirection: 'row', height: 700}}>
              <FlatList
                  data={this.props.availablePlayers.filter(
                      p =>!this.state.selectedForBuy.some(p1 => p1.playerId === p.playerId)
                  )}
                  renderItem={this.renderPlayerName}
                  style={{width: 650, marginBottom: 50, height: '100%'}}
                  ref={ref => (this.fixedColumnRef = ref)}
                  onScroll={this.scrollOtherColumns}
                  onScrollEndDrag={this.scrollOtherColumns}
                  scrollEventThrottle={0}
                  showsVerticalScrollIndicator={false}
                  keyExtractor={(item, index) => "" + item.playerId}/>
              <ScrollView
                  horizontal={true}
                  onScroll={this.scrollHeader}
                  onScrollEndDrag={this.scrollHeader}
                  scrollEventThrottle={160}>
                  <FlatList                      
                      data={this.props.availablePlayers.filter(
                          p =>!this.state.selectedForBuy.some(p1 => p1.playerId === p.playerId)
                      )}
                      style={{marginBottom: 50}}
                      scrollEnabled={false}
                      renderItem={this.renderTableRow}
                      keyExtractor={(item, index) => "" + item.playerId}
                      ref={ref => (this.statsColumnsRef = ref)}
                      />
              </ScrollView>
            </View>
          </View>

          <View style={styles.buttons}>
            <TouchableOpacity
              style={[
                styles.buttonContainer,
                { borderRightWidth: 1, borderColor: "white" }
              ]}
              onPress={() => this.props.navigation.goBack()}>
              <Image source={cancelIcon} style={styles.icon} />
              <Text style={styles.buttonText}>ОТМЕНИ</Text>
            </TouchableOpacity>
            <TouchableOpacity
              style={styles.buttonContainer}
              onPress={this.makeTransfer}>
              <Text style={styles.buttonText}>ПОТВЪРДИ</Text>
              <Image source={okIcon} style={styles.icon} />
            </TouchableOpacity>
          </View>
        </View>
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getAllRealTeams: (id) => dispatch(getAllTeams(id)),
    getAvailablePlayersForTransfer: (positionId, teamId, sortBy, championshipId) =>
      dispatch(getAvailablePlayersForTransfer(positionId, teamId, sortBy, null, championshipId)),
    removeAvailablePlayerForTransfer: playerId =>
      dispatch(removeAvailablePlayerForTransfer(playerId)),
    restoreAvailablePlayerForTransfer: player =>
      dispatch(restoreAvailablePlayerForTransfer(player)),
    makeTransfer: (oldPlayers, newPlayers) =>
      dispatch(makeTransfer(oldPlayers, newPlayers)),
    decreaseBudget: sum => dispatch(decreaseBudget(sum)),
    increaseBudget: sum => dispatch(increaseBudget(sum))
  };
};

const mapStateToProps = (state, ownProps) => {
  return {
    homeInfo: state.home.info,
    allTeams: state.realTeams.realTeams,
    userTeam: state.transfers.userTeam,
    availablePlayers: state.transfers.availablePlayers,
    budget: state.home.info ? state.home.info.budget : 0,
    championshipInfo: state.championship.championshipInfo
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MakeTransfer);

const styles = EStyleSheet.create({
  container: {
    justifyContent: "space-between",
    width: "100%",
    height: Dimensions.get('window').height,
    overflow: 'hidden'
  },
  headerView: {
    height: 70,
    width: "100%",
    flexDirection: "row",
    justifyContent: "flex-start",
    backgroundColor: "#CCD4E1"
  },
  headerText: {
    marginTop: 5,
    fontSize: 17,
  },
  headerIcon: {
    width: 20,
    height: 20,
    marginRight: 2
  },
  statWithIcon: {
    flexDirection: "row"
  },
  statContainer: {
    justifyContent: "center",
    alignItems: "center",
    flex: 1
  },
  statText: {
    fontWeight: "bold",
    fontSize: 20,
    color: "#DEC84B"
  },
  statDescription: {
    fontSize: 14,
    color: "#69D565"
  },
  teamPickContainer: {
    width: "70%",
    backgroundColor: "#CCD4E1",
    alignItems: "center",
    paddingLeft: 5
  },
  specialIconStyle: {
    width: 20,
    height: 20,
    top: -10,
    right: 40,
    zIndex: 3
  },
  teamContainer: {
    flexDirection: "row",
    alignItems: "center",
    paddingVertical: 20,
    width: 130
  },
  iconStyle: {
    width: 45,
    height: 55,
    marginHorizontal: 25
  },
  transferContainer: {
    width: "100%",
    backgroundColor: "#E5EAF3",
    flexDirection: "row"
  },
  tableHeader: {
    height: 40,  
    backgroundColor: "#CCD4E1"
  },
  headerText: {
    flex: 1,
    textAlign: "center",
    width: 100
  },
  playerNameHeader: {
    textAlign: "center",
    width: 250
  },
  playerNameContainer: {
    flexDirection: "row",
    backgroundColor: "#D9DDF5",
    borderRightWidth: 1,
    borderColor: "#C1C6DF",
    width: 550,
    alignItems: 'center'
  },
  tableRow: {
    flexDirection: "row",
    borderBottomWidth: 1,
    borderColor: "#C1C6DF",
    height: 80,
  },
  playerForm: {
    height: 80,
    paddingVertical: 10,
    justifyContent: "space-between"
  },
  tableColumn: {
    alignItems: "center",
    justifyContent: "center",
    width: 100,
    backgroundColor:'#E6E9F8'
  },
  buttonContainer: {
    width: "50%",
    height: 40,
    paddingHorizontal: 5,
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row"
  },
  buttons: {
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: "black",
    paddingVertical: 10,
    position: 'absolute',
    bottom: 0,
    left: 0,
    zIndex: 4
  },
  buttonText: {
    textAlign: "center",
    color: "white",
    fontSize: 17,
    fontWeight: "bold"
  },
  icon: {
    width: 20,
    height: 20,
    marginHorizontal: 8
  }
});
