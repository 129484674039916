import React from 'react';
import { StyleSheet, TouchableOpacity, Text } from 'react-native';

const button = (props) => {
    return (
        <TouchableOpacity 
            style={[styles.button, props.style]} 
            onPress={props.onPress}>
            <Text style={[styles.buttonText, props.textStyle]}>{props.title}</Text>
        </TouchableOpacity>
    );
}

export default button;

const styles = StyleSheet.create({
  button: {
    width: '100%',
    height: 50,
    borderRadius: 15,
    backgroundColor: 'white',
    alignItems: 'center',
    justifyContent: 'center'
  },
  buttonText: {
      fontSize: 18
  }
});

