import React from "react";
import {
  StyleSheet,
  TouchableOpacity,
  Text,
  View,
  Image
} from "react-native";
import bankCard from '../../assets/card-payment.png';
import smsCode from '../../assets/sms-payment.png';
import PopupDialog from '../../Common/components/PopupDialog';

const paymentPopup = props => {
  if (!props.isVisible) return null;

  return (
    <PopupDialog title="ИЗБЕРЕТЕ ПЛАТЕЖЕН МЕТОД" isVisible={props.isVisible} 
              onDismiss={props.onDismiss}>
        <View style={styles.methodsContainer}>
          <TouchableOpacity style={styles.paymentMethod} onPress={props.onBankCardSelected}>
            <Image source={bankCard} style={styles.image} />
            <Text style={styles.methodText}>БАНКОВА КАРТА</Text>
          </TouchableOpacity>

          {!props.hideSmsCode && 
          <TouchableOpacity style={styles.paymentMethod} onPress={props.onSmsCodeSelected}>
            <Image source={smsCode} style={styles.image} />
            <Text style={styles.methodText}>СМС КОД</Text>
          </TouchableOpacity>}
        </View>
    </PopupDialog>
  );
};

export default paymentPopup;

const styles = StyleSheet.create({
  image: {
      height: 80,
      width: 80
  },
  paymentMethod: {
      alignItems: 'center',
      justifyContent: 'center',
      marginHorizontal: 20
  },
  methodText: {
      fontWeight: 'bold',
      textAlign: 'center',
      marginTop: 5,
      fontSize: 15
  },
  methodsContainer: {
      flexDirection: 'row',
      width: '100%',
      flex: 1,
      alignItems: 'center',
      justifyContent: 'center',
      paddingHorizontal: '10%',
      marginVertical: 20
  }
});
