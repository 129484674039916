import React from 'react';
import {
    View,
    TouchableOpacity,
    Image,
    Text,
    StyleSheet,
    ScrollView
} from 'react-native';
import EStyleSheet from 'react-native-extended-stylesheet';
import homeImage from '../../assets/home_inactive.png';
import homeImageHovered from '../../assets/home_active.png';
import teamImage from '../../assets/team_tabbar.png';
import teamImageHovered from '../../assets/team_hover.png';
import transferImage from '../../assets/transfers.png';
import transferImageHovered from '../../assets/transfers_hover.png';
import pointsImage from '../../assets/points.png';
import pointsImageHovered from '../../assets/points_hover.png';
import palmsImage from '../../assets/palmsbet_tab.png';
import statsImage from "../../assets/stats.png"
import statsImageHovered from "../../assets/stats_hover.png"
import leaguesImage from "../../assets/leagues.png"
import leaguesImageHovered from "../../assets/leagues_hover.png"
import resultsImage from "../../assets/results.png"
import resultsImageHovered from "../../assets/results_hover.png"
import tableImage from "../../assets/table.png"
import tableImageHovered from "../../assets/table_hover.png"

import { connect } from 'react-redux';

class TabBar extends React.Component {

    state = {
        activeTabId: 1,
        tabs: [
            {
                id: 0,
                title: 'Начало',
                path: 'Home',
                icon: homeImage,
                hoverIcon: homeImageHovered,
            },
            // {
            //     id: 1,
            //     title: 'Палмс',
            //     path: 'Palms',
            //     icon: palmsImage,
            //     hoverIcon: palmsImage,
            // },
            {
                id: 2,
                title: 'Отбор',
                path: 'Team',
                icon: teamImage,
                hoverIcon: teamImageHovered,
            },
            {
                id: 3,
                title: 'Точки',
                path: 'Points',
                icon: pointsImage,
                hoverIcon: pointsImageHovered,
            },
            {
                id: 4,
                title: 'Трансфери',
                path: 'Transfers',
                icon: transferImage,
                hoverIcon: transferImageHovered,
            },
            {
                id: 5,
                title: 'Лиги',
                path: 'Leagues',
                icon: leaguesImage,
                hoverIcon: leaguesImageHovered,
            },
            {
                id: 6,
                title: 'Резултати',
                path: 'Results',
                icon: resultsImage,
                hoverIcon: resultsImageHovered,
            },
            {
                id: 7,
                title: 'Класиране',
                path: 'LeagueTable',
                icon: tableImage,
                hoverIcon: tableImageHovered,
            },
            {
                id: 8,
                title: 'Статистики',
                path: 'Stats',
                icon: statsImage,
                hoverIcon: statsImageHovered,
            },
        ]
    }

    changePage = id => {
        this.setState({ activeTabId: id });
        const tab = this.state.tabs.find(t => t.id === id);
        this.props.navigation.navigate(tab.path);
    }

    render() {
        const activeTab = this.state.tabs.find(
            t => window.location.href.indexOf(t.path.toLowerCase()) !== -1);
        const activeTabId = activeTab ? activeTab.id : 0;

        const { tabs } = this.state;
        return (
            <div style={{
                overflowX: 'scroll',
                display: 'flex',
                flexDirection: 'row',
                backgroundColor: 'black',
                alignItems: 'center',
                justifyContent: 'space-evenly',
                
                height: '100px',
                width: "100%"
            }}>
                {tabs.map((tab, i) => {

                    return <View style={[styles.tabContainer, activeTabId === tab.id ? { backgroundColor: '#2053A4' } : null] } key={i}>
                        <TouchableOpacity
                            style={styles.tab}
                            onPress={() => this.changePage(tab.id)}>
                            <Image source={activeTabId !== tab.id ? tab.icon : tab.hoverIcon}
                                style={{ width: 30, height: 30, marginBottom: 10 }} />
                            {/* <Text style={styles.tabTitle}>{tab.title}</Text> */}
                        </TouchableOpacity>
                    </View>
                })}
            </div>);
    }
}


export default connect(null)(TabBar);

const styles = EStyleSheet.create({
    tabsScrollView: {
        height: 120,
        width: '100%',
        flexDirection: 'row'
    },
    tabsContainer: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    tabContainer: {
        height: 70,
        width: "12%",
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 10,
    },
    tab: {
        height: 50,
        width: "90%",
        padding: 5,
        alignItems: 'center',
        justifyContent: 'center',
    },
    tabTitle: {
        color: 'white',
        textAlign: 'center',
        fontSize: 11
    }
});