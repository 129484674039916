import React from 'react';
import { StyleSheet, Text, View, Image, 
TouchableOpacity, ScrollView, StatusBar, Clipboard } from 'react-native';
import Background from '../../Common/components/Background';
import HeaderWithBack from '../../Common/components/HeaderWithBack';
import SendCodePopup from '../components/SendCodePopup';
import InfoPopup from '../../Home/components/InfoPopup';
import { connect } from 'react-redux';
import { getOwnLeagues, sendInvitation, invitationNotSent } from '../actions/ownLeagues'; 


class OwnLeagues extends React.Component {

    state = {
        selectedLeague: null,
        successPopupVisible: false,
    }

    static navigationOptions = { header: null }

    componentDidMount() {
      this.props.getOwnLeagues();
    }

    sendInvitation = (email, id) => {
        this.props.sendInvitation(email, id);
        this.setState({selectedLeague : null});
    }

    componentDidUpdate(prevProps) {
        if (prevProps.invitationSent !== this.props.invitationSent && 
            this.props.invitationSent) {
            this.setState({successPopupVisible: true});
            this.props.invitationNotSent();
        }
    } 

    render() {
        if (!this.props.ownLeagues) return null;

        return (
        <React.Fragment>
            <HeaderWithBack title="Собствени лиги" navigation={this.props.navigation} />
            
            <SendCodePopup 
            selectedLeague={this.state.selectedLeague} 
            onDismiss={() => this.setState({selectedLeague : null})}
            onConfirm={this.sendInvitation}/> 

            <InfoPopup title="ПОКАНАТА Е ИЗПРАТЕНА УСПЕШНО"
            isVisible={this.state.successPopupVisible} 
            onDismiss={() => this.setState({successPopupVisible: false}) }/>

            
            <ScrollView style={styles.scrollView} contentContainerStyle={styles.container}>
                <Background />
                <View style={{width: '100%'}}>
                  <Text style={styles.headingText}>Списък с лигите, на които сте собственик</Text>
                </View>
                {this.props.ownLeagues.map(league => (
                    <View style={styles.leagueContainer} key={league.id}>
                        <Text style={styles.name}>{league.name}</Text>
                        <TouchableOpacity onPress={() => Clipboard.setString(league.code)}>
                          <Text style={styles.code}>{league.code}</Text>
                          <Text style={styles.code}>КОПИРАЙ</Text>
                        </TouchableOpacity>
                        <TouchableOpacity 
                            style={styles.sendButton}
                            onPress={() => this.setState({selectedLeague : league})}>
                            <Text>изпрати</Text>
                        </TouchableOpacity>
                    </View>
                ))}
            </ScrollView>
        </React.Fragment>
        )
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getOwnLeagues: () => dispatch(getOwnLeagues()),
    sendInvitation: (email, id) => dispatch(sendInvitation(email, id)),
    invitationNotSent: () => dispatch(invitationNotSent())
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    ownLeagues: state.ownLeagues.ownLeagues,
    invitationSent: state.ownLeagues.invitationSent
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OwnLeagues);

const styles = StyleSheet.create({
  scrollView: {    
    width: '100%',
    flex: 1
  },
  container: {
    width: '100%',
    flex: 1
  },
  headingText: {
      fontWeight:'bold',
      color: 'white',
      fontSize: 26,
      marginBottom: 12,
      marginTop: 200,
      marginLeft: 15,
      width: '100%'
  },
  leagueContainer: {
      backgroundColor: 'white',
      width: '100%',
      paddingVertical: 15,
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-around',
      marginVertical: 10
  },
  name: {
      fontWeight:'bold',
      fontSize: 17
  },
  code: {
      color: '#2154A4',
      fontSize: 17
  },
  sendButton: {
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: '#72E86C',
      borderRadius: 5,
      padding: 5
  }
});