import React from 'react';
import { StyleSheet, Text, View, Image, TouchableOpacity } from 'react-native';
import banIcon from '../../assets/banned-player.svg.png';
import injuredIcon from '../../assets/injured-player.svg.png';
import transferedIcon from '../../assets/transfered-player.svg.png';
import captainIcon from '../../assets/captain.svg.png';
import viceCaptainIcon from '../../assets/vice-captain.svg.png';
import addPlayerIcon from '../../assets/Add_Player.png';
import removeIcon from '../../assets/remove-player.svg.png';
import captainChipActivatedIcon from '../../assets/captain-chip-new.svg.png';
import viceCaptainChipActivatedIcon from '../../assets/vice-captain-chip.png';
import autoInIcon from '../../assets/transfer-in.png';
import autoOutIcon from '../../assets/transfer-out.png';
import { getPlayerPointsColor } from "../../Common/utils/utils.js";
// import { Ionicons } from '@expo/vector-icons';

const playerView = props => {
  let selectedStyle = null, selectedPlayerName = null, saleStyle = null;

  if (props.isForSale) {
    saleStyle = { opacity: 0.6 }
  }

  if (props.selected) {
    selectedStyle = { opacity: props.isForSale ? 1.0 : 0.6 };
    selectedPlayerName = { backgroundColor: '#2053A4' }
  }

  return (<TouchableOpacity style={[styles.playerContainer, props.containerStyle]}
    onPress={props.onPress}>
    {props.nullPlayer ?
      <Image style={[styles.teamIcon, saleStyle, selectedStyle, props.iconStyle]} source={addPlayerIcon} />
      :
      <Image style={[styles.teamIcon, saleStyle, selectedStyle, props.iconStyle]} source={{ uri: props.iconUrl }} />
    }

    <View style={[styles.playerNameContainer, selectedPlayerName, props.nullPlayer ? styles.nullPlayerContainer : null]}>
      {props.nullPlayer ?
        <Text style={[styles.playerName, styles.nullPlayerName]}>
          ИЗБЕРИ
                </Text>
        :
        <Text style={[styles.playerName, props.playerStyle]}>
          {props.name}
        </Text>
      }
    </View>

    {!props.showPrice && props.teamName &&
      <View style={styles.playerTeamContainer}>
        {props.isReady ?
          <Text style={[styles.playerTeam, props.teamStyle]}>{props.teamName}</Text>
          :
          <Text style={[styles.playerTeam, props.teamStyle]}>ПРОМЕНИ</Text>
        }
      </View>}

    {props.showPrice && (typeof props.price == 'number') &&
      <View style={[styles.playerTeamContainer, { backgroundColor: '#E4A647' }]}>
        <Text style={styles.playerTeam}>{props.price}</Text>
      </View>}

    {!props.showPrice && (typeof props.points === 'number') &&
      <View style={[styles.playerTeamContainer, getPlayerPointsColor(props.points, true)]}>
        <Text style={styles.playerTeam}>{props.points}</Text>
      </View>}


    {props.canRemoveFromList && <Image source={removeIcon} style={styles.removeStyle} />}
    { /*props.isForSale && <Ionicons name="md-arrow-round-forward" 
                          size={35} color="red" style={styles.saleStyle} /> }
            { props.isForBuy && <Ionicons name="md-arrow-round-back" 
                          size={35} color="green" style={styles.buyStyle} /> */}
    {props.isAutoOut && <Image source={autoOutIcon} style={[styles.iconStyle, { width: 10, height: 10, top: 7 }]} />}
    {props.isAutoIn && <Image source={autoInIcon} style={[styles.iconStyle, { width: 10, height: 10, top: 7 }]} />}
    {props.isBanned && <Image source={banIcon} style={styles.iconStyle} />}
    {props.isInjured && <Image source={injuredIcon} style={styles.iconStyle} />}
    {props.isTransfered && <Image source={transferedIcon} style={styles.iconStyle} />}

    {props.isCaptain && <Image source={props.tripleCaptainActivated ? captainChipActivatedIcon : captainIcon} style={styles.captainIconStyle} />}
    {props.isViceCaptain && <Image source={props.doubleViceCaptainActivated ? viceCaptainChipActivatedIcon : viceCaptainIcon} style={styles.captainIconStyle} />}
  </TouchableOpacity>
  )
}

export default playerView;

const styles = StyleSheet.create({
  playerContainer: {
    paddingVertical: 3,
    justifyContent: 'center',
    alignItems: 'center',
    marginHorizontal: 3,
  },
  playerName: {
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: 11,
  },
  nullPlayerName: {
    color: 'white',
  },
  nullPlayerContainer: {
    backgroundColor: '#2053A4'
  },
  playerNameContainer: {
    backgroundColor: 'white',
    width: 73,
    maxWidth: 73,
    overflow: 'hidden',
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
    alignItems: 'center',
    justifyContent: 'center'
  },
  playerTeamContainer: {
    marginTop: 3,
    backgroundColor: '#F6C146',
    width: 70,
    borderBottomLeftRadius: 5,
    borderBottomRightRadius: 5,
    alignItems: 'center',
    justifyContent: 'center'
  },
  playerTeam: {
    fontWeight: 'bold',
    fontSize: 13
  },
  teamIcon: {
    width: 45,
    height: 55
  },
  iconStyle: {
    position: 'absolute',
    width: 20,
    height: 20,
    top: 20,
    right: 10,
    zIndex: 1
  },
  removeStyle: {
    position: 'absolute',
    width: 25,
    height: 25,
    top: 10,
    right: 0,
    zIndex: 1
  },
  saleStyle: {
    position: 'absolute',
    width: 30,
    height: 30,
    top: 80,
    left: 70,
    zIndex: 1
  },
  buyStyle: {
    position: 'absolute',
    width: 40,
    height: 30,
    top: 20,
    right: 37,
    zIndex: 1
  },
  captainIconStyle: {
    position: 'absolute',
    width: 20,
    height: 20,
    top: 0,
    right: 10,
    zIndex: 1
  }
});