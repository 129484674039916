import { SET_ADS } from "./actionTypes";
import { BASE_URL } from '../config/config';
import { authGetToken } from '../../Common/actions/token';

export const getAds = () => {
  return dispatch => {
    dispatch(authGetToken())
    .catch(err => {
        console.log('No auth token exists!');
        alert("Please login again, your session is expired!");
    })    
    .then(token => {
        return fetch(BASE_URL + '/ads', {
          headers: {
              "Content-Type": "application/json; charset=utf-8",
              "Authorization": token
          }
        });
    })
    .catch(err => {
      console.log(err);
    })
    .then(res => res.json())
    .catch(err => console.log(JSON.stringify(err.message)))
    .then(res => {
      if (res.has_error) return;
      const ads = res.data;
      ads.forEach(ad => {
        if (ad.image) ad.image.url = BASE_URL + ad.image.url;
      });
      dispatch(setAds(ads));
    })
  };
}


export const setAds = ads => {
  return {
    type: SET_ADS,
    ads
  };
};