import React from 'react';
import Background from '../../Common/components/Background';
import Input from '../../Common/components/Input';
import InfoPopup from '../../Home/components/InfoPopup';
import HeaderWithBack from '../../Common/components/HeaderWithBack';
import { StyleSheet, Text, View, Image, Button, TouchableOpacity, ScrollView, Linking } from 'react-native';
import email from '../../assets/email-icon.svg.png';
import emailIcon from '../../assets/email.png';
import { connect } from 'react-redux';
import { sendInvitations } from '../actions/invitations'; 


class InviteFriend extends React.Component {

    state = {
        email: '',
        hasError: false,
        emails: [
        ],
        successPopupVisible: false
    }
    
    static navigationOptions = {
        title: 'Покани приятел',
    };

    addEmail = () => {
        const { emails, email } = this.state;
        console.log(InviteFriend.isValidEmail(email))
        if (emails.includes(email) || !InviteFriend.isValidEmail(email)) {
            return;
        }
        this.setState({
            emails: [...emails, email],
            email: ''
        });
    }

    removeEmail = index => {
        const emails = [...this.state.emails];
        emails.splice(index, 1);
        this.setState({ emails });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.message !== this.props.message) {
            this.setState({successPopupVisible: true, emails: []});
        }
    } 

    static isValidEmail(email) {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    sendInvitations = () => this.props.sendInvitations(this.state.emails);

    render() {
        return (
            <ScrollView style={styles.scrollView} contentContainerStyle={styles.container}>
                <InfoPopup title={this.props.message && this.props.message.toUpperCase()}
                isVisible={this.state.successPopupVisible} 
                onDismiss={() => this.setState({successPopupVisible: false}) }/>
                <Background />
                <HeaderWithBack title="Покани приятели" navigation={this.props.navigation} />
                <Image source={email} style={styles.image} />
                <Text style={styles.text}>
                     Покани свои приятели да се регистрират в FPL Fantasy Manager и създай Частна Лига с тях.
                </Text>
                <View style={{flexDirection: 'row', width: '80%', marginBottom: 40}}> 
                    <Input placeholder='Имейл адрес'                         
                    onChangeText={(val) => this.setState({email: val})}
                    style={styles.input}
                    inputStyle={styles.inputStyle}
                    image={emailIcon}
                    containerStyle={{width: '75%'}}
                    value={this.state.email}/>
                    <Text style={styles.plusButon} onPress={this.addEmail}>+</Text>
                </View>
                {this.state.emails.map((email,i) => (
                    <View style={styles.emailContainer} key={i}>
                        <Text style={styles.emailText}>{email}</Text>
                        <Text onPress={()=>this.removeEmail(i)} style={styles.removeButton}>-</Text>
                    </View>
                ))}
                {this.state.hasError && <Text style={styles.errorText}>Моля, въведете валиден имейл адрес</Text>}
                <TouchableOpacity style={styles.button} onPress={this.sendInvitations}>
                    <Text style={styles.confirmText}>ПОКАНИ ЗА РЕГИСТРАЦИЯ</Text>
                </TouchableOpacity>
                {this.state.hasSubmitted && <Text style={styles.successText}>Поканите са изпратени успешно</Text>}
            </ScrollView>
        )
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    sendInvitations: emails => dispatch(sendInvitations(emails))
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    message: state.invitations.message,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(InviteFriend);


const styles = StyleSheet.create({
  scrollView: {    
    width: '100%',
    height: '100%'
  },
  container: {
    width: '100%',
    height: '100%',
    alignItems: 'center'    
  },
    image: {
        width: '25%',
        height: 100,
        marginTop: 120
    },
    text: {
        color: 'white',
        fontSize: 19,
        width: '75%',
        textAlign: 'center',
        marginVertical: 20
    },
    input: {        
        justifyContent: 'center',
        alignItems: 'center',
    },
    inputStyle: {
        width: '80%',
        textAlign: 'center',
        color: '#E4A647'
    },
    emphasize: {
        color: '#72E86C'
    },
    confirmText: {
        color: '#E4A647',
        fontWeight: 'bold',
        fontSize: 17
    },
    errorText: {
        color: '#A43B32',
        textAlign: 'center',
        fontSize: 17
    },
    button: {
        marginTop: 30
    },
    plusButon: {
        color: '#72E86C', 
        fontWeight: 'bold', 
        fontSize: 30, 
        marginLeft: 70
    },
    emailContainer: {
        width: '80%',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginVertical: 7,
    },
    emailText: {
        backgroundColor: 'black',
        color: 'white',
        fontSize: 17,
        padding: 10,
        borderRadius: 10
    },
    removeButton: {color: 'white', fontWeight: 'bold', fontSize: 22}
});