import React from 'react';
import { StyleSheet, View, Text, Linking } from 'react-native';

class Palms extends React.Component {
    static navigationOptions = { header: null }

    render() { 
        return ( 
            <View style={styles.container}>
                {Linking.openURL('https://www.palmsbet.com/bg/fantasy-league/&marketingCode=FANTASY')}
                {this.props.history.push('Home')}
            </View>
        );
    }
}

const styles = StyleSheet.create({
    container: {
        flex: 1, 
        backgroundColor: 'black'
    }
});

export default Palms;