import React from 'react';
import { connect } from 'react-redux';
import { View, Text, StyleSheet, ScrollView } from 'react-native';
import Background from '../../Common/components/Background';
import HeaderWithBack from '../../Common/components/HeaderWithBack';



export default class Rules extends React.Component {
    render() {
        return (
            <React.Fragment>
                <HeaderWithBack title={"Правила"} navigation={this.props.navigation} />
                <View style={{ height: 300 }}>
                    <Background style={{ height: 700, minHeight: 700, maxHeight: 700 }} />
                    <View style={{ margin: 20, flex: 1, justifyContent: 'flex-end' }}>
                        <Text style={styles.mainText}>Официални правила</Text>

                    </View>
                </View>
                <ScrollView style={{ backgroundColor: 'white' }}>
                    {renderHTML()}
                </ScrollView>
            </React.Fragment>);
    }
}


const styles = StyleSheet.create({
    mainText: {
        fontSize: 25,
        color: "white",
        fontWeight: 'bold',
        zIndex: 5
    }
})



const renderHTML = () => {
    return (
        <section style={{ maxHeight: '100%', overflow: 'auto' }}>
            <div class="kc-elm kc-css-623282 kc_text_block"><h3>Размер на отбора</h3>
                <p>Отборът ти от 10 човека трябва да е съставен от:</p>
                <ul>
                    <li>2 Вратари</li>
                    <li>3 Защитника</li>
                    <li>3 Полузащитника</li>
                    <li>2 Нападателя</li>
                </ul>
                <h3>Фентъзи бюджет</h3>

                <p>Фентъзи бюджетът, с който ще стартираш играта, е&nbsp;<strong><u>7 500 виртуални пари</u></strong></p>
                <h3>Играчи от отбор</h3>
                <p>Може да избираш до 2-ма играчи от всеки отбор от шампионат на БАМФ. Ако след трансферен прозорец случайно се окажеш с 3-ма играчи от един отбор, ще бъдеш принуден да продадеш един от тях.</p>
                <h3>Кръгове</h3>
                <p>В играта са включени редовните кръгове от първенствата на БАМФ.</p>
                <h3>Избери стартовите 6</h3>
                <p>От твоите 10 играча ти трябва да избереш 6 титуляри, които ще стартират кръга.</p>
                <p>Всичките ти точки за кръга ще бъдат отбелязани/натрупани от тези 6 играчи, но ако един или повече не играят, те могат автоматично да бъдат заменени.</p>

                <p><u>Възможни схеми:</u></p>
                <p>2-2-1, 2-1-2, 1-2-2</p>

                <h3>Капитан и Вицекапитан</h3>
                <p>От стартовите 6 трябва да избереш Капитан и Вицекапитан. Точките на твоя Капитан ще бъдат удвоени.</p>
                <p>Ако твоят Капитан не играе в кръга, той ще бъде сменен от Вицекапитана.</p>
                <p>Ако и Вицекапитанът не играе в кръга, тогава никой от играчите ти няма да получи удвоени точки.</p>
                <h3>Правила за автоматични смени</h3>
                <p>Твоите смени ти дават сигурност при непредвидени събития като контузии и наказания. Смените ти автоматично заменят стартовите играчи, които не играят в кръга.</p>
                <p>В зависимост от поредността на резервата, която си определил преди кръга, автоматичните смени се обработват в края на кръга, както следва:</p>
                <ul>
                    <li>Ако Вратарят ти не играе в кръга, той ще бъде сменен от резервния вратар.</li>
                    <li>Ако и двамата Вратари не играят в кръга, няма да получиш точки.</li>
                    <li>Ако някой от полевите играчите не играе в кръга, той ще бъде заменен от първата възможна резерва на идентична позиция, която играе в кръга (например, ако стартовият ти отбор има 2-ма защитници, защитник може да бъде заменен само от друг защитник).</li>
                </ul>
                <h3>Трансфери</h3>
                <p>След като избереш своя отбор, можеш да купуваш и продаваш играчи на трансферния пазар.</p>
                <p>След края на всеки Кръг ще получиш един безплатен трансфер за предстоящия Кръг. Всеки
                допълнителен трансфер, който ще направиш в рамките на един и същ Кръг, ще приспадне 4 точки
от общия ти резултат Генерален Резултат.</p>
                <p>Ако не използваш безплатния трансфер, можеш да направиш допълнителен безплатен трансфер в
                следващия Кръг. Ако не използваш този спестен безплатен трансфер в следващия Кръг, той ще
                бъде пренесен, докато не го направиш. Никога не можеш да имаш повече от 1 запазен трансфер
(максисмум два свободни трансфера на кръг).</p>
                <h3>Неограничени свободни трансфери&nbsp;(Wildcards)</h3>
                <p>Опцията Неограничени свободни трансфери (Wildcards) ти дава възможност за неограничен брой
                трансфери. След като активираш Wildcard, ти имаш право да го използваш в периода на
трансферния прозорец преди Кръга, като след затварянето му Wildcard ще е изчерпан.</p>
                <p>Неограничени свободни трансфери (Wildcards) може да се използва единт път по време на
шампионата.</p>
                <h3>Точки</h3>
                <p>През сезона твоите футболисти ще получат точки въз основа на тяхното представяне в реалните
мачове на отборите си в Първа Професионална Лига, както следва:</p>
                <table class="points" border="0">
                    <tbody>
                        <tr>
                            <th>СЪБИТИЕ</th>
                            <th>ТОЧКИ</th>
                        </tr>
                        <tr>
                            <td>За Участие в мача</td>
                            <td style={{ textAlign: 'center' }}><strong>2</strong></td>
                        </tr>
                        <tr>
                            <td>За всеки вкаран гол от ВРАТАР</td>
                            <td style={{ textAlign: 'center' }}><strong>10</strong></td>
                        </tr>
                        <tr>
                            <td>За всеки вкаран гол от ЗАЩИТНИК</td>
                            <td style={{ textAlign: 'center' }}><strong>6</strong></td>
                        </tr>
                        <tr>
                            <td>За всеки вкаран гол от ПОЛУЗАЩИТНИК</td>
                            <td style={{ textAlign: 'center' }}><strong>6</strong></td>
                        </tr>
                        <tr>
                            <td>За всеки вкаран гол от НАПАДАТЕЛ</td>
                            <td style={{ textAlign: 'center' }}><strong>6</strong></td>
                        </tr>
                        <tr>
                            <td>За всяка асистенция</td>
                            <td style={{ textAlign: 'center' }}><strong>3</strong></td>
                        </tr>
                        <tr>
                            <td>За Чиста мрежа от ВРАТАР</td>
                            <td style={{ textAlign: 'center' }}><strong>5</strong></td>
                        </tr>
                        <tr>
                            <td>За Чиста мрежа от ЗАЩИТНИК</td>
                            <td style={{ textAlign: 'center' }}><strong>4</strong></td>
                        </tr>
                        <tr>
                            <td>За Чиста мрежа от ПОЛУЗАЩИТНИК</td>
                            <td style={{ textAlign: 'center' }}><strong>4</strong></td>
                        </tr>
                        <tr>
                            <td>За всеки три спасявания от ВРАТАР</td>
                            <td style={{ textAlign: 'center' }}><strong>1</strong></td>
                        </tr>
                        <tr>
                            <td>За всяка спасена дузпа от ВРАТАР</td>
                            <td style={{ textAlign: 'center' }}><strong>5</strong></td>
                        </tr>
                        <tr>
                            <td>За всяка изпусната дузпа</td>
                            <td style={{ textAlign: 'center' }}><strong>-2</strong></td>
                        </tr>
                        <tr>
                            <td>За всеки 2-ри допуснат гол от ВРАТАР или ЗАЩИТНИК</td>
                            <td style={{ textAlign: 'center' }}><strong>-1</strong></td>
                        </tr>
                        <tr>
                            <td>За всеки жълт картон</td>
                            <td style={{ textAlign: 'center' }}><strong>-1</strong></td>
                        </tr>
                        <tr>
                            <td>За всеки червен картон</td>
                            <td style={{ textAlign: 'center' }}><strong>-3</strong></td>
                        </tr>
                        <tr>
                            <td>За всеки автогол</td>
                            <td style={{ textAlign: 'center' }}><strong>-2</strong></td>
                        </tr>
                    </tbody>
                </table>
                <h3>Бонус Система</h3>
                <p>Футболистите получават също допълнителни точки в зависимост от тяхното представяне. Тримата
                с най-добър общ резултат на базата на нашата бонус системата прибавят съответно 3т., 2т. или 1т.
към реалните точки постигнати в мача. Бонус системата се формира по следния начин:</p>
                <table class="points" border="0">
                    <tbody>
                        <tr>
                            <th>СЪБИТИЕ</th>
                            <th>ТОЧКИ</th>
                        </tr>
                        <tr>
                            <td>Гол НАПАДАТЕЛ</td>
                            <td style={{ textAlign: 'center' }}><b>6</b></td>
                        </tr>
                        <tr>
                            <td>Гол ПОЛУЗАЩИТНИК</td>
                            <td style={{ textAlign: 'center' }}><strong>6</strong></td>
                        </tr>
                        <tr>
                            <td>Гол ЗАЩИТНИК</td>
                            <td style={{ textAlign: 'center' }}><b>6</b></td>
                        </tr>
                        <tr>
                            <td>Гол ВРАТАР</td>
                            <td style={{ textAlign: 'center' }}><b>4</b></td>
                        </tr>
                        <tr>
                            <td>Асистенция</td>
                            <td style={{ textAlign: 'center' }}><strong>3</strong></td>
                        </tr>
                        <tr>
                            <td>За Чиста мрежа от ПОЛУЗАЩИТНИК</td>
                            <td style={{ textAlign: 'center' }}><strong>2</strong></td>
                        </tr>
                        <tr>
                            <td>За Чиста мрежа от ЗАЩИТНИК</td>
                            <td style={{ textAlign: 'center' }}><strong>2</strong></td>
                        </tr>
                        <tr>
                            <td>За Чиста мрежа от ВРАТАР</td>
                            <td style={{ textAlign: 'center' }}><strong>3</strong></td>
                        </tr>
                        <tr>
                            <td>1 спасяване</td>
                            <td style={{ textAlign: 'center' }}><strong>0.5</strong></td>
                        </tr>
                        <tr>
                            <td>Жълт картон</td>
                            <td style={{ textAlign: 'center' }}><strong>-1</strong></td>
                        </tr>
                        <tr>
                            <td>Червен картон</td>
                            <td style={{ textAlign: 'center' }}><b>-3</b></td>
                        </tr>
                        <tr>
                            <td>Автогол</td>
                            <td style={{ textAlign: 'center' }}><b>-3</b></td>
                        </tr>
                        <tr>
                            <td>За участие в мача</td>
                            <td style={{ textAlign: 'center' }}><b>2</b></td>
                        </tr>
                        <tr>
                            <td>Спасена дузпа</td>
                            <td style={{ textAlign: 'center' }}><b>3</b></td>
                        </tr>
                        <tr>
                            <td>Изпусната дузпа</td>
                            <td style={{ textAlign: 'center' }}><b>-2</b></td>
                        </tr>
                        <tr>
                            <td>Играч на мача</td>
                            <td style={{ textAlign: 'center' }}><strong>3</strong></td>
                        </tr>
                        <tr>
                            <td>2-ри допуснат гол от ЗАЩИТНИК</td>
                            <td style={{ textAlign: 'center' }}><strong>-1</strong></td>
                        </tr>
                        <tr>
                            <td>2-ри допуснати гол от ВРАТАР</td>
                            <td style={{ textAlign: 'center' }}><strong>-0.5</strong></td>
                        </tr>
                    </tbody>
                </table>
                <h3>Чиста Мрежа</h3>
                <p>Чиста мрежа се присъжда за недопуснат гол на отбора.</p>

                <h3>Червен Картон</h3>
                <p>Ако играч получи червен картон, той ще продължи да бъде наказан за головете, допуснати от
неговия отбор в текущия мач.</p>
                <p>Ако играч получи два жълти картона и съответно червен, ти ще получиш минус точки за всеки
жълт картон (-2т)</p>
                <p>Ако играч получи жълт картон и след него директен червен, ти ще получиш минус точки както за
жълтия, така и за червения картон (-4т).</p>
                <h3>Играч на мача</h3>
                <p>Точките за Играч на Мача са част от нашата бонус система и се определят от съдията на мача.</p>
                <h3>Асистенции</h3>
                <ul>
                    <li>Асистенциите се присъждат на играча от отбора, който прави последния пас преди да бъде
                    отбелязан гол. Асистенция се присъжда независимо дали при подаването има рикошет,
избиване или докосване на противников играч.</li>
                    <li>Ако даден удар към вратата е блокиран от противник, спасен от вратаря или удари гредата
                    и при добавката се отбележи гол, се присъжда Асистенция на играчът, отправил последния
удар.</li>
                    <li>Ако изстрел на играч принуди противника да си вкара АВТОГОЛ, тогава се присъжда
Асистенция на играча, отправил удара.</li>
                    <li>В случай на ДУЗПА или СВОБОДЕН УДАР, играчът, който печели дузпата или свободния
                    удар, получава Асистенция. Ако даден гол е отбелязан от играча спечелил дузпата или
наказателния удар, НЕ СЕ присъжда Асистенция.</li>
                </ul>
                <h3>Статистики</h3>
                <p>Точките за всички статистики се взeмат от bamf.bg . Това е официален сайт на Българската
Асоциация по Мини Футбол и информацията в него се попълва и проверява от делегатите на
БАМФ на мачовете.</p>
                <p>За да се избегнат съмнения, точките, присъдени в играта, могат да се променят до един час след
последния сигнал на последния мач от който и да е ден. След като всички точки бъдат
актуализирани в този ден, няма да бъдат направени допълнителни корекции на точките.</p>
               
                <h3>Безплатни Бонуси</h3>
                <p>Бонусите могат да бъдат използвани за подобряване на ефективността на отбора ти през сезона.</p>
                <p>В един Кръг може да се играе само по един бонус. Наличните бонуси са както следва:</p>
                <table class="points" border="0">
                    <tbody>
                        <tr>
                            <th>ИМЕ</th>
                            <th>ЕФЕКТ</th>
                        </tr>
                        <tr>
                            <td>Троен Капитан</td>
                            <td>Точките на Капитана се утрояват (вместо да се удвояват) в Кръга, в който е избран
Бонуса.</td>
                        </tr>
                        <tr>
                            <td>Пълен Отбор</td>
                            <td>Вземат се точките на всички играчи от отбора ти, включително и резервите, в
Кръга, за който е избран Бонуса.</td>
                        </tr>
                        <tr>
                            <td>Двоен Вицекапитан</td>
                            <td>Точките на Вицекапитан се удвояват като тези на Капитана в Кръга, за който
е избран Бонуса.</td>
                        </tr>
                    </tbody>
                </table>
              
                <h3>Краен срок за промени в отбора преди Кръг</h3>
                <p>Всички промени в отбора ти (стартови 6, трансфери на играчи, определяне на капитана и
вицекапитана, използване на Бонуси, промени на схеми на игра) трябва да бъдат направени
преди крайния срок на стартиране на Кръга, за да влязат в сила за мачовете.</p>
                <p><b>Крайният срок е 1 час преди началното време за първия мач на Кръга.</b></p>
                <h3>Лиги</h3>
                <p>След като изградиш отбора си, можеш да се присъединиш към вече съществуващи лиги или да
създадеш собствена лига.</p>
                <p><strong><u>Частни лиги</u></strong></p>
                <p>Частните лиги са сърцето и душата на играта, където се състезаваш срещу приятелите си. Просто
създаваш лига и след това изпращаш уникалния код, за да позволиш на приятелите си да се
присъединят.</p>
                <p>Можеш да се състезаваш в до 15 частни лиги. В една лига могат да участват неограничен брой
отбори.</p>
                <p><strong><u>Публични (общи) лиги</u></strong></p>
                <p>Автоматично влизаш в следните общи лиги:</p>
                <ul>
                    <li>Общата лига, включваща всички регистрирани отбори;</li>
                    <li>Лига за привърженици на любимия ти отбор в Първа Професионална Лига;</li>
                    <li>Лига за мениджъри, които стартират от същия Кръг като теб.</li>
                </ul>
                <h3>Резултати и класиране</h3>
                <p>В лига с Генералния Резултат отборите се класират въз основа на общия брой точки в играта.</p>
                <p>В случай на равенство на точките между два или повече отбора, се вземат предвид точките от
последния Кръг.</p>
                <h3>Цени на играчите</h3>
                <p>Цените на играчите не се променят, докато сезона не започне.</p>
                <p>Цените на играчите се променят през сезона на всеки 5 кръга в зависимост от формата им в
реалните мачове.</p>
            </div>
        </section>
    )
}