import React from "react";
import {
  StyleSheet,
  TouchableOpacity,
  Text,
  View,
  Image
} from "react-native";
import PopupDialog from '../../Common/components/PopupDialog';
import okIcon from '../../assets/approve-transfer.svg.png';
import cancelIcon from '../../assets/cancel-transfer.svg.png';

const confirmPopup = props => {
    return (
        <PopupDialog isVisible={props.isVisible} 
            title={"Изтриване на лични данни".toUpperCase()}
            popupStyle={{top: '50%'}}>
            <View style={styles.container}>
                <Text style={{fontSize: 16, marginVertical: 10, textAlign: 'center'}}>Сигурни ли сте искате вашите данни да бъдат изтрити?</Text>

                <View style={styles.buttons}>
                    <TouchableOpacity 
                        style={[styles.buttonContainer, {borderRightWidth: 1, borderColor: 'white'}]}
                        onPress={props.onDismiss}>
                        <Image source={cancelIcon} style={styles.icon} />
                        <Text style={styles.buttonText}>
                            Откажи
                        </Text>
                    </TouchableOpacity>
                    <TouchableOpacity 
                        style={styles.buttonContainer}
                        onPress={props.onConfirm}>
                        <Text style={styles.buttonText}>
                            Активирай
                        </Text>
                        <Image source={okIcon} style={styles.icon} />
                    </TouchableOpacity>
                </View>
            </View>
        </PopupDialog>
    );
};

export default confirmPopup;

const styles = StyleSheet.create({
  container : {
      width: '100%',
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center'
  },
  chipName : {
      color: '#479C2D',
      fontWeight: 'bold',
      marginVertical: 5
  },
  chipInfo : {
    color: '#F7D27B',
    marginBottom: 15
  },
  buttonContainer: {
      width: '50%',
      height: 40,
      paddingHorizontal: 5,
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'row',
  }, 
  buttons: {
      width: '100%',
      flexDirection: 'row',
      alignItems: 'center',
      backgroundColor: 'black',
      borderBottomLeftRadius: 10,
      borderBottomRightRadius: 10,
      paddingVertical: 10,
  },
  buttonText: {
    textAlign: 'center',
    color: 'white',
    fontSize: 20,
    fontWeight: 'bold'
  }, 
  icon: {
      width: 20,
      height: 20,
      marginHorizontal: 8
  },
});
