import React from 'react';
import { StyleSheet, Text, View, Image, Button, TouchableOpacity, 
ScrollView, ActivityIndicator } from 'react-native';
import { connect } from 'react-redux';
import { getRoomRanking } from '../actions/leaguesRankings'; 
import TabBar from '../../Common/components/TabBar';
import LoadingScreen from '../../Common/components/LoadingScreen';

class Rankings extends React.Component {
  static navigationOptions = {
      title: 'Класиране лиги',
  };

  state = {
    loadingMore: false
  }

  getMoreRanking() {
    const id = this.props.navigation.getParam('roomId');
    this.props.getRoomRanking(id);
    this.setState({loadingMore: true});
  }

  componentDidMount() {    
    if (!this.props.leagueRanking)
      this.getMoreRanking();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.leagueRanking !== this.props.leagueRanking && this.state.loadingMore) {
      this.setState({loadingMore: false});
    } 
  }

  openPlayerDetails = userId => {
    this.props.navigation.navigate('Points', { userId });
  }

  isCloseToBottom = ({layoutMeasurement, contentOffset, contentSize}) => {
    const paddingToBottom = 5;
    return layoutMeasurement.height + contentOffset.y >=
      contentSize.height - paddingToBottom;
  };

  renderRankingRow = (ranking, isOwn) => 
   (<TouchableOpacity 
   style={[styles.tableRow, (isOwn===true) ? {backgroundColor: '#D3FBD8', marginBottom: 40} : null]} 
   key={ranking.rank + Math.random()} 
      onPress={() => this.openPlayerDetails(ranking.userId)}>
      <View style={[styles.rowSubView, {flex:2, justifyContent: 'space-between'}]}>

          <View style={styles.rankInfo}>
            <Text style={styles.boldText}>{ranking.rank}</Text>
          </View>

          <View style={styles.teamInfo}>
              <Text style={styles.boldText}>{ranking.team}</Text> 
              <Text>{ranking.username}</Text>
          </View>
      </View>

      <View style={[styles.pointsView, (isOwn===true) ? {backgroundColor: '#9FF3A7'} : null]}>
        <Text style={styles.boldText}>{ranking.totalPoints}</Text>
      </View>
    </TouchableOpacity>);
  

  render() {
    if (!this.props.leagueRanking || this.props.leagueRanking.length === 0) {
      return <LoadingScreen />;
    }

    if (!this.props.roomInfo) {
      this.props.navigation.navigate('Home');
      return null;
    }

    return (
      <ScrollView style={styles.scrollView} 
                  contentContainerStyle={styles.container}
                  onScroll={({nativeEvent}) => {
                    if (this.isCloseToBottom(nativeEvent) && !this.state.loadingMore) {
                      this.getMoreRanking();
                    }
                  }}
                  scrollEventThrottle={400}>

          <View style={[styles.tableRow, styles.header]}>
            <Text style={{flex:2, marginHorizontal: 30}}>Потребител</Text>
            <View style={[styles.rowSubView, {flexDirection: 'column'}]}>
              <Text style={styles.boldText}>Toчки</Text>
            </View>
          </View>
          {//this.renderRankingRow(this.props.leagueRanking.loggedUser, true)
          }
          {this.props.leagueRanking.rankings.map(this.renderRankingRow)}
          {this.state.loadingMore && <ActivityIndicator size="large" color="grey" />}
          <TabBar navigation={this.props.navigation}/>
      </ScrollView>
      
    );
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getRoomRanking: id => dispatch(getRoomRanking(id))
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    leagueRanking: state.leaguesRankings.leagueRanking,
    roomInfo: state.room.roomInfo
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Rankings);

const styles = StyleSheet.create({
  container: {    
    flex: 1,
    height: '100%',    
    minHeight: 1200
  },
  scrollView: {
    flex: 1,
    width: '100%',
    height: '100%',
    minHeight: 1200
  },
  headerView: {
    height: 80,
    width: '100%',
    flexDirection: 'row',
    backgroundColor: 'black',
    alignItems: 'center',
    paddingLeft: 10
  },
  headerText: {
    fontSize: 19,
    fontWeight: 'bold',
    color: '#6FE169',    
  },
  header: {
    backgroundColor: '#CCD4E1',
    height: 80,
    maxHeight: 80
  },
  tableRow: {
    flex: 1,
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: 'white',
    borderBottomWidth: 1,
    borderColor: '#CCD4E1',
    height:  100,
    maxHeight: 100
  },
  rowSubView: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    marginVertical: 10,
    marginHorizontal: 10,
  },
  pointsView: {
    flex: 1,
    height: '100%',
    backgroundColor: '#E3E9F4',
    alignItems: 'center',
    justifyContent: 'center'
  },
  teamInfo: {
    width: '60%'
  },
  boldText: {
    fontWeight: 'bold'
  },
  rankInfo: {
    flexDirection: 'row',
    width: 30
  },
  leagueContainer: {
    width: 200,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  rowText: {
    fontSize: 17,
    textAlign: 'center',
    flex: 1,
    fontWeight: 'bold'
  }
});
