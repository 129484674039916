import React from 'react';
import { StyleSheet, Text, View, Image, Button, TouchableOpacity, ScrollView } from 'react-native';
import EStyleSheet from 'react-native-extended-stylesheet';
import PlayerPopup from '../../Transfers/components/PlayerPopup';
import TabBar from '../../Common/components/TabBar';
import PickerListPopup from '../../Common/components/PickerListPopup';
import LoadingScreen from '../../Common/components/LoadingScreen';
import IosArrowDown from 'react-ionicons/lib/IosArrowDown';
import HeaderWithBack from '../../Common/components/HeaderWithBack';

import { connect } from 'react-redux';
import { getAllPositions } from '../actions/positions';
import { getAllTeams } from '../../Common/actions/teams';
import { getAvailablePlayersForTransfer } from '../../Transfers/actions/players';


class Stats extends React.Component {

  state = {
    allTeamsAndPositions: null,
    allFilters: [
      { key: 1, label: 'Филтър', sortBy: null },
      { key: 2, label: 'Цена', sortBy: 'price' },
      { key: 3, label: 'Форма', sortBy: 'shape' },
      { key: 4, label: 'Точки П.К.', sortBy: 'pointsLastRound' },
      { key: 5, label: 'Общо точки', sortBy: 'points' },
      { key: 6, label: 'Асистенции', sortBy: 'assists' },
      { key: 7, label: 'Чисти мрежи', sortBy: 'cleanNet' },
      { key: 8, label: 'Спасявания', sortBy: 'saves' },
    ],
    currentFilter: { key: 5, label: 'Общо точки', sortBy: 'points' },
    currentTeamOrPosition: {},
    selectedPlayerId: null,
    teamsAndPositionsOpen: false,
    filtersOpen: false
  }

  getPlayerColor = shape => {
    if (shape > 75) {
      return 'green';
    }
    if (shape > 50) {
      return "#A6EA9A";
    }
    if (shape > 25) {
      return '#efa42b';
    }
    return 'red';
  }

  static navigationOptions = {
    title: 'Статистики',
  };


  refreshPlayers = () => {
    const { sortBy } = this.state.currentFilter;
    const { positionId, teamId } = this.state.currentTeamOrPosition;
    this.props.getAvailablePlayersForTransfer(positionId, teamId, sortBy, window.localStorage.getItem("championshipId"));
  }

  componentDidMount() {
    this.props.getAllPositions();
    this.props.getAllTeams(window.localStorage.getItem("championshipId"));
  }

  componentDidUpdate(prevProps) {
    if (!this.state.allTeamsAndPositions && this.props.realTeams.length > 0 && this.props.positions) {
      const allTeamsAndPositions = [{
        key: -10,
        label: 'Всички играчи',
        positionId: null,
        teamId: null
      }];

      this.props.positions.forEach(position => {
        if (position.id != 5) {
          allTeamsAndPositions.push({
            key: position.id,
            label: position.name,
            positionId: position.id,
            teamId: null
          });
        }
      });

      this.props.realTeams.forEach(team => {
        allTeamsAndPositions.push({
          key: team.id * 100,
          label: team.name,
          positionId: null,
          teamId: team.id
        });
      });

      const currentTeamOrPosition = allTeamsAndPositions[0];
      this.setState({ allTeamsAndPositions, currentTeamOrPosition }, this.refreshPlayers);
    }
  }

  showPlayerPopup = player => {
    this.setState({ selectedPlayerId: player.playerId })
  }

  hidePlayerPopup = () => {
    this.setState({ selectedPlayerId: null })
  }

  changeSearch = option => {
    if (!option.key) {
      this.setState({ teamsAndPositionsOpen: false });
      return;
    }
    this.setState({ currentTeamOrPosition: option, teamsAndPositionsOpen: false }, () => this.refreshPlayers());
  }

  changeSort = option => {
    if (!option.key) {
      this.setState({ filtersOpen: false });
      return;
    }
    this.setState({ currentFilter: option, filtersOpen: false }, () => this.refreshPlayers());
  }

  render() {
    if (!this.props.availablePlayers) {
      return <LoadingScreen />;
    }
    return (
      <React.Fragment>
        <HeaderWithBack title={"Статистики"} hideBack={true} navigation={this.props.navigation} />
        <PickerListPopup
          data={this.state.allTeamsAndPositions}
          open={this.state.teamsAndPositionsOpen}
          title='Позиция или отбор'
          onClose={this.changeSearch} />

        <PickerListPopup
          data={this.state.allFilters}
          open={this.state.filtersOpen}
          title='Филтър'
          onClose={this.changeSort} />

        {this.state.selectedPlayerId &&
          <PlayerPopup playerId={this.state.selectedPlayerId}
            onDismiss={this.hidePlayerPopup}
            navigation={this.props.navigation} />
        }
        <View style={styles.headerView}>
          <TouchableOpacity style={styles.positionContainer} onPress={() => this.setState({ teamsAndPositionsOpen: true })}>
            <Text style={styles.headerText}>
              {this.state.currentTeamOrPosition.label}
            </Text>
            <IosArrowDown fontSize="23px" color="white" />
          </TouchableOpacity>
          <TouchableOpacity style={styles.positionContainer} onPress={() => this.setState({ filtersOpen: true })}>
            <Text style={styles.headerText}>
              {this.state.currentFilter.label}
            </Text>
            <IosArrowDown fontSize="20px" color="white" style={{ marginRight: 5 }} />
          </TouchableOpacity>
        </View>
        <ScrollView style={styles.scrollView}
          contentContainerStyle={styles.container}>

          {this.props.availablePlayers.map(stat => (
            <TouchableOpacity style={styles.playerStatContainer} key={stat.playerId}
              onPress={() => this.showPlayerPopup(stat)}>
              <View style={styles.playerInfo}>
                <Image source={{ uri: stat.iconUrl }} style={styles.playerImg} />

                <View style={styles.playerStats}>
                  <View style={{ flexDirection: 'row' }}>
                    <Text style={styles.name}>{stat.name}</Text>
                    <Text style={styles.price}>{stat.price}</Text>
                  </View>
                  <Text>{stat.position}</Text>
                  <View style={styles.shapeContainer}>
                    <View style={[styles.shapeContainer,
                    {
                      marginVertical: 0,
                      backgroundColor: this.getPlayerColor(stat.shape),
                      width: (stat.shape + "%")
                    }]} />
                  </View>
                </View>
              </View>
              <View style={styles.playerPoints}>
                <Text style={styles.playerPointsText}>
                  {this.state.currentFilter.sortBy ?
                    stat[this.state.currentFilter.sortBy === 'cleanNet' ?
                      'clean_nets' : this.state.currentFilter.sortBy]
                    : stat.points}
                </Text>
              </View>
            </TouchableOpacity>
          ))}
        </ScrollView>
        <TabBar navigation={this.props.navigation} />
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getAllPositions: () => dispatch(getAllPositions()),
    getAllTeams: (id) => dispatch(getAllTeams(id)),
    getAvailablePlayersForTransfer: (positionId, teamId, sortBy, championshipId) => dispatch(getAvailablePlayersForTransfer(positionId, teamId, sortBy + '_desc', true, championshipId))
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    homeInfo: state.home.info,
    positions: state.positions.positions,
    realTeams: state.realTeams.realTeams,
    availablePlayers: state.transfers.availablePlayers,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Stats);


const styles = EStyleSheet.create({
  container: {
    alignItems: 'center',
    justifyContent: 'space-between',
    flex: 1
  },
  scrollView: {
    height: '100%',
    width: '100%',
  },
  headerView: {
    height: 60,
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: 'black',
    paddingHorizontal: 5
  },
  headerText: {
    fontSize: 14,
    color: 'white',
    textAlign: 'center'
  },
  positionContainer: {
    flexDirection: 'row',
    width: 100,
    marginLeft: 25
  },
  playerImg: {
    height: 80,
    width: 80,
    resizeMode: 'contain'
  },
  playerStatContainer: {
    width: '100%',
    borderBottomWidth: 1,
    borderColor: '#CED6E3',
    flexDirection: 'row'
  },
  playerInfo: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: '#E5EAF3',
    paddingVertical: 10,
    paddingHorizontal: 10,
    flex: 3,
    height: '100%'
  },
  playerPoints: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'white'
  },
  playerPointsText: {
    fontWeight: 'bold',
    fontSize: 20
  },
  shapeContainer: {
    height: 10,
    marginVertical: 20,
    width: 150,
    backgroundColor: '#C0CADE',
    borderRadius: 10
  },
  playerStats: {
    marginLeft: 20
  },
  name: {
    fontSize: 18,
    fontWeight: 'bold'
  },
  price: {
    fontSize: 18,
    fontWeight: 'bold',
    color: '#3462AC',
    marginLeft: 10
  }

});
