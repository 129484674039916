import { SET_CHAMPIONSHIP_INFO, GET_ALL_CHAMPIONSHIPS, GET_ACTIVE_CHAMPIONSHIP, GET_USER_CHAMPIONSHIPS } from "../actions/actionTypes";

const initialState = {
    championshipInfo: null,
    championships: [],
    activeChamp: null,
    userChampionships: []
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CHAMPIONSHIP_INFO:
      return {
        ...state,
        championshipInfo: action.info
      };
      case GET_ALL_CHAMPIONSHIPS: 
      return {
        ...state,
        championships: action.info
      }
      case GET_ACTIVE_CHAMPIONSHIP:
        return {
          ...state,
          activeChamp: action.info
        }
        case GET_USER_CHAMPIONSHIPS:
          return {
            ...state,
            userChampionships: action.info
          }
    default:
      return state;
  }
};

export default reducer;