export const TRY_AUTH = "TRY_AUTH";

export const SEND_EMAIL = "SEND_EMAIL";
export const MAIL_SENT = "MAIL_SENT";

export const AUTH_SET_TOKEN = "AUTH_SET_TOKEN";

export const SET_CHAMPIONSHIP_INFO = "SET_CHAMPIONSHIP_INFO";

export const GET_ALL_CHAMPIONSHIPS = "GET_ALL_CHAMPIONSHIPS";
export const GET_USER_CHAMPIONSHIPS = "GET_USER_CHAMPIONSHIPS";
export const GET_ACTIVE_CHAMPIONSHIP = "GET_ACTIVE_CHAMPIONSHIP";