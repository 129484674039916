import React from 'react';
import { StyleSheet, Text, View, Image, Button, TouchableOpacity, ScrollView, Linking } from 'react-native';
import { connect } from 'react-redux';
import { getLeagueTable } from '../actions/leagueTable'; 
import TabBar from '../../Common/components/TabBar';
import HeaderWithBack from '../../Common/components/HeaderWithBack';

class LeagueTable extends React.Component {
  static navigationOptions = {
    title: 'Класиране в ППФЛ',
  };

  componentDidMount() {
      this.props.getLeagueTable();
  }

  getPlaceStyles = placeNumber => {
    if (placeNumber === 1) {
      return {
        backgroundColor: '#72E86C',
        padding: 6,
        borderRadius: 5
      }
    }
    if (placeNumber === 2 || placeNumber === 3) {
      return {
        backgroundColor: '#2053A4',
        color: 'white',
        padding: 6,
        borderRadius: 5
      }
    }

    return null;
  }

  render() {
    if (!this.props.leagueTable) return null;

    return (
      <React.Fragment>
      <HeaderWithBack title={"Класиране"} hideBack={true} navigation={this.props.navigation}/> 
      <View style={styles.headerView}>
          <Text style={styles.headerText}>М</Text>
          <Text style={styles.headerText}>П</Text>
          <Text style={styles.headerText}>Р</Text>
          <Text style={styles.headerText}>З</Text>
          <Text style={[styles.headerText, {flex:2}]}>Г</Text>
          <Text style={[styles.headerText, {flex:2}]}>ГР</Text>
          <Text style={[styles.headerText, {textAlign: 'right'}]}>т.</Text>
      </View>
      <ScrollView style={styles.scrollView} 
                  contentContainerStyle={styles.container}>
        

        {this.props.leagueTable.map((score,index) => (
          <View style={[styles.teamScoreContainer, 
            {backgroundColor: index % 2 === 0 ? 'white' : '#E5EAF4'}]} 
            key={index}>

            <View style={styles.teamInfo}>
              <View style={styles.place}>
                <Text style={[styles.placeText, this.getPlaceStyles(index+1)]}>{index+1}</Text>
              </View>
              <Image source={{uri:score.teamLogoUrl}} style={styles.rowImage}/>
              <Text style={styles.rowBoldText}>{score.teamName}</Text>
            </View>
            <Text style={styles.rowText}>{score.numberOfMatches}</Text>
            <Text style={styles.rowText}>{score.wins}</Text>
            <Text style={styles.rowText}>{score.draws}</Text>
            <Text style={styles.rowText}>{score.loses}</Text>
            <Text style={[score.goalsDifference >= 0 ? 
              styles.rowPositiveText : styles.rowNegativeText, {flex: 2}]}>
              {score.goals}
            </Text>
            <Text style={[score.goalsDifference >= 0 ? 
              styles.rowPositiveText : styles.rowNegativeText, {flex:2}]}>
              {score.goalsDifference}
            </Text>
            <Text style={styles.rowBoldText}>{score.points}</Text>
          </View>
        ))}
      </ScrollView>
      <TabBar navigation={this.props.navigation} />
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getLeagueTable: () => dispatch(getLeagueTable())
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    leagueTable: state.leagueTable.leagueTable,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LeagueTable);

const styles = StyleSheet.create({
  container: {    
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  scrollView: {
    width: '100%',
    height: '100%'
  },
  headerView: {
    height: 80,
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',    
    justifyContent: 'flex-end',    
    backgroundColor: 'black',
    paddingLeft: '35%',
    paddingRight: 10
  },
  headerText: {
    fontSize: 17,
    color: 'white',
    flex: 1,
    textAlign: 'center'
  },
  teamScoreContainer: {
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: 60,
    borderWidth: 1,
    borderColor: '#CCD4E1'
  },
  teamInfo: {
    flexDirection: 'row',
    width: '35%',
    alignItems: 'center',
  },
  place: {
      flex: 1,
      alignItems: 'center',
      justifyContent: 'center'
  },
  placeText: {
    textAlign: 'center',
    fontSize: 17
  },
  rowImage: {
    width: 30,
    height: 35,
    resizeMode: 'contain'
  },
  rowText: {
    fontSize: 17,
    textAlign: 'center',
    flex: 1
  },
  rowBoldText: {
    fontSize: 17,
    textAlign: 'center',
    flex: 1,
    fontWeight: 'bold'
  },
  rowPositiveText: {
    fontSize: 17,
    textAlign: 'center',
    flex: 1,
    color: '#4FA138'
  },
  rowNegativeText: {
    fontSize: 17,
    textAlign: 'center',
    flex: 1,
    color: '#A7443C'
  }
});
