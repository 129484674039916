import { SET_POSITIONS } from "../actions/actionTypes";

const initialState = {
  positions: null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_POSITIONS:
      return {
        ...state,
        positions: action.positions
      };
    default:
      return state;
  }
};

export default reducer;