import React from 'react';
import { StyleSheet, Text, View, Image, Button, TouchableOpacity, ScrollView, Linking, ActivityIndicator } from 'react-native';
import { connect } from 'react-redux';
import { getLeagueRanking } from '../actions/leaguesRankings'; 
import HeaderWithBack from '../../Common/components/HeaderWithBack';

class LeagueDetail extends React.Component {
  static navigationOptions = {
      title: 'Класиране лиги',
  };

  state = {
    loadingMore: false
  }

  getMoreRanking() {
    const id = this.props.navigation.getParam('leagueId');
    this.props.getLeagueRanking(id);
    this.setState({loadingMore: true});
  }

  componentDidMount() {
    this.getMoreRanking();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.leagueRanking !== this.props.leagueRanking && this.state.loadingMore) {
      this.setState({loadingMore: false});
    } 
  }

  openPlayerDetails = userId => {
    this.props.navigation.navigate('Points', { userId });
  }

  isCloseToBottom = ({layoutMeasurement, contentOffset, contentSize}) => {
    const paddingToBottom = 5;
    return layoutMeasurement.height + contentOffset.y >=
      contentSize.height - paddingToBottom;
  };

  renderRankingRow = (ranking, isOwn) => 
   (<TouchableOpacity 
   style={[styles.tableRow, (isOwn===true) ? {backgroundColor: '#D3FBD8', marginBottom: 40} : null]} 
   key={ranking.rank} 
      onPress={() => this.openPlayerDetails(ranking.userId)}>
      <View style={[styles.rowSubView, {flex:2, justifyContent: 'space-between'}]}>

          <View style={styles.rankInfo}>
            {
              // <Ionicons name={ranking.iconName} size={25} 
              //           color={ranking.color} style={{marginRight: 5}} />
            }
            <Text style={styles.boldText}>{ranking.rank}</Text>
          </View>

          <View style={styles.teamInfo}>
              <Text style={styles.boldText}>{ranking.team}</Text> 
              <Text style={[styles.boldText, {fontSize: 10}]}>{ranking.username}</Text>
          </View>
      </View>

      <View style={styles.rowSubView}>
          <Text style={styles.boldText}>{ranking.roundPoints}</Text> 
      </View>

      <View style={[styles.pointsView, (isOwn===true) ? {backgroundColor: '#9FF3A7'} : null]}>
        <Text style={styles.boldText}>{ranking.totalPoints}</Text>
      </View>
    </TouchableOpacity>);
  

  render() {
    if (this.props.leagueRanking.length === 0) return null;

    return (
      <React.Fragment>
      <HeaderWithBack title={"КЛАСИРАНЕ"} navigation={this.props.navigation}/> 
      <View style={styles.headerView}>
          <Text style={styles.headerText}>{this.props.leagueRanking.name}</Text>
      </View>

      <ScrollView style={styles.scrollView} 
                  contentContainerStyle={styles.container}
                  onScroll={({nativeEvent}) => {
                    if (this.isCloseToBottom(nativeEvent) && !this.state.loadingMore) {
                      this.getMoreRanking();
                    }
                  }}
                  scrollEventThrottle={400}>

          <View style={[styles.tableRow, styles.header]}>
            <Text style={{flex:2, marginHorizontal: 30}}>Отбор/Потребител</Text>
            <View style={[styles.rowSubView, {flexDirection: 'column'}]}>
                <Text style={styles.boldText}>Кръг</Text> 
                <Text>точки</Text>
            </View>
            <View style={[styles.rowSubView, {flexDirection: 'column'}]}>
              <Text style={styles.boldText}>Общо</Text>
              <Text>точки</Text> 
            </View>
          </View>
          {this.renderRankingRow(this.props.leagueRanking.loggedUser, true)}
          {this.props.leagueRanking.rankings.map(this.renderRankingRow)}
          {this.state.loadingMore && <ActivityIndicator size="large" color="grey" />}
      </ScrollView>
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getLeagueRanking: id => dispatch(getLeagueRanking(id))
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    leagueRanking: state.leaguesRankings.leagueRanking,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LeagueDetail);

const styles = StyleSheet.create({
  container: {    
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  scrollView: {
    flex: 1,
    width: '100%',
    height: '100%'
  },
  headerView: {
    height: 80,
    width: '100%',
    flexDirection: 'row',
    backgroundColor: 'black',
    alignItems: 'center',
    paddingLeft: 10
  },
  headerText: {
    fontSize: 19,
    fontWeight: 'bold',
    color: '#6FE169',    
  },
  header: {
    backgroundColor: '#CCD4E1',
  },
  tableRow: {
    flex: 1,
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: 'white',
    borderBottomWidth: 1,
    borderColor: '#CCD4E1',
    height:  70
  },
  rowSubView: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    marginVertical: 10,
    marginHorizontal: 10,
  },
  pointsView: {
    flex: 1,
    height: '100%',
    backgroundColor: '#E3E9F4',
    alignItems: 'center',
    justifyContent: 'center'
  },
  teamInfo: {
    width: '70%'
  },
  boldText: {
    fontWeight: 'bold'
  },
  rankInfo: {
    flexDirection: 'row',
    width: 40
  },
  leagueContainer: {
    width: 200,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  rowText: {
    fontSize: 17,
    textAlign: 'center',
    flex: 1,
    fontWeight: 'bold'
  }
});
