import React from 'react';
import { StyleSheet, Text, View, Image, Button, TouchableOpacity, Linking, ScrollView, StatusBar } from 'react-native';
import { connect } from 'react-redux';
import { getNews } from '../actions/news'; 
import HeaderWithBack from '../../Common/components/HeaderWithBack';

class News extends React.Component {
    static navigationOptions = {
        title: 'Новини',
    };
    
    state = {
        newsCategories: [{
            id: 1,
            title: 'FPL FM'
        },
        {
            id: 2,
            title: 'ППФЛ'
        },
        {
            id: 3
        }],
        currentCategoryId: 1
    }

    isSelected = category => {
        return category.id === this.state.currentCategoryId;
    }

    changeTab = category => {
        this.setState({
            currentCategoryId: category.id
        }, () => this.props.getNews(this.state.currentCategoryId));
    }

    componentDidMount() {
        this.props.getNews(1);
    }

  render() {
    if (!this.props.news) return null;
    this.state.newsCategories[2].title = this.props.favouriteTeamName;

    return (
      <ScrollView style={styles.scrollView} contentContainerStyle={styles.container}>
        <HeaderWithBack title={"Новини"} hideBack={true} navigation={this.props.navigation}/> 
        <View style={styles.tabContainer}>
            {this.state.newsCategories.map(category => (
                <TouchableOpacity onPress={() => this.changeTab(category)}
                            style={[styles.tab, 
                            this.isSelected(category) ? styles.selectedTab : null]} 
                    key={category.id}>
                    <Text style={[styles.categoryText, 
                            this.isSelected(category) ? styles.selectedCategoryText : null]}>
                            {category.title}
                    </Text>
                </TouchableOpacity>
            ))}
        </View>
        {this.props.news.map((singleNews,i) => (
            <TouchableOpacity style={styles.newsContainer} key={i}
                            onPress={() => Linking.openURL(singleNews.link)}>
                <Image source={{uri: singleNews.imageUrl}} style={styles.newsImage} />
                <Text style={styles.newsTitle}>{singleNews.title}</Text>
            </TouchableOpacity>
        ))}
      </ScrollView>
    );
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getNews: category => dispatch(getNews(category))
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    news: state.news.news,
    favouriteTeamName: state.home.info.favouriteTeamName
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(News);

const styles = StyleSheet.create({
  scrollView: {
    flex: 1,
    width: '100%',
    height: '100%',
  },
  container: {
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center'
  },
  tabContainer: {
      width: '100%',
      height: 60,
      flexDirection: 'row'
  },
  tab: {
      flex: 1,
      height: '100%',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: '#CCD4E1'
  },
  selectedTab: {
      backgroundColor: '#2053A4'
  },
  categoryText: {
      color: 'black',
      fontSize: 15
  },
  selectedCategoryText: {
      color: 'white'
  },
  newsContainer: {
      flexDirection: 'row',
      height: 150,
      width: '100%',
      marginVertical: 10
  },
  newsImage: {
      height: '90%',
      flex: 1,
      marginHorizontal: 5,
      borderRadius: 10
  },
  newsTitle: {
      fontSize: 17,
      fontWeight: 'bold',
      flex: 1
  }
});
