import React, { Component } from 'react';
import { View, StyleSheet, TouchableOpacity, Image, Text, Dimensions } from 'react-native';
import EStyleSheet from 'react-native-extended-stylesheet';
import back from '../../assets/back-arrow.svg.png';
import drawer from '../../assets/side-menu.png';
import DrawerScreen from './DrawerScreen';
import { connect } from 'react-redux';

class HeaderWithBack extends React.Component {
    state = {
        isDrawerOpened: false
    }

    render() {
        const { title, navigation, style } = this.props;
        const { isDrawerOpened } = this.state;
        return (
            <React.Fragment>
                <View style={[styles.container, style]}>
                    {!this.props.hideBack && <TouchableOpacity onPress={navigation.goBack}>
                        <Image source={back} style={styles.back} />
                    </TouchableOpacity>}

                    <Text style={styles.title}>{title}</Text>
                    {this.props.token && 
                    <TouchableOpacity onPress={() => this.setState({isDrawerOpened: !isDrawerOpened})}>
                        <Image source={drawer} style={styles.drawer} />
                    </TouchableOpacity>
                    }
                </View>
                {this.props.token && 
                <div style={drawerStyles} className={isDrawerOpened ? "slideOpen" : ""}>
                    <DrawerScreen 
                        navigation={navigation} 
                        onClose={()=> this.setState({isDrawerOpened: !isDrawerOpened})}/>
                </div>}
            </React.Fragment>
        )
    }
}

const drawerStyles = {
    position: 'absolute',
    top: 0,
    left: 10000,
    bottom: 0,
    right: 0,
    backgroundColor: 'white',
    zIndex: 999999
}

const styles = EStyleSheet.create({
    container: {
        width: '100%',
        height: '3rem',
        minHeight: 50,
        backgroundColor: 'white',
        alignItems: 'center',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    back: {
        width: 60,
        height: 35,
        resizeMode: 'contain',
        marginRight: 10
    },
    drawer: {
        width: 60,
        height: 35,
        resizeMode: 'contain',        
    },
    title: {
        fontWeight: 'bold',
        fontSize: 20,
        textAlign: 'left',
        width: '60%'
    }
});


const mapStateToProps = (state, ownProps) => {
  return {
    token: state.auth.token
  }
}
 
export default connect(mapStateToProps)(HeaderWithBack);