import React from "react";
import {
  StyleSheet,
  TouchableOpacity,
  Text,
  Dimensions,
  View
} from "react-native";

const popupDialog = props => {
  if (!props.isVisible) return null;

  return (
    <React.Fragment>
      <TouchableOpacity
        style={[styles.container, props.style]}
        onPress={props.onDismiss}/>

      <View style={[styles.popup, props.popupStyle]}>
        {props.title && 
        <View style={styles.header}>
          <Text style={styles.headerText}>{props.title}</Text>
        </View>}

        {props.children}

      </View>
    </React.Fragment>
  );
};

export default popupDialog;

const styles = StyleSheet.create({
  container: {
    width: Dimensions.get("screen").width,
    height: Dimensions.get("screen").height,
    position: 'absolute',
    top: 0,
    left: 0,
    backgroundColor: "black",
    opacity: 0.5,
    zIndex: 20
  },
  popup: {
    position: 'absolute',
    top: 290,
    left: 0,
    width: "100%",
    backgroundColor: "white",
    borderRadius: 20,
    padding: 5,
    zIndex: 20,
    opacity: 1
  },
  header: {
      width: '100%',
      alignItems: 'center',
      justifyContent: 'center',
      paddingVertical: 15,
      backgroundColor: '#E4E9F4',
      borderTopLeftRadius: 20,
      borderTopRightRadius: 20,
      marginBottom: 20
  },
  headerText: {
      fontWeight: 'bold',
      fontSize: 16,
      textAlign: 'center'
  }
});
