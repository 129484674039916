import React from "react";
import {
  StyleSheet,
  TouchableOpacity,
  Text,
  View,
  Image
} from "react-native";
import PopupDialog from '../../Common/components/PopupDialog';

const paymentPopup = props => {
  if (!props.isVisible) return null;

  return (
    <PopupDialog title={props.title} isVisible={props.isVisible} onDismiss={props.onDismiss}>
        <TouchableOpacity style={styles.buttonContainer} onPress={props.onDismiss}>
            <Text style={styles.buttonText}>OK</Text>
        </TouchableOpacity>
    </PopupDialog>
  );
};

export default paymentPopup;

const styles = StyleSheet.create({
  buttonContainer: {
      width: '100%',
      height: 50,
      alignItems: 'center',
      backgroundColor: 'black',
      justifyContent: 'center',
      borderBottomLeftRadius: 10,
      borderBottomRightRadius: 10
  }, 
  buttonText: {
    textAlign: 'center',
    color: 'white',
    fontSize: 15
  }
});
