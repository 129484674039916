import { 
  SET_TEAM, 
  SET_AVAILABLE_PLAYERS, 
  REMOVE_AVAILABLE_PLAYER, 
  RESTORE_AVAILABLE_PLAYER, 
  SET_GROUP,
  RESET_TEAM,
  SET_TEAM_READY,
  SET_CAPTAIN,
  SET_VICE_CAPTAIN,
  SCHEME_CHANGED,
  MAKE_SWAP,
  SORT_PLAYERS,
  CHIP_USED
} from "../actions/actionTypes";
 
const initialState = {
    userTeam: null,
    availablePlayers: null,
    // currentPage: 0,
    // currentPositionId: 0
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case CHIP_USED: {
      const chips = [...state.userTeam.chips];
      const index = chips.findIndex(c => c.ids.free_id === action.chip.ids.free_id && 
                                    c.ids.paid_id === action.chip.ids.paid_id);
      const chip = {...chips[index]};                                  
      chip.active = true;
      chip.available--;
      chips[index] = chip;

      const newTeam = {...state.userTeam, chips};
      return {
        ...state,
        userTeam: newTeam
      }
    }
    case SCHEME_CHANGED: {
      const scheme = state.userTeam.allSchemes.find(s => s.key === action.newSchemeId).label;
      const newTeam = {...state.userTeam, currentScheme: scheme};
      return {
        ...state,
        userTeam: newTeam
      }
    }
    case MAKE_SWAP: {
      const newTeam = {...state.userTeam};
      const playerInIndex = newTeam.reserves.findIndex(p => p.playerId === action.playerInId);
      newTeam.groups = newTeam.groups.map(g => {
        const playerOutIndex = g.players.findIndex(p => p.playerId === action.playerOutId);
        if (playerOutIndex >= 0) {
          const newGroup = {...g};
          const playerOut = newGroup.players[playerOutIndex];
          const playerIn = newTeam.reserves[playerInIndex];
          playerIn.isCaptain = playerOut.isCaptain;
          playerIn.isViceCaptain = playerOut.isViceCaptain;
          playerOut.isViceCaptain = false;
          playerOut.isCaptain = false;
          newGroup.players = [...newGroup.players];
          newGroup.players[playerOutIndex] = {...newTeam.reserves[playerInIndex]};

          newTeam.reserves = [...newTeam.reserves];
          newTeam.reserves[playerInIndex] = {...playerOut};
          return newGroup;
        }
        return g;
      });

      return {
        ...state,
        userTeam: newTeam
      }
    }
    case SET_VICE_CAPTAIN:
    case SET_CAPTAIN: {
      const newTeam = {...state.userTeam};

      //special case - swap captain and vice captain
      let capGroupIndex, capIndex;
      let capResIndex = newTeam.reserves.findIndex(p => p.isCaptain);
      capGroupIndex = capResIndex < 0 && newTeam.groups.findIndex(g => g.players.some(p => p.isCaptain));
      if (capGroupIndex >= 0 || capResIndex >= 0) {
        capIndex = capResIndex < 0 && newTeam.groups[capGroupIndex].players.findIndex(p => p.isCaptain);
        
        let vcapGroupIndex, vcapIndex;
        let vcapResIndex = newTeam.reserves.findIndex(p => p.isViceCaptain);
        vcapGroupIndex = vcapResIndex < 0 && newTeam.groups.findIndex(g => g.players.some(p => p.isViceCaptain));
        vcapIndex = vcapResIndex < 0 && newTeam.groups[vcapGroupIndex].players.findIndex(p => p.isViceCaptain);

        const cap = capResIndex >= 0 ? newTeam.reserves[capResIndex] : newTeam.groups[capGroupIndex].players[capIndex];
        const vcap = vcapResIndex >= 0 ? newTeam.reserves[vcapResIndex] : newTeam.groups[vcapGroupIndex].players[vcapIndex]; 

        if ( (action.type === SET_CAPTAIN && vcap.playerId === action.playerId) || 
            (action.type === SET_VICE_CAPTAIN && cap.playerId === action.playerId)) {
          if (capResIndex >= 0) {
            const newRes = [...newTeam.reserves];
            newRes[capResIndex] = {...newRes[capResIndex], isCaptain: false, isViceCaptain: true}
            newTeam.reserves = newRes;
          } else {
            const newGroups = [...newTeam.groups];
            const newPlayers = [...newGroups[capGroupIndex].players];
            newPlayers[capIndex] = {...newPlayers[capIndex], isCaptain: false, isViceCaptain: true}
            newGroups[capGroupIndex].players = newPlayers;
            newTeam.groups = newGroups;
          }
          if (vcapResIndex >= 0) {
            const newRes = [...newTeam.reserves];
            newRes[vcapResIndex] = {...newRes[vcapResIndex], isCaptain: true, isViceCaptain: false}
            newTeam.reserves = newRes;
          } else {
            const newGroups = [...newTeam.groups];
            const newPlayers = [...newGroups[vcapGroupIndex].players];
            newPlayers[vcapIndex] = {...newPlayers[vcapIndex], isCaptain: true, isViceCaptain: false}
            newGroups[vcapGroupIndex].players = newPlayers;
            newTeam.groups = newGroups;
          }
          return {
            ...state,
            userTeam: newTeam
          }
        }
      }

      newTeam.reserves = newTeam.reserves.map(p => {
          if (p.playerId === action.playerId) {
            p.isCaptain = false;
            p.isViceCaptain = false;
          }
          if (action.type === SET_CAPTAIN) p.isCaptain = false;
          if (action.type === SET_VICE_CAPTAIN) p.isViceCaptain = false;
          return {...p};
      });

      const index = newTeam.reserves.findIndex(p => p.playerId === action.playerId);
      if (index >= 0) {
        const newReserves = {...newTeam.reserves};
        const newPlayer = {...newTeam.reserves[index]};
        newPlayer.isCaptain = action.type === SET_CAPTAIN;
        newPlayer.isViceCaptain = action.type === SET_VICE_CAPTAIN;
        newTeam.reserves[index] = newPlayer;
      }

      newTeam.groups = newTeam.groups.map(g => {
        g.players = g.players.map(p => {
          if (p.playerId === action.playerId) {
            p.isCaptain = false;
            p.isViceCaptain = false;
          }
          if (action.type === SET_CAPTAIN) p.isCaptain = false;
          if (action.type === SET_VICE_CAPTAIN) p.isViceCaptain = false;
          return {...p};
        });
        const playerIndex = g.players.findIndex(p => p.playerId === action.playerId);
        if (playerIndex >= 0) {
          const newGroup = {...g};
          const newPlayer = {...newGroup.players[playerIndex]};
          newPlayer.isCaptain = action.type === SET_CAPTAIN;
          newPlayer.isViceCaptain = action.type === SET_VICE_CAPTAIN;
          newGroup.players[playerIndex] = newPlayer;
          return newGroup;
        }
        return {...g};
      });
      return {
        ...state,
        userTeam: newTeam
      }
    }
    case SET_TEAM_READY: 
    return {
        ...state,
        userTeam: action.userTeam
    }
    case SET_TEAM:
      return {
        ...state,
        userTeam: action.team
      };
    case RESET_TEAM:
      const team = { ...state.userTeam };
      team.groups = team.groups.map(group => {
        const g = {...group};
        g.players = g.players.map(p => null);
        return g;
      });
      return {
        ...state,
        userTeam: team
      };
      
    case SET_AVAILABLE_PLAYERS: 
    return {
        ...state,
        availablePlayers: action.players
      };
    case REMOVE_AVAILABLE_PLAYER: 
    return {
        ...state,
        availablePlayers: state.availablePlayers.filter(p => p.playerId !== action.playerId)
      };
    case RESTORE_AVAILABLE_PLAYER:
    return {
        ...state,
        availablePlayers: [action.player, ...state.availablePlayers]
      }; 
    case SET_GROUP:
      const userTeam = { ...state.userTeam };
      const groupIndex = userTeam.groups.findIndex(g => g.groupId === action.group.groupId);
      userTeam.groups = [...userTeam.groups];
      userTeam.groups[groupIndex] = action.group;

      return {
        ...state,
        userTeam
      }; 

    case SORT_PLAYERS: 
    const availablePlayers = [...state.availablePlayers];
    availablePlayers.sort( (p1, p2) => {
        if (p1[action.criteria.criteria] > p2[action.criteria.criteria]) {
          return action.criteria.order === 'asc' ? 1 : -1;
        }
        if (p1[action.criteria.criteria] < p2[action.criteria.criteria]) {
          return action.criteria.order === 'asc' ? -1 : 1;
        }
        return 0;
    });
    return {
        ...state,
        availablePlayers
      };
    default:
      return state;
  }
};

export default reducer;