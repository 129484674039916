import { 
  LEAGUE_CREATED
} from "../actions/actionTypes";
import { 
  SET_OWN_LEAGUES, 
  INVITATION_SENT,
  INVITATION_NOT_SENT
} from "../../OwnLeagues/actions/actionTypes";
 
const initialState = {
    ownLeagues: [],
    invitationSent: false
};

const reducer = (state = initialState, action) => {
  switch (action.type) {

    case LEAGUE_CREATED:
      return {
        ...state,
        ownLeagues: [...state.ownLeagues,action.league] 
      };

    case SET_OWN_LEAGUES:
      return {
        ...state,
        ownLeagues: action.leagues 
      };
    case INVITATION_SENT: 
     return {
        ...state,
        invitationSent: true 
      };
    case INVITATION_NOT_SENT: 
     return {
        ...state,
        invitationSent: false 
      };

    default:
      return state;
  }
};

export default reducer;