import React, { Component } from 'react';
import { ScrollView, Text, View, StyleSheet, Image, TouchableOpacity, AsyncStorage } from 'react-native';
import { connect } from 'react-redux';
import { getHomeInfo } from '../../Home/actions/home';
import { getUserProfile } from '../../EditProfile/actions/userProfile';
import closeImage from '../../assets/close-menu.png';
import noLogo from '../../assets/nologo.png';


class DrawerScreen extends Component {

  componentDidMount() {
    if (!this.props.homeInfo)
      this.props.getHomeInfo();
    if (!this.props.profile)
      this.props.getUserProfile();
  }

  navigateToScreen = (route) => () => this.props.navigation.navigate(route);

  logout = async () => {
    await AsyncStorage.clear();
    this.props.navigation.navigate('Welcome');
  }

  render () {
    return (
      <View style={{flex:1, zIndex: 99999}}>
        <View style={styles.header}>
            <Text style={styles.headerText}>BAMF <Text style={styles.bold}>Fantasy Manager</Text></Text>
            <TouchableOpacity onPress={this.props.onClose}>
                <Image source={closeImage} style={styles.close} />
            </TouchableOpacity>
        </View>
        <View style={styles.userSubMenu}>
            <View style={styles.photoContainer}>
                <Image style={[styles.image, {borderRadius: 60}]} source={this.props.profile ? this.props.profile.image : noLogo} />
            </View>
            <View style={styles.userContainer}>
                <Text style={styles.username}>{this.props.homeInfo ? this.props.homeInfo.username : ''}</Text>
                <Text onPress={this.navigateToScreen('EditProfile')} style={styles.userMenuItem}>Профил</Text>
                <Text onPress={this.logout} style={styles.userMenuItem}>Изход</Text>
            </View>
        </View>
        <View style={styles.menuContainer}>
            <Text style={styles.menuItem} onPress={this.navigateToScreen('InviteFriend')}>
                Покани играчи
            </Text>
            <Text style={styles.menuItem} onPress={this.navigateToScreen('About')}>
                За Приложението
            </Text>
            <Text style={styles.menuItem} onPress={this.navigateToScreen('OwnLeagues')}>
                Лиги
            </Text>
            <Text style={styles.menuItem} onPress={this.navigateToScreen('Faq')}>
                Често задавани въпроси
            </Text>
            <Text style={styles.menuItem} onPress={this.navigateToScreen('Rules')}>
                Правила
            </Text>
            <Text style={styles.menuItem} onPress={this.navigateToScreen('Awards')}>
                Награди
            </Text>
            <Text style={styles.menuItem} onPress={this.navigateToScreen('EditProfile')}>
                Настройки
            </Text>
        </View>
      </View>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getHomeInfo: () => dispatch(getHomeInfo()),
    getUserProfile: () => dispatch(getUserProfile()),
  };
};

const mapStateToProps = (state, ownProps) => {
  return {
    homeInfo: state.home.info,
    profile: state.userProfile.userProfile,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DrawerScreen);

const styles = StyleSheet.create({
  header: {
      width: '100%',
      paddingHorizontal: 10,
      paddingVertical: 3,
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      flex: 1.3
  },
  headerText: {
      fontSize: 21
  },
  bold: {
      fontWeight: 'bold'
  },
  close: {
      width: 30, 
      height: 30, 
      marginRight: 10,
      marginTop: 10
  },
  image: {
    width: 120, 
    height: 120, 
  },
  userSubMenu: {
      backgroundColor: '#2053A4',
      flexDirection: 'row',
      padding: 20,
      width: '100%'
  },
  username: {
      fontWeight: 'bold',
      color: 'white',
      fontSize: 18
  },
  userMenuItem: {
      color: 'white',
      fontSize: 18
  },
  userContainer: {
      marginLeft: 25,
      justifyContent: 'space-around'
  },
  menuContainer: {
      flex: 10,
      alignItems: 'center',      
      paddingTop: 30
  },
  menuItem: {
      marginVertical: 12,
      fontSize: 18,
      color: '#5A5C6E'
  }
});