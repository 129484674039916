import { SET_INVITATION_MESSAGE } from "./actionTypes";
import { BASE_URL } from '../../Common/config/config';
import { authGetToken } from '../../Common/actions/token';

export const sendInvitations = emails => {
  return dispatch => {
    dispatch(authGetToken())
    .catch(err => {
        console.log('No auth token exists!');
        alert("Please login again, your session is expired!");
    })
    .then(token => {
        return fetch(BASE_URL + '/users/invite', {
            method: 'POST',
            headers: {
                "Content-Type": "application/json; charset=utf-8",
                "Authorization": token
            },
            body: JSON.stringify(emails)
        });
    })
    .catch(err => console.log(err))
    .then(res => res.json())
    .then(res => {
      dispatch(setMessage(res.message))
    });
  };
}

export const setMessage = message => {
  return {
    type: SET_INVITATION_MESSAGE,
    message
  };
};