import { 
  SET_TEAM_FOR_TRANSFERS, 
  SET_AVAILABLE_PLAYERS_FOR_TRANSFER,
  REMOVE_AVAILABLE_PLAYER_FOR_TRANSFER,
  RESTORE_AVAILABLE_PLAYER_FOR_TRANSFER,
  WILD_CARD_USED
} from "../actions/actionTypes";
 
const initialState = {
    userTeam: null,
    availablePlayers: null 
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case WILD_CARD_USED: {
      return {
        ...state,
        userTeam: {
          ...state.userTeam,
          isWildCardActivated: true,
          numberOfWildCards: state.userTeam.numberOfWildCards-1
        }
      }
    }
    case SET_TEAM_FOR_TRANSFERS:
      return {
        ...state,
        userTeam: action.team
      };
    case SET_AVAILABLE_PLAYERS_FOR_TRANSFER: 
      return {
        ...state,
        availablePlayers: action.players
      }
    case REMOVE_AVAILABLE_PLAYER_FOR_TRANSFER: 
    return {
        ...state,
        availablePlayers: state.availablePlayers.filter(p => p.playerId !== action.playerId)
      };
    case RESTORE_AVAILABLE_PLAYER_FOR_TRANSFER:
    return {
        ...state,
        availablePlayers: [action.player, ...state.availablePlayers]
      }; 
    default:
      return state;
  }
};

export default reducer;