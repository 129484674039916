import { SET_LEAGUE_TABLE } from "./actionTypes";
import { BASE_URL } from '../../Common/config/config';
import { authGetToken } from '../../Common/actions/token';
import { setError } from '../../Common/actions/errors';

export const getLeagueTable = () => {
  return dispatch => {
    dispatch(authGetToken())
    .catch(err => {
        console.log('No auth token exists!');
        alert("Please login again, your session is expired!");
    })
    .then(token => {
        return fetch(BASE_URL + '/users/rankings', {
            headers: {
                "Content-Type": "application/json; charset=utf-8",
                "Authorization": token
            }
        });
    })
    .catch(err => console.log(err))
    .then(res => res.json())
    .then(res => {
      if (res.has_error && res.status === 503) {
        dispatch(setError('Кръга е затворен!', 503));
        return;
      }

      if (Array.isArray(res.data)) {
          res.data.forEach(score => {
              score.teamLogoUrl = BASE_URL + score.teamLogoUrl;
          })
      }
      dispatch(setLeagueTable(res.data))
    });
  };
}

export const setLeagueTable = leagueTable => {
  return {
    type: SET_LEAGUE_TABLE,
    leagueTable
  };
};