import React, { Component } from "react";
import { View, Dimensions } from "react-native";
import EStyleSheet from 'react-native-extended-stylesheet';
import WebRoutesGenerator from "../NativeWebRouteWrapper/";


import Home from "../Home/screens/Home";
import Team from '../Team/screens/Team';
import SelectPlayer from '../Team/screens/SelectPlayer';
import PlayerProfile from '../Team/screens/PlayerProfile';
import Rankings from '../Rankings/screens/Rankings';
import Transfers from '../Transfers/screens/Transfers';
import MakeTransfer from '../Transfers/screens/MakeTransfer';
import Results from '../Results/screens/Results';
import LiveScore from '../Results/screens/LiveScore';
import Stats from '../Stats/screens/Stats';
import Points from '../Points/screens/Points';
import Rules from '../Rules/screens/Rules';
import Maintenance from '../Common/screens/Maintenance/Maintenance';
import Welcome from '../Login/screens/Welcome';
import Login from '../Login/screens/Login';
import ForgottenPassword from '../Login/screens/ForgottenPassword';
import Register from '../Register/screens/Register';
import Terms from '../Register/screens/Terms';
import LeagueTable from '../LeagueTable/screens/LeagueTable';
import Leagues from '../Leagues/screens/Leagues';
import LeagueDetails from '../Leagues/screens/LeagueDetail';
import JoinLeague from '../Leagues/screens/JoinLeague';
import GongLeague from '../Leagues/screens/GongLeague';
import CreateLeague from '../Leagues/screens/CreateLeague';
import LeagueCreateSucess from '../Leagues/screens/LeagueCreateSucess';
import News from '../News/screens/News';
import Palms from '../Common/screens/Palms/Palms';


//Drawer screens
import DrawerScreen from '../Common/components/DrawerScreen';
import EditProfile from '../EditProfile/screens/EditProfile';
import InviteFriend from '../InviteFriend/screens/InviteFriend';
import About from '../About/screens/About';
import OwnLeagues from '../OwnLeagues/screens/OwnLeagues';
import Faq from '../FAQ/screens/Faq';
import Awards from '../Awards/screens/Awards';


const routeMap = {
  Welcome: {
    component: Welcome,
    path: '/',
    exact: true
  },
  // Palms: {
  //   component: Palms,
  //   path: "/palms",
  //   exact: true
  // },
  Login: {
    component: Login,
    path: '/login'
  },
  Register: {
    component: Register,
    path: '/register'
  },
  ForgottenPassword: {
    component: ForgottenPassword,
    path: '/forgotten-password'
  },
  Home: {
    component: Home,
    path: "/home"
  },
  Team : {
    component: Team,
    path: '/team'
  },
  SelectPlayer : {
    component: SelectPlayer,
    path: '/selectPlayer'
  },
  PlayerProfile: {
    component: PlayerProfile,
    path: '/playerProfile/:playerId?'
  },
  Rankings: {
    component: Rankings,
    path: '/rankings/:roomId?'
  },
  Transfers: {
    component: Transfers,
    path: '/transfers'
  },
  MakeTransfer: {
    component: MakeTransfer,
    path: '/maketransfer'
  },
  Results: {
    component: Results,
    path: '/results'
  },
  LiveScore: {
    component: LiveScore,
    path: '/livescore/:scoreId?'
  },
  LeagueDetails: {
    component: LeagueDetails,
    path: '/leaguedetails/:leagueId?'
  },
  JoinLeague: {
    component: JoinLeague,
    path: '/joinleague'
  },
  CreateLeague: {
    component: CreateLeague,
    path: '/createleague'
  },
  LeagueCreateSucess: {
    component: LeagueCreateSucess,
    path: '/leaguecreatesucess'
  },
  OwnLeagues: {
    component: OwnLeagues,
    path: '/ownleagues'
  },
  GongLeague: {
    component: GongLeague,
    path: '/gongleague'
  },
  Stats: {
    component: Stats,
    path: '/stats'
  },
  Points: {
    component: Points,
    path: '/points/:userId?'
  },
  LeagueTable: {
    component: LeagueTable,
    path: '/leaguetable'
  },
  Leagues: {
    component: Leagues,
    path: '/leagues'
  },
  News: {
    component: News,
    path: '/news'
  },
  Rules: {
    component: Rules,
    path: '/rules'
  },
  Maintenance: {
    component: Maintenance,
    path: '/maintenance'
  },
  EditProfile: {
    component: EditProfile,
    path: '/editProfile'
  },
  InviteFriend: {
    component: InviteFriend,
    path: '/inviteFriend'
  },
  About: {
    component: About,
    path: '/about'
  },
  Faq: {
    component: Faq,
    path: '/faq'
  },
  Awards: {
    component: Awards,
    path: '/awards'
  },
  Terms: {
    component: Terms,
    path: '/terms'
  }
};

const maxWidth = 500;
class App extends Component {
  render() {
    const { width } = Dimensions.get('window');
    let w = width > maxWidth ? maxWidth :  width;
    EStyleSheet.build({
      $rem: w / 86
    });
    return (
        <View style={{ height: "100vh", width: "100%" }}>
            {WebRoutesGenerator({ routeMap })} 
        </View>
    );
  }
}



export default App;
