import { 
  SET_HOME_INFO, 
  SET_BUDGET, 
  DECREASE_BUDGET,
  INCREASE_BUDGET
 } from "../actions/actionTypes";

const initialState = {
    info: null  
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_HOME_INFO:
      return {
        ...state,
        info: action.info
      };
    case SET_BUDGET: {
      return {
        ...state,
        info: {...state.info, budget: action.budget}
      }
    }
    case DECREASE_BUDGET: {
      if (!state.info) return state;
      return {
        ...state,
        info: {...state.info, budget: state.info.budget - action.sum}
      }
    }
    case INCREASE_BUDGET: {
      if (!state.info) return state;
      return {
        ...state,
        info: {...state.info, budget: state.info.budget + action.sum}
      }
    }

    default:
      return state;
  }
};

export default reducer;