import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
 
 export const SchemeDialog = (props) => {
    const { schemes } = props;
    if (!schemes) return null;
    return(
    <Dialog onClose={props.onClose} aria-labelledby="dialog-title" open={props.open}
    style={{maxHeight: 500}}>
        <DialogTitle id="dialog-title">Изберете схема</DialogTitle>
        <div>
          <List>
            {schemes.map(scheme => (
              <ListItem button onClick={() => props.onClose(scheme)} key={scheme.key}>
                <ListItemText primary={scheme.label} />
              </ListItem>
            ))}
          </List>
        </div>
      </Dialog>
     )
 }
 
 export default SchemeDialog;