import { 
  SET_TEAM, 
  SET_AVAILABLE_PLAYERS, 
  REMOVE_AVAILABLE_PLAYER, 
  RESTORE_AVAILABLE_PLAYER,
  SET_GROUP,
  RESET_TEAM,
  SET_TEAM_READY,
  MAKE_SWAP,
  SCHEME_CHANGED,
  SORT_PLAYERS,
  CHIP_USED
} from "./actionTypes";
import { BASE_URL } from '../../Common/config/config';
import { authGetToken } from '../../Common/actions/token';
import { makeUserReady } from '../../Register/actions/register';
import { decreaseBudget, setBudget } from '../../Home/actions/home';

import { getTeam as getTeamForTransfers } from '../../Transfers/actions/team';
import { uiStartLoading, uiStopLoading } from '../../Common/actions/ui';
import { setError } from '../../Common/actions/errors';


function processTeamIcons(userTeam) {
  userTeam.groups.forEach(g => {
    g.players.forEach(p => {
      p.iconUrl = BASE_URL + p.iconUrl;
      p.realPlayerId = p.id;
      p.playerId = p.id;
    });
  });
  userTeam.reserves.forEach(p => {
    p.iconUrl = BASE_URL + p.iconUrl;
    p.realPlayerId = p.id;
    p.playerId = p.id;
  });
}

export const onUseChip = chip => {
    return dispatch => {
    dispatch(authGetToken())
    .catch(err => {
        console.log('No auth token exists!');
        alert("Please login again, your session is expired!");
    })
    .then(token => {
        const { free_id, paid_id } = chip.ids;
        return fetch(BASE_URL + '/chips/use', {
            method : "POST",
            headers: {
                "Content-Type": "application/json; charset=utf-8",
                "Authorization": token,                
            },
            body: JSON.stringify({free_id, paid_id})
        });
    })
    .catch(err => console.log(err))
    .then(res => res.json())
    .then(res => {
      dispatch(chipUsed(chip));

      //refresh transfers
      dispatch(getTeamForTransfers());
    });
  };
}

export const chipUsed = chip => {
    return {
      type: CHIP_USED,
      chip
  };
}

export const getTeam = () => {
  return (dispatch, getState) => {
    dispatch(authGetToken())
      .catch(err => {
        console.log('No auth token exists!');
        // alert("Please login again, your session is expired!");
      })
      .then(token => {
        return fetch(BASE_URL + '/users/team', {
          headers: {
            "Content-Type": "application/json; charset=utf-8",
            "Authorization": token
          }
        });
      })
      .catch(err => console.log(err))
      .then(res => res.json())
      .then(res => {
        console.log(res);
        if (res.has_error && res.status === 503) {
          dispatch(setError('Кръга е затворен!', 503));
          return;
        } else if (res.has_error && res.status === 400) {
          dispatch(setTeam({

            groups: [
              {
                groupId: 1, players: [null, null]
              },
              {
                groupId: 2, players: [null, null, null, null, null,]
              },
              {
                groupId: 3, players: [null, null, null, null, null,]
              },
              {
                groupId: 4, players: [null, null, null, null, null,]
              }
            ]
          }))
          return;
        }
        const userTeam = res.data;
        if (!userTeam.isReady) {
          const championshipInfo = getState().championship.championshipInfo;
          const {
            user_team_allowed_goalkeepers,
            user_team_allowed_defenders,
            user_team_allowed_half_defenders,
            user_team_allowed_attackers,
            user_team_max_players
          } = championshipInfo;
          const minPlayers = [user_team_allowed_goalkeepers, user_team_allowed_defenders, user_team_allowed_half_defenders, user_team_allowed_attackers];

          userTeam.unfilledCount = user_team_max_players;
          const names = ['Вратар', 'Защитник', 'Полузащитник', 'Нападател'];
          userTeam.groups.forEach((group, i) => {
            if (!group.players) group.players = [];
            group.name = names[i];
            while (group.players.length < minPlayers[i]) {
              group.players.push(null);
            }
          });
        } else {
          processTeamIcons(userTeam);
        }
        dispatch(setTeam(userTeam));
        dispatch(getTeamForTransfers());
      });
  };
}

export const autoFillTeam = () => {
  return (dispatch, getState) => {
    dispatch(authGetToken())
    .catch(err => {
        console.log('No auth token exists!');
        
    })
    .then(token => {
        return fetch(BASE_URL + '/users/team/autofill', {
            method : "POST",
            headers: {
                "Content-Type": "application/json; charset=utf-8",
                "Authorization": token,                
            }
        });
    })
    .catch(err => console.log(err))
    .then(res => res.json())
    .then(res => {
      if (res.has_error && res.status === 503) {
        dispatch(setError('Кръга е затворен!', 503));
        return;
      }

      res.data.groups.forEach(g => {
        g.players.forEach(player => player.iconUrl = BASE_URL + player.iconUrl);
      });
      dispatch(setTeam(res.data));
      dispatch(setBudget(getState().championship.championshipInfo.user_initial_budget - res.data.totalPrice));
    });
  };
}

export const makeTeamReady = () => {
  return (dispatch,getState) => {
    console.log(JSON.stringify(getState().userTeam.userTeam.groups));
    dispatch(authGetToken())
    .catch(err => {
        console.log('No auth token exists!');
       
    })
    .then(token => {
        return fetch(BASE_URL + '/users/team/ready', {
            method : "POST",
            headers: {
                "Content-Type": "application/json; charset=utf-8",
                "Authorization": token,                
            },
            body: JSON.stringify(getState().userTeam.userTeam.groups)
        });
    })
    .catch(err => { 
      console.log(err); 
    })
    .then(res => res.json())
    .then(res => {
      console.log(res);
      if (res.has_error && res.status === 503) {
        dispatch(setError('Кръга е затворен!', 503));
        return;
      }
      
      const userTeam = res.data;
      userTeam.groups.forEach(g => {
          g.players.forEach(p => {
            p.iconUrl = BASE_URL + p.iconUrl;
            p.realPlayerId = p.id;
            p.playerId = p.id;
          });
        });
      userTeam.reserves.forEach(p => {
          p.iconUrl = BASE_URL + p.iconUrl;
          p.realPlayerId = p.id;
          p.playerId = p.id;
      });
      dispatch(setTeamReady(userTeam));
      dispatch(makeUserReady());
      dispatch(getTeamForTransfers());
    });
  };
}

export const setTeamReady = userTeam => {
  return {
    type: SET_TEAM_READY,
    userTeam
  };
}

export const resetTeam = () => {
  return {
    type: RESET_TEAM
  };
}

export const setTeam = team => {
  return {
    type: SET_TEAM,
    team
  };
};

export const getAvailablePlayers = positionId => {
  return dispatch => {
    dispatch(uiStartLoading());
    dispatch(authGetToken())
    .catch(err => {
        console.log('No auth token exists!');
        
    })
    .then(token => {
        // const page = getState().userTeam.currentPage + 1;
        return fetch(BASE_URL + '/players?position_id=' + positionId+ '&sort_by=team', {
            headers: {
                "Content-Type": "application/json; charset=utf-8",
                "Authorization": token
            }
        });
    })
    .catch(err => console.log(err))
    .then(res => res.json())
    .then(res => {
      if (res.has_error && res.status === 503) {
        dispatch(setError('Кръга е затворен!', 503));
        return;
      }

      res.data.forEach(player => player.iconUrl = BASE_URL + player.iconUrl);
      dispatch(setAvailablePlayers(res.data));
      dispatch(uiStopLoading());
    });
  };
}

export const setAvailablePlayers = players => {
  return {
    type: SET_AVAILABLE_PLAYERS,
    players
  };
};

export const sortPlayers = criteria => {
  return {
    type: SORT_PLAYERS,
    criteria
  };
}

export const removeAvailablePlayer = playerId => {
  return {
    type: REMOVE_AVAILABLE_PLAYER,
    playerId
  };
};

export const restoreAvailablePlayer = player => {
  return {
    type: RESTORE_AVAILABLE_PLAYER,
    player
  };
};

export const setGroup = group => {
  return {
    type: SET_GROUP,
    group
  };
};


export const changePlayer = (playerInId, playerOutId) => {
  return dispatch => {
    dispatch(authGetToken())
    .catch(err => {
        console.log('No auth token exists!');
       
    })
    .then(token => {
        return fetch(BASE_URL + '/users/team/swap', {
            method: 'POST',
            headers: {
                "Content-Type": "application/json; charset=utf-8",
                "Authorization": token
            },
            body: JSON.stringify({player_in_id: playerInId, player_out_id: playerOutId})
        });
    })
    .catch(err => console.log(err))
    .then(res => {
      if (res.has_error && res.status === 503) {
        dispatch(setError('Кръга е затворен!', 503));
        return;
      }

      dispatch(swapPlayers(playerInId, playerOutId));
      dispatch(getTeamForTransfers());
    });
  };
}

export const swapPlayers = (playerInId, playerOutId) => {
  return {
    type: MAKE_SWAP,
    playerInId, 
    playerOutId
  };
};


export const changeScheme = id => {
  return (dispatch,getState) => {
    dispatch(authGetToken())
    .catch(err => {
        console.log('No auth token exists!');
        
    })
    .then(token => {
        return fetch(BASE_URL + '/users/schemes/'+id, {
            method : "POST",
            headers: {
                "Content-Type": "application/json; charset=utf-8",
                "Authorization": token,                
            }
        });
    })
    .catch(err => console.log(err))
    .then(res => res.json())
    .then(res => {
      if (res.has_error && res.status === 503) {
        dispatch(setError('Кръга е затворен!', 503));
        return;
      }

      processTeamIcons(res.data);
      dispatch(setTeam(res.data));
      dispatch(schemeChanged(id));
      dispatch(getTeamForTransfers());
    });
  };
}

export const schemeChanged = newSchemeId => {
  return {
    type: SCHEME_CHANGED,
    newSchemeId, 
  };
};