import { 
  SET_ROUNDS, 
} from "./actionTypes";
import { BASE_URL } from '../../Common/config/config';
import { authGetToken } from '../../Common/actions/token';
import { setError } from '../../Common/actions/errors';

export const getRounds = (id) => {
  return dispatch => {
    dispatch(authGetToken())
    .catch(err => {
        console.log('No auth token exists!');
        alert("Please login again, your session is expired!");
    })
    .then(token => {
        return fetch(BASE_URL + `/championships/${id}/rounds`, {
            headers: {
                "Content-Type": "application/json; charset=utf-8",
                "Authorization": token
            }
        });
    })
    .catch(err => console.log(err))
    .then(res => res.json())
    .then(res => {
      if (res.has_error && res.status === 503) {
        dispatch(setError('Кръга е затворен!', 503));
        return;
      }

      dispatch(setRounds(res.data));
    });
  };
}


export const setRounds = rounds => {
  return {
    type: SET_ROUNDS,
    rounds
  };
};