import React from 'react';
import { StyleSheet, Image, Dimensions, View } from 'react-native';
import notLoggedBgImage from '../../assets/login-register-bg.png';
import loggedBgImage from '../../assets/pages-bg.png';
import loadingBgImage from '../../assets/loading-screen.gif';
import livescoreBgImage from '../../assets/livescore-bg.png';


const background = (props) => {
    let image = loggedBgImage;
    if (props.notLogged) image = notLoggedBgImage;
    if (props.loading) image =  loadingBgImage;
    if (props.livescore) image = livescoreBgImage;
    return (<View style={styles.container}>
        <Image style={[styles.image, props.style]} source={image} />
    </View>);

}


export default background;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    zIndex: 0,
    top: 0,
    left: 0,
    position: 'absolute',
    width: '100%',
    maxWidth: 700,
    height: '100%',
    backgroundColor: 'black'
  },
  image: {
    width: '100%',
    minWidth: '100%',
    resizeMode: 'stretch',
    height: '100%',
    maxHeight: 1200
  }
});