import { SET_USER_PROFILE, SET_USER_PROFILE_IMAGE } from "./actionTypes";
import { BASE_URL } from '../../Common/config/config';
import { authGetToken } from '../../Common/actions/token';
import noLogo from '../../assets/nologo.png';
import { setError } from '../../Common/actions/errors';

export const onDeleteProfile = () => {
  return dispatch => {
    dispatch(authGetToken())
    .catch(err => {
        console.log('No auth token exists!');
        alert("Please login again, your session is expired!");
    })
    .then(token => {
        return fetch(BASE_URL + '/users/profile', {
            method: 'DELETE',
            headers: {
                "Content-Type": "application/json; charset=utf-8",
                "Authorization": token
            }
        });
    });
  }
}

export const uploadImage = base64 => {
  return dispatch => {
    dispatch(authGetToken())
    .catch(err => {
        console.log('No auth token exists!');
        alert("Please login again, your session is expired!");
    })
    .then(token => {
        base64 = base64.slice('data:image/jpeg;base64,'.length);
        return fetch(BASE_URL + '/users/image', {
            method: 'POST',
            headers: {
                "Content-Type": "application/json; charset=utf-8",
                "Authorization": token
            },
            body: JSON.stringify({ base64 })
        });
    })
  }
}

export const setUserProfileImage = image => {
  return {
    type: SET_USER_PROFILE_IMAGE,
    image
  };
};

export const updateUserProfile = profile => {
  return dispatch => {
    dispatch(authGetToken())
    .catch(err => {
        console.log('No auth token exists!');
        alert("Please login again, your session is expired!");
    })
    .then(token => {
        profile = {...profile};
        delete profile.image;
        delete profile.favoriteTeam;
        delete profile.favoriteTeamLogo;
        profile.first_name = profile.full_name.split(" ")[0];
        if (profile.full_name.indexOf(' ') >= 0)
          profile.last_name = profile.full_name.split(" ")[1];
        else
          profile.last_name = "";
          
        return fetch(BASE_URL + '/users/profile', {
            method: 'PUT',
            headers: {
                "Content-Type": "application/json; charset=utf-8",
                "Authorization": token
            },
            body: JSON.stringify(profile)
        });
    })
    .catch(err => console.log(err))
    .then(res => res.json())
    .then(res => {
      if (res.has_error && res.status === 503) {
        dispatch(setError('Кръга е затворен!', 503));
        return;
      }
 
      if (res.data.favouriteTeamLogo) {
        res.data.favouriteTeamLogo = {uri:BASE_URL + res.data.favouriteTeamLogo};
      }
      if (res.data.image) {
        res.data.image = {uri:BASE_URL + res.data.image.url};
      } else {
        res.data.image = noLogo;
      }
      dispatch(setUserProfile(res.data))
    });
  };
}

export const getUserProfile = () => {
  return dispatch => {
    dispatch(authGetToken())
    .catch(err => {
        console.log('No auth token exists!');
        alert("Please login again, your session is expired!");
    })
    .then(token => {
        return fetch(BASE_URL + '/users/profile', {
            headers: {
                "Content-Type": "application/json; charset=utf-8",
                "Authorization": token
            }
        });
    })
    .catch(err => console.log(err))
    .then(res => res.json())
    .then(res => {
      if (res.has_error && res.status === 503) {
        dispatch(setError('Кръга е затворен!', 503));
        return;
      }

      if (res.data.favouriteTeamLogo) {
        res.data.favouriteTeamLogo = {uri:BASE_URL + res.data.favouriteTeamLogo};
      }
      if (res.data.image) {
        res.data.image = {uri:BASE_URL + res.data.image.url};
      } else {
        res.data.image = noLogo;
      }
      if (res.data.birthDate.indexOf(" ") > 0) {
        res.data.birthDate = res.data.birthDate.split(" ")[0];
      }
      dispatch(setUserProfile(res.data))
    });
  };
}

export const setUserProfile = userProfile => {
  return {
    type: SET_USER_PROFILE,
    userProfile
  };
};