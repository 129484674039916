export const getPlayerPointsColor = (points, isBackground) => {
    if (points <= 0) {
        return isBackground ? {backgroundColor: "#A50039"} : { color: '#A50039' };
      }
      if (points >= 1 && points <= 3) {
        return isBackground ? {backgroundColor: "#FF085D"} : { color: '#FF085D' };
      }
      if (points >= 4 && points <= 5) {
        return isBackground ? {backgroundColor: "#EBFF00"} : { color: '#EBFF00' };
      }
      if (points >= 6 && points <= 9) {
        return isBackground ? {backgroundColor: "#00FF8E"} : { color: '#00FF8E' };
      }
      return isBackground ? {backgroundColor: "#02894E"} : { color: '#02894E' };
}