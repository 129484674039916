import { SET_USER_PROFILE, SET_USER_PROFILE_IMAGE } from "../actions/actionTypes";

const initialState = {
    userProfile: null  
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER_PROFILE:
      return {
        ...state,
        userProfile: action.userProfile
      };
    case SET_USER_PROFILE_IMAGE: {
      return {
        ...state,
        userProfile: {...state.userProfile, image: action.image}
      }
    }
    default:
      return state;
  }
};

export default reducer;