import React from 'react';
import { StyleSheet, Text, View, Image, Button, TouchableOpacity, ScrollView, Linking, StatusBar } from 'react-native';
import Playground from '../../Common/components/Playground';
import LoadingScreen from '../../Common/components/LoadingScreen';
import Background from '../../Common/components/Background';
import RefreshContainer from '../../Common/components/RefreshContainer';
import PlayerProfilePopup from '../../Team/components/PlayerProfilePopup';
import MdArrowDropdown from 'react-ionicons/lib/MdArrowDropdown';
import RoundsDialog from '../../Common/components/RoundsDialog';
import TabBar from '../../Common/components/TabBar';
import HeaderWithBack from '../../Common/components/HeaderWithBack';

import transferIcon from '../../assets/transfers-arrow.svg.png';
import budgetIcon from '../../assets/budget.svg.png';
import { connect } from 'react-redux';
import { getTeam } from '../actions/team'; 
import { getRounds } from '../actions/rounds'; 
import { getPlayerPointsColor } from "../../Common/utils/utils.js";

class Points extends React.Component {

  state = {
    selectedPlayerId: null,
    refreshing: false
  }

  static navigationOptions = {
    title: 'Точки',
  };

  showPopup = player => {
    this.setState({
      selectedPlayerId : player.realPlayerId
    });
  }

  hidePopup = () => {
    this.setState({
      selectedPlayerId : null
    });
  }

  _onRefresh = () => {
      this.setState({refreshing: true});
      const userId = this.props.navigation.getParam('userId');
      this.props.getTeam(null, userId);      
  }
  
  componentDidMount() {
    this._onRefresh();
    this.props.getRounds(window.localStorage.getItem("championshipId"));
  }

  componentDidUpdate(prevProps) {
    if (this.props.errorCode === 503) {
      this.props.navigation.navigate('Maintenance');
      return;
    }
    if (this.state.refreshing && prevProps.userTeam !== this.props.userTeam) {

        this.setState({refreshing: false});
    }
    if (this.props.navigation !== prevProps.navigation) {
      this._onRefresh()
    }
  }

  closeRounds = round => {
    if (!round.id) return;
    this.setState({roundsOpen: false});
    const userId = this.props.navigation.getParam('userId');
    this.props.getTeam(round.id, userId);      
  };

  render() {
    const { rounds, userTeam } = this.props;

    if (!userTeam || !rounds) return <LoadingScreen />;
    console.log(userTeam)
    const currentRound = rounds.find(r => r.id === userTeam.roundId);
    const ads = this.props.ads.filter(ad => ad.position.id === 5 || ad.position.id === 6);
    
    return (
       <RefreshContainer 
                  refreshing={this.state.refreshing} 
                  onRefresh={this._onRefresh}>
        <Background />
        <HeaderWithBack title={"Точки"} hideBack={true} navigation={this.props.navigation}/> 
        <RoundsDialog 
              open={this.state.roundsOpen} 
              onClose={this.closeRounds} 
              rounds={rounds}/>  

        <PlayerProfilePopup 
          isVisible={this.state.selectedPlayerId} 
          onDismiss={this.hidePopup} 
          playerId={this.state.selectedPlayerId}
          roundId={currentRound.id}
          forPoints={true} />

        <View style={styles.headerView}>
            <View style={styles.statContainer}>
                <View style={styles.statWithIcon}>
                    <Image source={transferIcon} style={styles.headerIcon} />
                    <Text style={styles.statText}>{this.props.userTeam.numberOfTransfers || 0}</Text>
                </View>
                <Text style={styles.statDescription}>Трансфери</Text>
            </View>
            <View style={styles.statContainer}>
                <View style={styles.statWithIcon}>
                    <Text style={styles.statText}>{this.props.userTeam.penaltyPoints || 0}</Text>
                </View>
                <Text style={styles.statDescription}>Наказателни точки</Text>
            </View>
            <View style={styles.statContainer}>
                <View style={styles.statWithIcon}>
                    <Image source={budgetIcon} style={styles.headerIcon} />
                    <Text style={styles.statText}>{this.props.userTeam.budget}</Text>
                </View>
                <Text style={styles.statDescription}>Бюджет</Text>
            </View>
        </View>
        <View style={styles.pointsHeaderView}>
          <View style={styles.roundContainer}>
            <TouchableOpacity style={{width:90}} onPress={() => this.setState({roundsOpen: true})}>
              <Text style={styles.roundText}> {currentRound.name} </Text>
              <MdArrowDropdown fontSize="17px"/>
            </TouchableOpacity>              
          </View>

          <View style={styles.pointsCointainer}>
            <Text style={[styles.points, getPlayerPointsColor(this.props.userTeam.playerPoints || 0)]}>{this.props.userTeam.playerPoints || 0}</Text>
            <Text style={styles.pointsText}>точки</Text>
          </View>

          <View style={styles.pointsCointainer}>
            <Text style={styles.username}>{this.props.userTeam.teamName}</Text>
            <Text style={styles.pointsText}>{this.props.userTeam.userFullName}</Text>
          </View> 
        </View>

        <Playground 
        onSelectPlayer={this.showPopup}
        {...this.props.userTeam}
        isReady={true}
        ads={ads} />
        <TabBar navigation={this.props.navigation}/>
      </RefreshContainer>
    );
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getTeam: (roundId, userId) => dispatch(getTeam(roundId, userId)),
    getRounds: (id) => dispatch(getRounds(id))
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    homeInfo: state.home.info,
    userTeam: state.points.userTeam,
    rounds: state.rounds.rounds,
    errorCode: state.errors.code,
    ads: state.ads.ads
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Points);

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    justifyContent: 'space-between',
    minHeight: '60%'
  },
  scrollView: {
    width: '100%',
    height: '100%'
  },
  headerView: {
    height: 70,
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',    
    justifyContent: 'space-around',
    backgroundColor: '#CCD4E1'
  },
  headerText: {
    fontSize: 17
  },
  headerIcon: {
      width: 20,
      height: 20,
      marginRight: 2
  },
  statWithIcon: {
      flexDirection: 'row',
  },
  statContainer: {
      marginVertical: 7,
      justifyContent: 'center',
      alignItems: 'center'
  },
  statText: {
      fontWeight: 'bold',
      fontSize: 20
  },
  statDescription: {
    fontSize: 14
  },
  pointsHeaderView: {
      height: 70,
      width: '100%',
      backgroundColor: 'rgba(0,0,0,0.5)',
      position: 'absolute',
      top: 120,
      zIndex: 400,
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-around'
  },
  roundContainer: {
    flexDirection: 'row',
    width: 90
  },
  roundText: {
      color: 'white',
      fontSize: 20
  },
  points: {
      color: '#72E86C',
      fontWeight: 'bold',
      fontSize: 25
  },
  pointsText: {
      color: 'white',
      fontSize: 15
  },
  pointsCointainer: {
      justifyContent: 'center',
      alignItems: 'center'
  },
  username: {
      color: 'white',
      fontSize: 18,
      fontWeight: 'bold'
  }
});
