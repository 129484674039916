import React from 'react';
import { 
  StyleSheet, 
  Text, View, 
  Image, 
  TouchableOpacity, 
  ScrollView, 
  StatusBar, 
  Platform,
} from 'react-native';
import { Toggle } from "react-toggle-component"
import { DatePicker } from "material-ui-pickers";
import PlacesAutocomplete from 'react-places-autocomplete';
import PopupDialog from '../../Common/components/PopupDialog';
import Background from '../../Common/components/Background';
import HeaderWithBack from '../../Common/components/HeaderWithBack';
import Input from '../../Common/components/Input';
import Button from '../../Common/components/Button';
import LoadingScreen from '../../Common/components/LoadingScreen';

import ConfirmPopup from '../components/ConfirmPopup';
import emailIcon from '../../assets/email-settings.svg.png';
import userIcon from '../../assets/username-settings.svg.png';
import cityIcon from '../../assets/location-settins.svg.png';
import teamIcon from '../../assets/team-settings.svg.png';
import passIcon from '../../assets/pass-settings.svg.png';
import dateIcon from '../../assets/date-of-birth-settings.svg.png';
import favoriteIcon from '../../assets/favorite-team-settings.svg.png';

import { connect } from 'react-redux';
import { getUserProfile, updateUserProfile, uploadImage, onDeleteProfile } from '../actions/userProfile';

const API_KEY = "AIzaSyAZ72jyvsDD1B8gklELrtYgfmHrQsqHJyc";
class EditProfile extends React.Component {

    state = {
        email : '',
        full_name: '',
        city: '',
        teamName: '',
        image: null,
        birthDate: '',
        favoriteTeam: '',
        favoriteTeamLogo: '',
        newRoundNotifications: false,
        canceledMatchNotifications: false,
        livescoreNotifications: false,
        showDatePicker: false,
        showConfirm: false,
        showSuccess: false
    }

    componentDidMount() {
      this.props.getUserProfile();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.profile !== this.props.profile) {
            this.setState({...this.props.profile});
        }
    }

    pad = num => ("" + num).length < 2 ? "0" + num : "" + num;

    setBirthDate = date => {
      date = new Date(date);
      this.setState(prevState => {
        return {
          birthDateAsDate: date,
          birthDate: date.getFullYear() + "-" +
                this.pad(date.getMonth()+1) + "-" + 
                this.pad(date.getDate()),
        }
      });
    }

    updateUserProfile = () => {
        this.props.updateUserProfile({...this.state});
        this.setState({showSuccess: true})
    }

    showConfirm = () => this.setState({showConfirm: true});
    deleteProfile = () => {
      this.props.onDeleteProfile(); 
      this.setState({showConfirm: false});
    }
    handleChange = city => this.setState({ city });

    uploadImage = e => {
      const files = e.nativeEvent.target.files;
      if (files && files[0]) {
          var FR= new FileReader();
          FR.addEventListener("load", e => {
            this.props.uploadImage(e.target.result);
            this.setState({image : {uri: e.target.result}})
          }); 
          FR.readAsDataURL(files[0]);
      }
    }

    render() {
        if (!this.state.email) return <LoadingScreen />;

        const { image } = this.state;
        console.log(image);
        return (
        <ScrollView style={styles.scrollView} contentContainerStyle={styles.container}>            
            <Background />
            <HeaderWithBack title="Настройки" navigation={this.props.navigation} />
            <TouchableOpacity onPress={() => document.getElementById('fileInput').click()}> 
              <Image className="userPhoto" style={styles.image} source={image} />
              <input onChange={this.uploadImage} id="fileInput" style={{display: 'none'}} type="file" name="myImage" accept="image/x-png,image/gif,image/jpeg" />
            </TouchableOpacity>

            <PopupDialog title="Промените бяха запазени успешно!" 
                      isVisible={this.state.showSuccess} 
                      onDismiss={() => this.setState({showSuccess: false})}
                      popupStyle={{top: '50%'}}>
                <View style={styles.buttons}>
                  <TouchableOpacity style={styles.buttonContainer} 
                    onPress={() => this.setState({showSuccess: false})}>
                      <Text style={styles.buttonText}>OK</Text>
                  </TouchableOpacity>
                </View>
            </PopupDialog>
         
            <View style={styles.profileForm}>
                <View style={styles.formContainer}>
                    <Input placeholder='Имейл адрес'                         
                        image={emailIcon}
                        keyboardType='email-address'
                        inputStyle={{color: 'black'}}
                        onChangeText={(val) => this.setState({email: val})}
                        value={this.state.email} 
                        style={styles.inputStyle}  
                        placeholderTextColor="#B4B4B4"/>

                    <Input placeholder='Име и фамилия'                         
                    image={userIcon}
                    inputStyle={{color: 'black'}}
                    onChangeText={(val) => this.setState({full_name: val})}
                    value={this.state.full_name}
                    style={styles.inputStyle} placeholderTextColor="#B4B4B4" />

                    <View style={styles.autoCompleteContainer}>
                      <Image style={styles.imageStyle} source={cityIcon}/>
                      <PlacesAutocomplete
                        value={this.state.city}
                        onChange={this.handleChange}
                        autoFocus={false}>
                        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                          <div style={{width: '100%'}} autoComplete="off">
                            <input
                              autoFocus={false}
                              style={{color: 'black'}}
                              autoComplete="off"
                              {...getInputProps({
                                placeholder: 'Избери град',
                                className: 'location-search-input',
                              })}
                            />
                            <div className="autocomplete-dropdown-container">
                              {loading && <div>Loading...</div>}
                              {suggestions.map(suggestion => {
                                const className = suggestion.active
                                  ? 'suggestion-item--active'
                                  : 'suggestion-item';
                                // inline style for demonstration purpose
                                const style = suggestion.active
                                  ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                  : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                return (
                                  <div
                                    {...getSuggestionItemProps(suggestion, {
                                      className,
                                      style,
                                    })}
                                  >
                                    <span>{suggestion.description}</span>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        )}
                      </PlacesAutocomplete>              
                    </View> 
                    <Input placeholder='Парола'                         
                        image={passIcon}
                        secureTextEntry
                        inputStyle={{color: 'black'}}
                        onChangeText={(val) => this.setState({password: val})}
                        value={this.state.password}
                        style={styles.inputStyle} placeholderTextColor="#B4B4B4" />

                    <Input placeholder='Потвърди парола'                         
                        image={passIcon}
                        secureTextEntry
                        inputStyle={{color: 'black'}}
                        onChangeText={(val) => this.setState({repeatPassword: val})}
                        value={this.state.repeatPassword}
                        style={styles.inputStyle}  placeholderTextColor="#B4B4B4"/>

                    <Input placeholder='Име на отбор'                         
                    image={teamIcon}
                    inputStyle={{color: 'black'}}
                    onChangeText={(val) => this.setState({teamName: val})}
                    value={this.state.teamName}
                    style={styles.inputStyle} placeholderTextColor="#B4B4B4" />

                    <TouchableOpacity style={{width:'100%'}} onPress={()=>document.querySelector('div.test').click()}>
                      <Input placeholder='Дата на раждане'                         
                        image={dateIcon}
                        value={this.state.birthDate} 
                        placeholderTextColor="#B4B4B4"
                        inputStyle={{color: 'black'}}
                        editable={false}
                        style={styles.inputStyle} />
                    </TouchableOpacity> 

                    <DatePicker 
                    style={{display:'none'}}
                    className="test"
                    value={this.state.birthDate} 
                    open={this.state.pickerOpened} 
                    onChange={this.setBirthDate} />

                    <View style={styles.favoriteContainer}>
                        <Image source={favoriteIcon} style={styles.iconStyle}/>
                        <Text style={{color: '#2053A4', fontSize: 18}}>Любим отбор</Text>
                    </View>
                    <View style={styles.favoriteContainer}>
                        <Image source={this.state.favouriteTeamLogo} style={styles.iconStyle}/>
                        <Text style={{fontSize: 18}}>{this.state.favouriteTeam}</Text>
                    </View>
                </View>
                <View style={styles.notificationsHeader}>
                    <Text style={{color: 'white', fontSize: 18}}>Настройки на известяванията</Text>
                </View>                
                <View style={{flexDirection: 'row', width: '80%', marginTop: 30 }}>
                    <Toggle name="toggle-1" 
                    leftBackgroundColor="tomato"
                    rightBackgroundColor="green"
                    knobColor="white"
                    checked={this.state.newRoundNotifications}
                    borderColor="white"
                    onToggle={e => this.setState({newRoundNotifications: e.target.checked})} />
                    <Text style={{fontSize: 18, marginLeft: 10}}>Нов кръг</Text>
                </View>
                <View style={{flexDirection: 'row', width: '80%', marginTop: 30 }}>
                    <Toggle name="toggle-2" 
                    leftBackgroundColor="tomato"
                    rightBackgroundColor="green"
                    knobColor="white"
                    checked={this.state.canceledMatchNotifications}
                    borderColor="white"
                    onToggle={e => this.setState({canceledMatchNotifications: e.target.checked})} />
                  <Text style={{fontSize: 18, marginLeft: 10}}>Отменен мач</Text>
                </View>
                <View style={{flexDirection: 'row', width: '80%', marginTop: 30 }}>
                  <Toggle name="toggle-3" 
                    leftBackgroundColor="tomato"
                    rightBackgroundColor="green"
                    knobColor="white"
                    checked={this.state.livescoreNotifications}
                    borderColor="white"
                    onToggle={e => this.setState({livescoreNotifications: e.target.checked})} />                
                  <Text style={{fontSize: 18, marginLeft: 10}}>Livescore</Text>
                </View>
                
                <ConfirmPopup 
                isVisible={this.state.showConfirm}
                onDismiss={() => this.setState({showConfirm: false})}
                onConfirm={this.deleteProfile} />

                <Button title='ИЗТРИЙ ЛИЧНИТЕ МИ ДАННИ' 
                style={styles.deleteDataButton} 
                textStyle={{fontWeight: 'bold'}}
                onPress={this.showConfirm} />

                <Button title='ЗАПАЗИ' 
                style={styles.saveButton} 
                textStyle={{color: '#72E86C', fontWeight: 'bold'}}
                onPress={this.updateUserProfile} />
            </View>
        </ScrollView>
        )
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getUserProfile: () => dispatch(getUserProfile()),
    updateUserProfile: profile => dispatch(updateUserProfile(profile)),
    uploadImage: image => dispatch(uploadImage(image)),
    onDeleteProfile: () => dispatch(onDeleteProfile())
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    profile: state.userProfile.userProfile,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditProfile);

const styles = StyleSheet.create({
  scrollView: {    
    width: '100%',
    height: '100%',
  },
  container: {
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center'
  },
  buttonContainer: {
      width: '100%',
      height: 40,
      paddingHorizontal: 5,
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'row',
      backgroundColor: 'black'
  }, 
  buttonText: {
    textAlign: 'center',
    color: 'white',
    fontSize: 20,
    fontWeight: 'bold'
  }, 
  buttons: {
      width: '100%',
      flexDirection: 'row',
      alignItems: 'center',
      backgroundColor: 'black',
      borderBottomLeftRadius: 10,
      borderBottomRightRadius: 10,
      paddingVertical: 10,
  },
  image: {
    width: 120, 
    height: 120, 
    marginVertical: 30,
    marginTop: 130,
    borderRadius: 70
  },
  profileForm: {
      flex: 1,
      width: '100%',
      backgroundColor: 'white',
      alignItems: 'center',
      justifyContent: 'center'
  },
  formContainer: {
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '85%',
      marginTop: 40,
      marginBottom: 50
  },
  favoriTeamInput: {
    borderColor: '#B3833D',
    color: '#B3833D'
  },
  inputStyle: {
    marginVertical: 10,
    color: 'black'
  },
  favoriteContainer: {
      flexDirection: 'row',
      width: '100%',
      marginBottom: 20
  },
  iconStyle: {
      width: 20,
      height: 20,
      marginRight: 5,
      marginTop: 5
  },
  notificationsHeader: {
      backgroundColor: '#2053A4',
      width: '100%',
      paddingHorizontal: 10,
      height: 60,
      alignItems: 'flex-start',
      justifyContent: 'center'
  },
  deleteDataButton: {
    width: '90%',
    backgroundColor: '#CCCCCC',
    marginVertical: 20
  },
  saveButton: {
    width: '90%',
    backgroundColor: '#2053A4',
    marginVertical: 20
  },
  autoCompleteContainer: {
      width: '100%',
      flexDirection: 'row',
      borderBottomWidth: 1,
      borderColor: '#bbb',                     
  },
  imageStyle: {
      width:20,
      height: 20,
      marginTop: 10,
      marginBottom: 5,
      marginLeft: 8
  }
});

const autocompleteStyles = {
  container: {
    heigth: 100
  },
  textInputContainer: {
    width: '100%',                    
    borderTopWidth: 0,
    borderBottomWidth:0,
    backgroundColor: 'transparent',
  },           
  textInput: {
    height: 37,
    borderWidth: 0,
    backgroundColor: 'transparent',
    lineHeight: 20,
    fontSize: 19,
    color: 'black'
  },
  description: {
    fontWeight: 'bold',
    color: 'black'
  },
  predefinedPlacesDescription: {
    color: '#1faadb'
  }
}