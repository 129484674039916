import { 
  SET_LEAGUE_TABLE, 
} from "../actions/actionTypes";
 
const initialState = {
    leagueTable: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {

    case SET_LEAGUE_TABLE:
      return {
        ...state,
        leagueTable: action.leagueTable
      };

    default:
      return state;
  }
};

export default reducer;