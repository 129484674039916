import React from 'react';
import Background from '../../Common/components/Background';
import Input from '../../Common/components/Input';
import { StyleSheet, Text, View, Image, Button, TouchableOpacity, ScrollView, Linking } from 'react-native';
import privateLeague from '../../assets/private-league.png';
import { connect } from 'react-redux';
import { createLeague } from '../actions/leagues'; 
import HeaderWithBack from '../../Common/components/HeaderWithBack';

class CreateLeague extends React.Component {
    state = {
        name: '',
        hasError: false
    }
    
    static navigationOptions = {
        title: 'Частна лига',
    };


    createLeague = () => {
        if (this.state.name.trim().length > 0) {
             this.props.createLeague(this.state.name);
             this.setState({hasError: false});
        } else {
            this.setState({hasError: true});
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.ownLeagues.length < this.props.ownLeagues.length) {
            this.props.navigation.navigate('LeagueCreateSucess');
        }
    }

    render() {
        return (
            <ScrollView style={styles.scrollView} contentContainerStyle={styles.container}>
                <Background />
                <HeaderWithBack title={"СЪЗДАЙ ЛИГА"} navigation={this.props.navigation}/> 
                <View style={{justifyContent: 'center', alignItems: 'center', width: '100%', flex: 1}}>
                    <Image source={privateLeague} style={styles.image} />
                    <Text style={styles.text}>
                        Всеки потребител може да създаде до 10 частни лиги с произволно име на кирилица или латиница до 20 букви.
                    </Text>
                    
                    <Text style={styles.text}>
                        След получаването на 
                        <Text style={styles.emphasize}> уникалния код на лигата, </Text>
                        може с него да поканите приятели/роднини/колеги и да се съревновавате заедно за приза най-добър мениджър!
                    </Text>
                    <Input placeholder='Име на лигата'                         
                    onChangeText={(val) => this.setState({name: val})}
                    style={styles.input}
                    inputStyle={styles.inputStyle}
                    placeholderTextColor='#E4A647'
                    underlineColorAndroid='#E4A647'
                    containerStyle={{width: '75%'}}
                    value={this.state.name}/>
                    {this.state.hasError && <Text style={styles.errorText}>*Моля попълнете име на лигата</Text>}
                    <TouchableOpacity style={[styles.button, {marginBottom: 30}]} 
                    onPress={this.createLeague}>
                        <Text style={styles.confirmText}>СЪЗДАЙ</Text>
                    </TouchableOpacity>
                </View>
            </ScrollView>
        )
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    createLeague: name => dispatch(createLeague(name))
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    ownLeagues: state.ownLeagues.ownLeagues,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateLeague);

const styles = StyleSheet.create({
    scrollView: {
        flex: 1,
        width: '100%',
        minHeight: '100%',
    },
    container : {
        width: '100%',
        minHeight: '100%',
        alignItems: 'center',
        justifyContent: 'center'
    }, 
    image: {
        width: '25%',
        height: 100,
        resizeMode: 'contain'
    },
    text: {
        color: 'white',
        fontSize: 19,
        width: '75%',
        textAlign: 'center',
        marginVertical: 20
    },
    input: {        
        justifyContent: 'center',
        alignItems: 'center',
        borderColor: '#E4A647'
    },
    inputStyle: {
        width: '80%',
        textAlign: 'center',
        color: '#E4A647'
    },
    emphasize: {
        color: '#72E86C'
    },
    confirmText: {
        color: '#E4A647',
        fontWeight: 'bold',
        fontSize: 20
    },
    errorText: {
        color: '#A43B32',
        textAlign: 'center',
        fontSize: 17
    },
    button: {
        marginTop: 30
    }
});