import { TRY_AUTH, AUTH_SET_TOKEN, MAIL_SENT, REGISTER_USER, USER_READY, JOIN_CHAMPIONSHIP, REMOVE_JOIN } from "./actionTypes";
import { BASE_URL } from '../../Common/config/config';
import { tryAuth } from '../../Login/actions/auth';
import { setError } from '../../Common/actions/errors';
import { uiStartLoading, uiStopLoading } from "../../Common/actions/ui";
import { authGetToken } from '../../Common/actions/token';
import { changeActiveChampionship } from "../../Home/actions/home";

export const tryRegister = data => {
  return dispatch => {
    console.log(JSON.stringify(data));
    dispatch(uiStartLoading());
    fetch(BASE_URL + '/users', {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json"
      }
    })
      .catch(err => {
        console.log(err);
        alert("Registration failed, please try again!");
        dispatch(uiStopLoading());
      })
      .then(res => res.json())
      .then(res => {
        if (res.has_error) {
          dispatch(setError(res.message));
        } else {
          dispatch(setError(''));
          dispatch(setNewUser());
          dispatch(tryAuth({ email: data.email, password: data.password }));
        }
      });
  };
}

export const joinChampionship = data => {
  return dispatch => {
    console.log(JSON.stringify(data));
    dispatch(authGetToken())
      .catch(err => {
        console.log('No auth token exists!');
        // alert("Please login again, your session is expired!");
      })
      .then(token => {
        dispatch(uiStartLoading());
        return fetch(BASE_URL + '/users/leagues/join', {
          method: "POST",
          body: JSON.stringify(data),
          headers: {
            "Content-Type": "application/json",
            "Authorization": token
          }
        })
      })
      .catch(err => {
        console.log(err);
        alert("Registration failed, please try again!");
        dispatch(uiStopLoading());
      })
      .then(res => res.json())
      .then(res => {
        localStorage.setItem("champ_id", data.championship_id);
        dispatch(changeActiveChampionship(data.championship_id))
        dispatch(setNewUser());
        dispatch(setJoinedLeague())


      });
  };
}

export const setJoinedLeague = () => {
  return {
    type: JOIN_CHAMPIONSHIP
  }
}

export const removeJoin = () => {
  return dispatch => {
    dispatch(() => {
      return {
        type: REMOVE_JOIN
      }
    })
  }
}

export const setNewUser = () => {
  return {
    type: REGISTER_USER
  };
};

export const makeUserReady = () => {
  return {
    type: USER_READY,
  };
};