import { SET_OWN_LEAGUES, INVITATION_SENT, INVITATION_NOT_SENT } from "./actionTypes";
import { BASE_URL } from '../../Common/config/config';
import { authGetToken } from '../../Common/actions/token';

export const getOwnLeagues = () => {
  return dispatch => {
    dispatch(authGetToken())
    .catch(err => {
        console.log('No auth token exists!');
        alert("Please login again, your session is expired!");
    })
    .then(token => {
        return fetch(BASE_URL + '/users/leagues/own', {
            headers: {
                "Content-Type": "application/json; charset=utf-8",
                "Authorization": token
            },
        });
    })
    .catch(err => console.log(err))
    .then(res => res.json())
    .then(res => {
      dispatch(setOwnLeagues(res.data))
    });
  };
}

export const setOwnLeagues = leagues => {
  return {
    type: SET_OWN_LEAGUES,
    leagues
  };
};

export const sendInvitation = (email, id) => {
  return dispatch => {
    dispatch(authGetToken())
    .catch(err => {
        console.log('No auth token exists!');
        alert("Please login again, your session is expired!");
    })
    .then(token => {
        return fetch(BASE_URL + '/users/leagues/invite', {
            method: 'POST',
            headers: {
                "Content-Type": "application/json; charset=utf-8",
                "Authorization": token
            },
            body: JSON.stringify({league_id: id, email: email})
        });
    })
    .catch(err => console.log(err))
    .then(res => {
      dispatch(invitationSent())
    });
  };
}

export const invitationSent = () => {
  return {
    type: INVITATION_SENT
  };
};

export const invitationNotSent = () => {
  return {
    type: INVITATION_NOT_SENT
  };
};