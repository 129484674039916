import React from 'react';
import { StyleSheet, Text, View, Image, TouchableOpacity, ScrollView, Dimensions } from 'react-native';
import Background from '../../Common/components/Background';
import HeaderWithBack from '../../Common/components/HeaderWithBack';

class Faq extends React.Component {

    render() {
        return (
            <React.Fragment>
                <HeaderWithBack title="Често задавани въпроси" navigation={this.props.navigation} />
                <ScrollView style={styles.scrollView} contentContainerStyle={styles.container}>
                    <Background style={{ height: Dimensions.get('screen').height * 1.8 }} />
                    <View style={{ width: '100%', flex: 1 }}>
                        <Text style={styles.headingText}>Често задавани въпроси</Text>


                        <Text style={styles.aboutHeading}>Как мога да играя в играта?</Text>
                        <View style={styles.aboutContainer}>
                            <Text style={styles.aboutText}>
                                Като се регистрираш използвайки уеб версията на адрес <a href={"https://fantasy.bamf.bg"}>fantasy.bamf.bg</a> или като изтеглиш
приложението за Android и iOS (очаквайте скоро!)
                        </Text>
                        </View>

                        <Text style={styles.aboutHeading}>Кога стартира играта?</Text>
                        <View style={styles.aboutContainer}>
                            <Text style={styles.aboutText}>
                                Старта на играта зависи от старта на всеки отделен шампионат в БАМФ.
                        </Text>
                        </View>

                        <Text style={styles.aboutHeading}>Кога мога да се включва в играта?</Text>
                        <View style={styles.aboutContainer}>

                            <Text style={styles.aboutText}>Можеш да се включиш в играта по всяко време, но ще започнеш от кръга на шампионата, след
                            текущия. Ако се регистрираш в Кръг 5, ще можеш да стартираш със своя отбор в Кръг 6.
                            </Text>
                        </View>

                        <Text style={styles.aboutHeading}>Мога ли да играя в почече от един шампионат с един акаунт?</Text>
                        <View style={styles.aboutContainer}>
                            <Text style={styles.aboutText}>
                                Да, това е възможно. В Начало, от най-горната лента с името на шампионата, в който участвате,
след натискане на падащото меню, изберете <strong>ДОБАВИ НОВ ШАМПИОНАТ</strong>.
                        </Text>
                        </View>

                        <Text style={styles.aboutHeading}>Как мога да се свържа с вас при технически проблем?</Text>
                        <View style={styles.aboutContainer}>
                            <Text style={styles.aboutText}>
                                Пиши ни на имейл fantasy@bamf.bg
                        </Text>
                        </View>


                    </View>
                </ScrollView>
            </React.Fragment>
        )
    }
}

export default Faq;

const styles = StyleSheet.create({
    scrollView: {
        width: '100%',
    },
    container: {
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center'
    },
    headingText: {
        fontWeight: 'bold',
        color: 'white',
        fontSize: 24,
        marginBottom: 12,
        marginLeft: 15,
        width: '100%',
        marginTop: 220
    },
    aboutHeading: {
        color: 'white',
        fontSize: 20,
        width: '100%',
        paddingLeft: 10,
        marginVertical: 15
    },
    aboutContainer: {
        backgroundColor: '#E7EAF9',
        width: '100%',
        padding: 10,
    },
    aboutText: {
        fontSize: 17,
        marginVertical: 15
    },
    version: {
        fontWeight: 'bold',
        fontSize: 17,
    }
});