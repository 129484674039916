import { SET_POSITIONS } from "./actionTypes";
import { BASE_URL } from '../../Common/config/config';
import { authGetToken } from '../../Common/actions/token';

export const getAllPositions = () => {
    return dispatch => {
    dispatch(authGetToken())
    .catch(err => {
        console.log('No auth token exists!');
        alert("Please login again, your session is expired!");
    })
    .then(token => {
      return fetch(BASE_URL + '/players/positions', {
          headers: {
            "Content-Type": "application/json",
            "Authorization" : token
          }
      })
    })
    .catch(err => {
      console.log(err);
      alert("Get all teams failed, please try again!");
    })
    .then(res => res.json())
    .then(res => {
      dispatch(setPositions(res.data));
    })
  };
}


export const setPositions = positions => {
  return {
    type: SET_POSITIONS,
    positions
  };
};