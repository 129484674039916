import { SET_CHAMPIONSHIP_INFO, GET_ALL_CHAMPIONSHIPS, GET_ACTIVE_CHAMPIONSHIP, GET_USER_CHAMPIONSHIPS } from "./actionTypes";
import { BASE_URL } from '../../Common/config/config';
import { authGetToken } from '../../Common/actions/token';

export const getChampionshipInfo = () => {
  return dispatch => {
    dispatch(authGetToken())
    .catch(err => {
        console.log('No auth token exists!');
        // alert("Please login again, your session is expired!");
    })
    .then(token => {
        return fetch(BASE_URL + '/championships', {
            headers: {
                "Content-Type": "application/json; charset=utf-8",
                "Authorization": token
            }
        });
    })
    .catch(err => console.log(err))
    .then(res => res.json())
    .then(res => dispatch(setChampionshipInfo(res.data)));
  };
}


export const setChampionshipInfo = info => {
  return {
    type: SET_CHAMPIONSHIP_INFO,
    info
  };
};

export const getAllChampionships = () => {
  return dispatch => {
    dispatch(authGetToken())
      .catch(err => {
        console.log('No auth token exists!');
        //alert("Please login again, your session is expired!");
      })
      .then(token => {
        return fetch(BASE_URL + '/championships/all', {
          headers: {
            "Content-Type": "application/json; charset=utf-8",
            "Authorization": token
          }
        });
      })
      .catch(err => console.log(err))
      .then(res => res.json())
      .then(res => dispatch(setAllChapmpionships(res.data)));
  };
}


export const setAllChapmpionships = info => {
  return {
    type: GET_ALL_CHAMPIONSHIPS,
    info
  };
};

export const getUserChampionships = () => {
 console.log("getting user champs")
  return dispatch => {
    dispatch(authGetToken())
      .catch(err => {
        console.log('No auth token exists!');
        //alert("Please login again, your session is expired!");
      })
      .then(token => {
        return fetch(BASE_URL + '/user/championships', {
          headers: {
            "Content-Type": "application/json; charset=utf-8",
            "Authorization": token
          }
        });
      })
      .catch(err => console.log(err))
      .then(res => res.json())
      .then(res => dispatch(setUserChampionships(res.data)));
  };
}


export const setUserChampionships = info => {
  console.log(info);
  return {
    type: GET_USER_CHAMPIONSHIPS,
    info
  };
};


export const getActiveChampionship = () => {
  return dispatch => {
    dispatch(authGetToken())
      .catch(err => {
        console.log('No auth token exists!');
        //alert("Please login again, your session is expired!");
      })
      .then(token => {
        return fetch(BASE_URL + '/championships', {
          headers: {
            "Content-Type": "application/json; charset=utf-8",
            "Authorization": token
          }
        });
      })
      .catch(err => console.log(err))
      .then(res => res.json())
      .then(res => dispatch(setActiveChampionship(res.data)));
  };
}


export const setActiveChampionship = info => {
  return {
    type: GET_ACTIVE_CHAMPIONSHIP,
    info
  };
};