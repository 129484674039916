import { SET_PLAYER_SHORT_INFO } from "../actions/actionTypes";

const initialState = {
    playerShortInfo: null,    
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PLAYER_SHORT_INFO:
      return {
        ...state,
        playerShortInfo: action.playerInfo
      };
    default:
      return state;
  }
};

export default reducer;