import React from 'react';
import { StyleSheet, Text, View, Image, Button, TouchableOpacity, ScrollView, Linking } from 'react-native';
// import ModalSelector from 'react-native-modal-selector';
import EStyleSheet from 'react-native-extended-stylesheet';

import MdArrowDropdown from 'react-ionicons/lib/MdArrowDropdown';
import Background from '../../Common/components/Background';
import PaymentPopup from '../../Home/components/PaymentPopup';
import Playground from '../../Common/components/Playground';
import BudgetContainer from '../../Common/components/BudgetContainer';
import LoadingScreen from '../../Common/components/LoadingScreen';
import TabBar from '../../Common/components/TabBar';
import PlayerPopup from '../components/PlayerPopup';
import ChipPopup from '../components/ChipPopup';
import SchemeDialog from '../components/SchemeDialog';
import HeaderWithBack from '../../Common/components/HeaderWithBack';

import autoFillImage from '../../assets/generate-team.svg.png';
import resetTeamIcon from '../../assets/close-popup.svg.png';

import { connect } from 'react-redux';
import {
  getTeam,
  autoFillTeam,
  resetTeam,
  makeTeamReady,
  changePlayer,
  changeScheme,
  onUseChip
} from '../actions/team';
import { setBudget } from '../../Home/actions/home';

class Team extends React.Component {

  state = {
    teamAutoGenerated: false,
    selectedPlayer: null,
    selectedChip: null,
    paymentPopupVisible: false,
    choosenChip: '',
  }

  static navigationOptions = {
    title: 'Отбор',
  };

  componentDidMount() {
    if (!this.props.championshipInfo) {
      this.props.navigation.navigate('Home');
      return;
    }
    if (sessionStorage.getItem("currentGroup")) {
      sessionStorage.removeItem("currentGroup");
    } else {
      this.props.getTeam();
    }
    
  }

  componentDidUpdate(prevProps) {
    if (this.props.errorCode === 503) {
      this.props.navigation.navigate('Maintenance');
    }
    if (this.props.homeInfo !== prevProps.homeInfo) {
      if (!this.state.teamAutoGenerated)
        this.props.getTeam();
    }
    if (this.props.navigation !== prevProps.navigation) {
      this.props.getTeam();
    }
  }

  showPlayerPopup = (player, isReserve) => {
    this.setState({ selectedPlayer: player, isSelectedReserve: isReserve })
  }

  hidePlayerPopup = () => {
    this.setState({ selectedPlayer: null })
  }

  buyChip = choosenChip => {
    if (!choosenChip.canBuy) return;
    this.setState({
      paymentPopupVisible: true,
      choosenChip
    });
  }

  showChipPopup = chip => {
    if (chip.forBuy) {
      this.setState({ paymentPopupVisible: true });
      return;
    }
    if (this.props.userTeam.chips.some(c => c.active)) return;
    this.setState({ selectedChip: chip });
  }

  hidePaymentPopup = () => this.setState({ paymentPopupVisible: false });
  hideChipPopup = () => this.setState({ selectedChip: null });

  useChip = () => {
    this.props.onUseChip(this.state.selectedChip);
    this.setState({ selectedChip: null });
  }

  addPlayerToTeam = (index, group) => {
    sessionStorage.setItem('currentGroup', JSON.stringify(group));
    this.props.navigation.navigate('SelectPlayer');
  }

  autoFillTeam = () => {
    this.props.autoFillTeam();
    this.setState({ teamAutoGenerated: true });
  }

  resetTeam = () => {
    this.props.setBudget(this.props.championshipInfo.user_initial_budget);
    this.props.resetTeam();
    this.setState({ teamAutoGenerated: false });
  }

  setTeamReady = () => {
    if (this.props.budget < 0) {
      alert("Разполагаемият бюджет е по-малък от 0!");
      return;
    }
    if (this.getUnfilledCount() > 0)
      return;
    this.props.makeTeamReady();
  }

  makeChange = player => {
    if (this.state.isSelectedReserve)
      this.props.changePlayer(this.state.selectedPlayer.playerId, player.playerId);
    else
      this.props.changePlayer(player.playerId, this.state.selectedPlayer.playerId);
    this.hidePlayerPopup();
  }

  changeScheme = id => this.props.changeScheme(id);

  renderChipButton = chip => {
    console.log(chip)
    const forBuy = chip.available == 0 && chip.can_buy;
    const disabled = this.props.userTeam.chips.some(c => c.active);
    const finished = chip.available == 0 && !chip.can_buy;

    let chipStyles = [styles.useChipButton];
    let textStyles = [styles.chipText];


    if (disabled) chipStyles.push(styles.disabledChip);
    if (forBuy) {
      chipStyles.push(styles.canBuyChip);
      textStyles.push({ color: 'black' });
    }
    if (finished) {
      chipStyles.push(styles.finishedChip);
    }
    chip.forBuy = forBuy;

    return (
      <TouchableOpacity key={Math.random() * 1000}
        style={chipStyles}
        onPress={() => !disabled ? this.showChipPopup(chip) : null}>
        {chip.active && <Text style={styles.activeText}>АКТИВИРАН</Text>}
        {forBuy && <Text style={[styles.activeText, { color: 'white' }]}>КУПИ</Text>}
        {finished && <Text style={[styles.activeText, { color: '#73C564' }]}>ИЗЧЕРПАН</Text>}
        <Text style={textStyles}>{chip.name.toUpperCase()}</Text>
        {chip.available == 2 && <Text style={[styles.activeText, { color: '#A79F6D' }]}>(2)</Text>}
      </TouchableOpacity>)
  }

  getUnfilledCount = () => {
    let unfilledCount = 0;
    this.props.userTeam.groups.forEach(g => {
      g.players.forEach(p => {
        if (p === null) unfilledCount++;
      });
    });
    return unfilledCount;
  }

  showSchemeDialog = () => this.setState({ schemeDialogOpened: true });
  changeScheme = id => {
    if (id) this.props.changeScheme(id);
    this.setState({ schemeDialogOpened: false });
  }

  render() {
    if (!this.props.userTeam) {
      return <LoadingScreen />;
    }
    const ads = this.props.ads.filter(ad => ad.position.id === 3 || ad.position.id === 4);
    console.log(ads)

    const unfilledCount = this.getUnfilledCount();
    const confirmButtonTeamStyles = [styles.confirmButton];
    if (unfilledCount > 0)
      confirmButtonTeamStyles.push({ backgroundColor: 'rgba(225,203,77, 0.6)' });


    return (
      <ScrollView contentContainerStyle={styles.container} style={styles.scrollView}>
        <Background />
        
        <HeaderWithBack title={"Отбор"} hideBack={true} navigation={this.props.navigation} />
        <SchemeDialog open={this.state.schemeDialogOpened}
          onClose={({ key }) => this.changeScheme(key)}
          schemes={this.props.userTeam.allSchemes} />

        {this.state.selectedPlayer &&
          <PlayerPopup
            onDismiss={this.hidePlayerPopup}
            onSelectPlayerForChange={this.makeChange}
            navigation={this.props.navigation}
            {...this.state.selectedPlayer} />
        }

        {this.state.selectedChip &&
          <ChipPopup
            chip={this.state.selectedChip}
            onDismiss={this.hideChipPopup}
            onConfirm={this.useChip} />
        }
        {//TODO: payments
        }
        <PaymentPopup
          isVisible={this.state.paymentPopupVisible}
          hideSmsCode={false}
          onBankCardSelected={this.hidePaymentPopup}
          onSmsCodeSelected={this.hidePaymentPopup}
          onDismiss={this.hidePaymentPopup} />

        <View style={styles.headerView}>
          {this.props.userTeam.isReady ?
            <React.Fragment>
              <Text style={styles.headerText}>{"КРЪГ " + this.props.userTeam.currentRound}</Text>
              <TouchableOpacity style={styles.schemeContainer} onPress={this.showSchemeDialog}>
                <Text style={styles.headerText}> {this.props.userTeam.currentScheme} </Text>
                <MdArrowDropdown fontSize="17px" style={{ marginRight: 5, marginTop: 3 }} />
              </TouchableOpacity>
            </React.Fragment>
            :
            <React.Fragment>
              <Text style={{ flex: 3 }}>Остават още {unfilledCount} играча</Text>
              <BudgetContainer budget={this.props.budget} />
            </React.Fragment>
          }
        </View>

        {this.props.userTeam.isReady ?
          <View style={styles.chipsContainer}>
           {this.props.userTeam.chips.map(chip => this.renderChipButton(chip))}
          </View> :
          <React.Fragment>
            {this.state.teamAutoGenerated &&
              <TouchableOpacity style={styles.autoCleanContainer}
                onPress={this.resetTeam}>
                <Text style={styles.autoFillText}>ИЗЧИСТИ</Text>
                <Image source={resetTeamIcon} style={styles.autoFillImage} />
              </TouchableOpacity>}
            <TouchableOpacity style={styles.autoFillContainer} onPress={this.autoFillTeam}>
              <Text style={styles.autoFillText}>АВТОМАТИЧНО ИЗБИРАНЕ НА ОТБОР</Text>
              <Image style={styles.autoFillImage} source={autoFillImage} />
            </TouchableOpacity>
          </React.Fragment>}
          
        <Playground
          onSelectPlayer={this.showPlayerPopup}
          showPrice={!this.props.userTeam.isReady}
          onAddPlayer={this.addPlayerToTeam}
          ads={ads}
          {...this.props.userTeam} />

        {!this.props.userTeam.isReady &&
          <TouchableOpacity onPress={this.setTeamReady} style={confirmButtonTeamStyles}>
            <Text style={styles.buttonText}>СЪЗДАЙ ОТБОР</Text>
          </TouchableOpacity>}
        <TabBar navigation={this.props.navigation} />
      </ScrollView>
    );
  }
}


const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getTeam: () => dispatch(getTeam()),
    autoFillTeam: () => dispatch(autoFillTeam()),
    resetTeam: () => dispatch(resetTeam()),
    makeTeamReady: () => dispatch(makeTeamReady()),
    changePlayer: (playerInId, playerOutId) => dispatch(changePlayer(playerInId, playerOutId)),
    changeScheme: id => dispatch(changeScheme(id)),
    setBudget: budget => dispatch(setBudget(budget)),
    onUseChip: chip => dispatch(onUseChip(chip))
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    userTeam: state.userTeam.userTeam,
    championshipInfo: state.championship.championshipInfo,
    budget: state.home.info ? state.home.info.budget : 0,
    homeInfo: state.home.info,
    errorCode: state.errors.code,
    ads: state.ads.ads
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Team);

const styles = EStyleSheet.create({
  container: {
    width: '100%',
    alignItems: 'center',
    justifyContent: 'flex-start'
  },
  scrollView: {
    width: '100%'
  },
  headerView: {
    height: 60,
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: '#CCD4E1',
    zIndex: 2
  },
  headerText: {
    fontSize: 17
  },
  schemeContainer: {
    flexDirection: 'row',
    width: 80
  },
  chipsContainer: {
    position: 'absolute',
    top: 130,
    zIndex: 5,
    width: '100%',
    flexDirection: 'row',
    backgroundColor: 'transparent',
    alignItems: 'center',
    justifyContent: 'center',
    marginHorizontal: 20
  },
  canBuyChip: {
    backgroundColor: '#69D380',
    borderWidth: 1,
    borderColor: 'white',
  },
  finishedChip: {
    backgroundColor: '#A33C32'
  },
  useChipButton: {
    backgroundColor: '#2053A4',
    borderWidth: 1,
    borderColor: '#3474D5',
    paddingHorizontal: '0.2rem',
    marginHorizontal: 5,
    borderRadius: 3,
    height: 50,
    justifyContent: 'center',
    alignItems: 'center'
  },
  chipText: {
    color: 'white',
    fontSize: 10
  },
  disabledChip: {
    opacity: 0.5
  },
  activeText: {
    color: '#63CE76'
  },
  autoFillContainer: {
    width: '50%',
    marginTop: 45,
    borderRadius: 10,
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    backgroundColor: '#2053A4',
    paddingVertical: 7,
    position: 'absolute',
    zIndex: 3,
    top: 110
  },
  autoCleanContainer: {
    width: '50%',
    marginTop: 40,
    borderRadius: 10,
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    backgroundColor: '#B75C5D',
    paddingVertical: 7,
    position: 'absolute',
    zIndex: 3,
    top: 75
  },
  autoFillImage: {
    width: 20,
    height: 20
  },
  autoFillText: {
    fontSize: 11,
    color: 'white',
    width: '70%',
    textAlign: 'center'
  },
  confirmButton: {
    justifyContent: 'center',
    alignItems: 'center',
    width: '50%',
    height: 50,
    backgroundColor: '#E1CB4D',
    borderRadius: 10,
    marginTop: 10,
    marginLeft: '5%',
    marginBottom: 10
  },
  buttonText: {
    fontWeight: 'bold',
    fontSize: 14
  }
});