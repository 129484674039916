import React from 'react';
import Background from '../../Common/components/Background';
import Input from '../../Common/components/Input';
import InfoPopup from '../../Home/components/InfoPopup';
import { StyleSheet, Text, View, Image, Button, TouchableOpacity, ScrollView, Linking } from 'react-native';
import privateLeague from '../../assets/private-league.png';
import HeaderWithBack from '../../Common/components/HeaderWithBack';
import { connect } from 'react-redux';
import { joinLeague } from '../actions/leagues'; 


class JoinLeague extends React.Component {

    state = {
        code: '',
        hasError: false,
        showSuccessPopup: false
    }
    
    static navigationOptions = {
        title: 'Лиги',
    };

    joinLeague = () => {
        if (this.state.code.trim().length > 0) {
            this.props.joinLeague(this.state.code);
            this.setState({hasError: false});
        } else {
            this.setState({hasError: true});
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.message !== this.props.message) {
            this.setState({showSuccessPopup : true})
        }
    }

    render() {
        return (
            <ScrollView style={styles.scrollView} contentContainerStyle={styles.container}>
             <InfoPopup title={this.props.message && this.props.message.toUpperCase()}
                isVisible={this.state.showSuccessPopup} 
                onDismiss={() => this.setState({showSuccessPopup: false}) }/>

                <Background />
                <HeaderWithBack title={"ВКЛЮЧИ СЕ В ЛИГА"} navigation={this.props.navigation}/> 
                <View style={{justifyContent: 'center', alignItems: 'center', width: '100%', flex: 1}}>
                    <Image source={privateLeague} style={styles.image} />
                    <Text style={styles.text}>
                        В приложението съществуват 2 типа лиги - Публични и Частни
                    </Text>
                    <Text style={styles.text}>
                        <Text style={styles.emphasize}>Публичните лиги</Text> са 3 на брой и в тях участва всеки потребител.
                    </Text>
                    <Text style={styles.text}>
                        <Text style={styles.emphasize}>Частните лиги </Text>  
                        се създават от потребителите, които могат да канят в тях свои приятели/роднини/колеги.
                    </Text>
                    <Input placeholder='Въведи код за частна лига'                         
                    onChangeText={(val) => this.setState({code: val})}
                    style={styles.input}
                    inputStyle={styles.inputStyle}
                    placeholderTextColor='#E4A647'
                    underlineColorAndroid='#E4A647'
                    containerStyle={{width: '75%'}}
                    value={this.state.code}/>
                    {this.state.hasError && <Text style={styles.errorText}>Не е налична лига с такъв код!</Text>}
                    <TouchableOpacity style={styles.button} onPress={this.joinLeague}>
                        <Text style={styles.confirmText}>ПОТВЪРДИ</Text>
                    </TouchableOpacity>
                </View>
            </ScrollView>
        )
    }
}


const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    joinLeague: code => dispatch(joinLeague(code))
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    leagues: state.leaguesRankings.leaguesRankings,
    message: state.leaguesRankings.joinedLeagueMessage
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(JoinLeague);


const styles = StyleSheet.create({
    scrollView: {
        flex: 1,
        width: '100%',
        minHeight: '100%',
    },
    container : {
        width: '100%',
        minHeight: '100%',
        alignItems: 'center',
        justifyContent: 'center'
    }, 
    image: {
        width: '25%',
        height: 140,
        resizeMode: 'contain'        
    },
    text: {
        color: 'white',
        fontSize: 19,
        width: '75%',
        textAlign: 'center',
        marginVertical: 20
    },
    input: {        
        justifyContent: 'center',
        alignItems: 'center',
        borderColor: '#E4A647'
    },
    inputStyle: {
        width: '80%',
        textAlign: 'center',
        color: '#E4A647'
    },
    emphasize: {
        color: '#72E86C'
    },
    confirmText: {
        color: '#E4A647',
        fontWeight: 'bold',
        fontSize: 20
    },
    errorText: {
        color: '#A43B32',
        textAlign: 'center',
        fontSize: 17
    },
    button: {
        marginTop: 30
    }
});