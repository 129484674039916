import React from 'react';
import {
  StyleSheet, TouchableOpacity, Image, View,
  Linking, Dimensions, Text
} from 'react-native';
import PlayerView from './PlayerView';
import playgroundBg from '../../assets/team-bg-pitch-lights.png';


const playground = props => {
  const isChipActive = id => props.isReady &&
    props.chips &&
    props.chips.find(c => c.ids.free_id === id).active;

  const tripleCaptainActivated = isChipActive(1);
  const doubleViceCaptainActivated = isChipActive(5);
  const fullTeamActivated = isChipActive(3);
  console.log(props.ads)
  return (
    <React.Fragment>
      <View style={[styles.playground, props.containerStyle]}>
        <Image source={playgroundBg}
          style={[styles.playgroundImg, props.backgroundStyle]} />

        <View style={styles.adsContainer}>
          
          {props.ads && props.ads.length > 0 &&
            <TouchableOpacity style={styles.adImageContainer}
              onPress={() => window.open(props.ads[0].url, '_blank')}>
                
              <Image style={styles.adImage} source={{ uri: props.ads[0].image.url }} />
            </TouchableOpacity>}

          {props.ads && props.ads.length > 1 &&
            <TouchableOpacity style={styles.adImageContainer}
              onPress={() => window.open(props.ads[1].url, '_blank')}>
              <Image style={styles.adImage} source={{ uri: props.ads[1].image.url }} />
            </TouchableOpacity>}
        </View>

        {!props.groups &&
          <View style={styles.noPlayers}>
            <Text style={styles.noPlayersText}>Нямате налични играчи за този кръг !</Text>
          </View>
        }

        {props.groups &&
          <View style={styles.playgroundPlayers}>
            {props.groups.map(group => {
              return (
                <View style={styles.groupContainer} key={group.groupId}>
                  {group.players.map((player, i) =>
                    player !== null ?
                      <PlayerView key={player.playerId || player.id}
                        onPress={() => props.isReady ? props.onSelectPlayer(player, false) : props.onAddPlayer(i, group)}
                        selected={props.selectedPlayers && props.selectedPlayers.some(p => p.playerId === player.playerId)}
                        tripleCaptainActivated={tripleCaptainActivated}
                        doubleViceCaptainActivated={doubleViceCaptainActivated}
                        showPrice={props.showPrice}
                        isReady={props.isReady}
                        {...player} />
                      :
                      <PlayerView key={Math.random() * i}
                        onPress={() => props.onAddPlayer(i, group)}
                        nullPlayer={true} />)}
                </View>
              )
            })}
          </View>}
      </View>
      {props.isReady && props.reserves &&
        <View style={[styles.reserves, fullTeamActivated ? { backgroundColor: '#2053A4' } : null]}>
          {props.reserves.map(player =>
            <PlayerView
              key={player.playerId || player.id}
              showPrice={props.showPrice}
              isReady={props.isReady}
              selected={props.selectedPlayers && props.selectedPlayers.some(p => p.playerId === player.playerId)}
              tripleCaptainActivated={tripleCaptainActivated}
              doubleViceCaptainActivated={doubleViceCaptainActivated}
              onPress={() => props.onSelectPlayer(player, true)}
              {...player} />)}
        </View>}
    </React.Fragment>
  );
}

export default playground;

const styles = StyleSheet.create({
  playground: {
    height: '65%',
    width: '100%',
    alignItems: 'center',
    minHeight: '550px'
  },
  playgroundPlayers: {
    marginTop: '21%',
    width: '100%',
  },
  playgroundImg: {
    position: 'absolute',
    left: 0,
    width: '100%',
    resizeMode: 'stretch',
    height: '100%',
    zIndex: 0
  },
  noPlayers: {
    backgroundColor: 'black',
    width: '100%',
    position: 'absolute',
    top: '47%',
    zIndex: 3,
    paddingVertical: 20,
    alignItems: 'center',
    justifyContent: 'center',
    opacity: 0.84
  },
  noPlayersText: {
    zIndex: 30,
    color: '#E4A647',
    fontSize: 19
  },
  adsContainer: {
    height: 100,
    width: '90%',
    flexDirection: 'row',
    position: 'absolute',
    top: 0,
    left: 20,
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  adImageContainer: {
    width: 50,
    top: 45
  },
  adImage: {
    width: 50,
    height: 40,
    resizeMode: 'contain'
  },
  groupContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: 80,
    marginBottom: 20
  },
  reserves: {
    paddingVertical: 10,
    flexDirection: 'row',
    width: '100%',
    borderWidth: 1,
    borderColor: '#81C37C',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#41713B'
  }
});

