import { 
  SET_RESULTS, 
  SET_LIVESCORE
} from "../actions/actionTypes";
 
const initialState = {
    results: null,
    livescore: null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {

    case SET_RESULTS:
      return {
        ...state,
        results: action.results
      };

     case SET_LIVESCORE:
      return {
        ...state,
        livescore: action.livescore
      };

    default:
      return state;
  }
};

export default reducer;