import React from 'react';
import Background from '../../Common/components/Background';
import Input from '../../Common/components/Input';
import { 
    StyleSheet, 
    Text, 
    View, 
    Image, 
    Button,
    TouchableOpacity, 
    ScrollView, 
    Linking, 
    Clipboard 
} from 'react-native';
import privateLeague from '../../assets/private-league.png';
import sendImage from '../../assets/invite-friend.png';
import { connect } from 'react-redux';
import HeaderWithBack from '../../Common/components/HeaderWithBack';

class LeagueCreateSucess extends React.Component {

    static navigationOptions = {
        title: 'Частна лига',
    };

    goToOwnLeagues = () => this.props.navigation.navigate('OwnLeagues');

    render() {
        const newLeague = this.props.ownLeagues[this.props.ownLeagues.length-1];
        console.log(newLeague);        
        return (
            <View style={styles.container}>
                <Background />
                <HeaderWithBack title={"СЪЗДАЙ ЛИГА"} navigation={this.props.navigation}/> 
                <Image source={privateLeague} style={styles.image} />
                <View style={{width: '100%', alignItems: 'center', justifyContent: 'center', flex: 1}}>
                    <Text style={styles.text}>
                        Успешно създадохте лига:
                    </Text>
                    <Text style={styles.text}>
                        <Text style={styles.emphasize}>{newLeague.name}</Text>
                    </Text>
                    <Text style={[styles.text, {color: '#CC9747'}]}>
                        {newLeague.code}
                    </Text>
                    <TouchableOpacity onPress={() => Clipboard.setString(newLeague.code)}>
                        <Text style={styles.confirmText}>КОПИРАЙ</Text>
                    </TouchableOpacity>
                    <TouchableOpacity style={styles.button} onPress={this.goToOwnLeagues}>
                        <Image source={sendImage} style={styles.icon} />
                        <Text style={styles.confirmText}>ИЗПРАТИ ПОКАНИ</Text>
                    </TouchableOpacity>
                </View>
            </View>
        )
    }
}


const mapStateToProps = (state, ownProps) => {
  return {
    ownLeagues: state.ownLeagues.ownLeagues,
  }
}

export default connect(mapStateToProps)(LeagueCreateSucess);


const styles = StyleSheet.create({
    container : {
        width: '100%',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center'
    }, 
    image: {
        width: '25%',
        height: 100
    },
    text: {
        color: 'white',
        fontSize: 19,
        width: '75%',
        textAlign: 'center',
        marginVertical: 20
    },
    input: {        
        justifyContent: 'center',
        alignItems: 'center',
        borderColor: '#E4A647'
    },
    inputStyle: {
        width: '80%',
        textAlign: 'center',
        color: '#E4A647'
    },
    emphasize: {
        color: '#72E86C'
    },
    confirmText: {
        color: '#E4A647',
        fontWeight: 'bold',
        fontSize: 20
    },
    errorText: {
        color: '#A43B32',
        textAlign: 'center',
        fontSize: 17
    },
    button: {
        flexDirection: 'row',
        marginTop: 30
    },
    icon: {
        width: 30,
        height: 30,
        marginRight: 10
    }
});