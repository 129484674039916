import { 
  SET_TEAM_PLAYER_SHORT_INFO, 
  SET_TEAM_PLAYER_FULL_INFO, 
  SET_CAPTAIN, 
  SET_VICE_CAPTAIN, 
  SET_TEAM_PLAYER_STAT_FOR_ROUND 
} from "../actions/actionTypes";

const initialState = {
    playerShortInfo: null,    
    playerFullInfo: null, 
    playerStatInfo: null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_TEAM_PLAYER_STAT_FOR_ROUND: {
       return {
        ...state,
        playerStatInfo: action.playerInfo
      };
    }
    case SET_TEAM_PLAYER_SHORT_INFO:
      return {
        ...state,
        playerShortInfo: action.playerInfo
      };
    case SET_VICE_CAPTAIN: {
      const playerShortInfo = {...state.playerShortInfo};
      if (action.playerId === playerShortInfo.playerId) {
        playerShortInfo.isCaptain = false;
        playerShortInfo.isViceCaptain = true;
      } else {
         playerShortInfo.isViceCaptain = false;
      }
      return {
        ...state,
        playerShortInfo
      }
    }
    case SET_CAPTAIN: {
     const playerShortInfo = {...state.playerShortInfo};
      if (action.playerId === playerShortInfo.playerId) {
        playerShortInfo.isCaptain = true;
        playerShortInfo.isViceCaptain = false;
      } else {
         playerShortInfo.isCaptain = false;
      }
      return {
        ...state,
        playerShortInfo
      }
    }
    case SET_TEAM_PLAYER_FULL_INFO:
      return {
        ...state,
        playerFullInfo: action.playerInfo
      };
    default:
      return state;
  }
};

export default reducer;