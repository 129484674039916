import React from "react";
import {
  StyleSheet,
  TouchableOpacity,
  Text,
  View,
  Image
} from "react-native";
import EStyleSheet from 'react-native-extended-stylesheet';
import PlayerView from '../../Common/components/PlayerView';
import PopupDialog from '../../Common/components/PopupDialog';
import hand from '../../assets/hand.svg.png';
import banIcon from '../../assets/banned-player.svg.png';
import injuredIcon from '../../assets/injured-player.svg.png';
import transferedIcon from '../../assets/transfered-player.svg.png';
import { getPlayerPointsColor } from "../../Common/utils/utils.js";

import { connect } from 'react-redux';
import { getPlayerShortInfo } from '../actions/players';


class PlayerPopup extends React.Component {
    showFullProfile = () => {
        this.props.navigation.navigate('PlayerProfile', {
            playerId : this.props.playerId
        });
    }

    componentDidMount() {
        if (this.props.playerId)  {
            this.props.getPlayerShortInfo(this.props.playerId);
        }
    }

    render() {
        if (!this.props.playerId || !this.props.playerInfo) return null;
        
        return (
            <PopupDialog isVisible={true} onDismiss={this.props.onDismiss}>
                <View style={styles.headerContainer}>
                    <View>
                        <Image source={{uri:this.props.playerInfo.image}} style={styles.playerImage} />
                        { this.props.playerInfo.isBanned && 
                            (<View style={{flexDirection:'row'}}> 
                                <Image source={banIcon} style={styles.specialIconStyle} />
                                <Text>Играчът е наказан</Text>
                                
                            </View>)  
                        }
                        { this.props.playerInfo.isInjured && 
                            (<View style={{flexDirection:'row'}}> 
                                <Image source={injuredIcon} style={styles.specialIconStyle} />
                                <Text>Играчът е контузен</Text>
                                
                            </View>)  
                        }
                        { this.props.playerInfo.isTransfered && 
                            (<View style={{flexDirection:'row'}}> 
                                <Image source={transferedIcon} style={styles.specialIconStyle} />
                                <Text>Играчът е трансфериран</Text>
                            </View>)  
                        }
                    </View>

                    <View style={styles.playerInfoContainer}>
                        <Text style={styles.name}>{this.props.playerInfo.name}</Text>
                        <Text>{this.props.playerInfo.position} / {this.props.playerInfo.team}</Text>
                        <Text style={styles.points}>{this.props.playerInfo.totalPoints} Точки </Text>
                        <TouchableOpacity onPress={this.showFullProfile} 
                                        style={styles.fullProfileButton}>
                            <Text style={styles.profileButtonText}>Пълен Профил</Text>
                        </TouchableOpacity>
                    </View>
                </View>
                <View style={styles.programInfo}>
                    <View style={styles.roundsContainer}>
                        <View style={[styles.roundRow, {borderBottomWidth: 1}]}>
                            {this.props.playerInfo.previousMatches.length > 1 && 
                            <View style={styles.roundDetails}>
                                <Text style={styles.roundInfoText}>{this.props.playerInfo.previousMatches[1].roundName}</Text>
                                <View style={styles.teamInfo}>
                                    <Text style={styles.roundInfoText}>{this.props.playerInfo.previousMatches[1].teamShort}</Text>
                                    <Image style={styles.teamIcon} source={{uri:this.props.playerInfo.previousMatches[1].teamLogo}} />
                                </View>
                                <View style={[styles.pointsContainer, getPlayerPointsColor(this.props.playerInfo.previousMatches[1].points, true)]}>
                                    <Text style={styles.roundInfoText}>{this.props.playerInfo.previousMatches[1].points} т.</Text>
                                </View>
                            </View>}
                            {this.props.playerInfo.nextMatches.length > 0 && 
                            <View style={styles.roundDetails}>
                                <Text style={styles.roundInfoText}>{this.props.playerInfo.nextMatches[0].roundName}</Text>
                                <View style={styles.teamInfo}>
                                    <Text style={styles.roundInfoText}>{this.props.playerInfo.nextMatches[0].teamShort}</Text>
                                    <Image style={styles.teamIcon} source={{uri:this.props.playerInfo.nextMatches[0].teamLogo}} />
                                </View>
                                <Text style={styles.roundInfoText}>{this.props.playerInfo.nextMatches[0].date.split(" ")[0]}</Text>
                            </View>}
                        </View>
                        <View style={styles.roundRow}>
                            {this.props.playerInfo.previousMatches.length > 0 && 
                            <View style={styles.roundDetails}>
                                <Text style={styles.roundInfoText}>{this.props.playerInfo.previousMatches[0].roundName}</Text>
                                <View style={styles.teamInfo}>
                                    <Text style={styles.roundInfoText}>{this.props.playerInfo.previousMatches[0].teamShort}</Text>
                                    <Image style={styles.teamIcon} source={{uri:this.props.playerInfo.previousMatches[0].teamLogo}} />
                                </View>
                                <View style={[styles.pointsContainer, getPlayerPointsColor(this.props.playerInfo.previousMatches[0].points, true)]}>
                                    <Text style={styles.roundInfoText}>{this.props.playerInfo.previousMatches[0].points} т.</Text>
                                </View>
                            </View>}
                            {this.props.playerInfo.nextMatches.length > 1 && 
                            <View style={styles.roundDetails}>
                                <Text style={styles.roundInfoText}>{this.props.playerInfo.nextMatches[1].roundName}</Text>
                                <View style={styles.teamInfo}>
                                    <Text style={styles.roundInfoText}>{this.props.playerInfo.nextMatches[1].teamShort}</Text>
                                    <Image style={styles.teamIcon} source={{uri:this.props.playerInfo.nextMatches[1].teamLogo}} />
                                </View>
                                <Text style={styles.roundInfoText}>{this.props.playerInfo.nextMatches[1].date.split(" ")[0]}</Text>
                            </View>}
                        </View>
                    </View>
                    {this.props.onConfirm && <TouchableOpacity 
                        style={styles.buttonContainer} 
                        onPress={this.props.onConfirm}>
                        <Text style={styles.buttonText}>
                            {this.props.buttonText}
                        </Text>
                        <Image source={hand} style={styles.icon} />
                    </TouchableOpacity>}
                </View>
            </PopupDialog>
        );
    }
};


const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getPlayerShortInfo: playerId => dispatch(getPlayerShortInfo(playerId))
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    playerInfo: state.playerInfo.playerShortInfo,
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(PlayerPopup);

const styles = EStyleSheet.create({
  playerImage: {
      height: '15rem',
      width: '12rem',
      borderRadius: 10
  },
  headerContainer: {
      flexDirection: 'row',
      width: '100%',
      padding: 10,
  },
  name: {
      fontWeight: 'bold',
      fontSize: 17,
      marginBottom: 5
  },
  points: {
      fontWeight: 'bold',
      color: '#2053A4',
      marginTop: 5,
      marginBottom: 3,
      fontSize: 12
  },
  fullProfileButton: {
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: '#2053A4',
      paddingVertical: 3,
      borderRadius: 5
  },
  profileButtonText: {
      color: 'white',
      fontSize: 15
  },
  specialIconStyle: {
    width: 20,
    height: 20,
    zIndex: 3
  },
  roundInfoText: {
      fontSize: 12
  },
  playerInfoContainer: {
      marginLeft: 10
  },
  buttonContainer: {
      width: '100%',
      height: 50,
      paddingHorizontal: 5,
      alignItems: 'center',
      backgroundColor: 'black',
      justifyContent: 'center',
      borderBottomLeftRadius: 10,
      borderBottomRightRadius: 10,
      flexDirection: 'row'
  }, 
  buttonText: {
    textAlign: 'center',
    color: 'white',
    fontSize: 20,
    fontWeight: 'bold',
    marginRight: 10
  }, 
  icon: {
      width: 30,
      height: 30
  },
  radioGroup: {
      flexDirection: 'row',
      alignItems: 'center'
  },
  roundsContainer: {
      backgroundColor: '#E5EAF4',
      width: '100%',
      padding: 3
  },
  roundRow: {
      flexDirection: 'row',
      paddingVertical: 2,
      alignItems: 'center'
  },
  roundDetails: {
      flexDirection: 'row',
      width: '50%',
      alignItems: 'center',
      justifyContent: 'space-between'
  },
  teamInfo: {
      flexDirection: 'row',
      alignItems: 'center'
  },
  teamIcon: {
      width: 15,
      height: 15,
      marginHorizontal: 5,
      marginVertical: 3
  },
  pointsContainer: {
      backgroundColor: '#E4A647',
      alignItems: 'center',
      justifyContent: 'center',
      paddingHorizontal: 4,
      paddingVertical: 2,
      borderRadius: 3,
      borderWidth: 1,
      borderColor: 'white',
      marginRight: 10
  },
  changesContainer: {
      backgroundColor: '#1D2640',
      flexDirection: 'row',
      paddingVertical: 15,
      paddingHorizontal: 5,
      width: '100%',
      justifyContent: 'space-between'
  },
  iconStyle: {
    width: 60,
    height: 90
  }
});
