import React from 'react';
import { StyleSheet, Text, View } from 'react-native';

const containerWithHeader = (props) => {
    return (
        <View style={[styles.container, props.style]}>
            <View style={styles.headerContainer}>
                <Text style={styles.title}>{props.title}</Text>
            </View>
            {props.children}
        </View>
    );
}

export default containerWithHeader;

const styles = StyleSheet.create({
  container : {
      width: '90%',
      backgroundColor: 'white',
      borderRadius: 10,
      marginVertical: 10,
      overflow: 'hidden',
  },
  headerContainer: {
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: '#CCD4E1',
      paddingVertical: 17
  },
  title: {
      fontWeight: 'bold',
      fontSize: 22
  }
});

