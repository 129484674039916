import React from "react";
import ReactDOM from "react-dom";
import App from "./App/App";
import { BrowserRouter as Router } from "react-router-dom";
import registerServiceWorker from "./App/registerServiceWorker";
import configureStore from './App/configureStore';
import { Provider } from 'react-redux';
import { MuiPickersUtilsProvider } from 'material-ui-pickers';
import MomentUtils from '@date-io/moment';


const store = configureStore();

ReactDOM.render(
  <Provider store={store}>
    <Router>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <App />
      </MuiPickersUtilsProvider>
    </Router>
  </Provider>,
  document.getElementById("root")
);
registerServiceWorker();