export const SET_TEAM = "SET_TEAM";
export const SET_AVAILABLE_PLAYERS = "SET_AVAILABLE_PLAYERS";
export const SORT_PLAYERS = "SORT_PLAYERS";
export const REMOVE_AVAILABLE_PLAYER = "REMOVE_AVAILABLE_PLAYER";
export const RESTORE_AVAILABLE_PLAYER = "RESTORE_AVAILABLE_PLAYER";
export const SET_GROUP = "SET_GROUP";
export const RESET_TEAM = "RESET_TEAM";
export const SET_TEAM_READY = "SET_TEAM_READY";
export const MAKE_SWAP = "MAKE_SWAP";
export const SCHEME_CHANGED = "SCHEME_CHANGED";
export const CHIP_USED = "CHIP_USED";

export const SET_TEAM_PLAYER_FULL_INFO = "SET_TEAM_PLAYER_FULL_INFO";
export const SET_TEAM_PLAYER_SHORT_INFO = "SET_TEAM_PLAYER_SHORT_INFO";
export const SET_TEAM_PLAYER_STAT_FOR_ROUND = "SET_TEAM_PLAYER_STAT_FOR_ROUND";

export const SET_CAPTAIN = "SET_CAPTAIN";
export const SET_VICE_CAPTAIN = "SET_VICE_CAPTAIN";




