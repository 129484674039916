import React from 'react';
import { StyleSheet, Text, View, Image, TouchableOpacity, ScrollView, StatusBar } from 'react-native';
import Background from '../../Common/components/Background';
import HeaderWithBack from '../../Common/components/HeaderWithBack';

class Awards extends React.Component {

    render() {
        return (
            <React.Fragment>
                <HeaderWithBack title="Награди" navigation={this.props.navigation} />
                <ScrollView style={styles.scrollView} contentContainerStyle={styles.container}>
                    <Background />
                    <Text style={styles.headingText}>НАГРАДИ НА BAMF Fantasy Manager</Text>
                    <View style={styles.htmlContainer}>
                        {renderHTML()}
                    </View>
                </ScrollView>
            </React.Fragment>
        )
    }
}

export default Awards;

const styles = StyleSheet.create({
    scrollView: {
        width: '100%',
    },
    container: {
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center'
    },
    headingText: {
        fontWeight: 'bold',
        color: 'white',
        fontSize: 24,
        marginBottom: 12,
        marginLeft: 15,
        width: '100%',
        marginTop: 200
    },
    htmlContainer: {
        width: '100%',
        flex: 1,
        backgroundColor: 'white'
    }
});


function renderHTML() {
    return (
        <div class="kc-elm kc-css-569281 kc_text_block">
            <h3>СЕДМИЧНА НАГРАДА ЗА ПОБЕДИТЕЛ НА КРЪГА</h3>
            <ul>
                <li>Различни награди от партньори на БАМФ</li>
            </ul>
            <h3>ГОЛЕМИ НАГРАДИ ЗА ПОБЕДИТЕЛИТЕ В ИГРАТА</h3>
            <p><strong>1-во място:</strong></p>
            <ul>
                <li>СПА уикенд за двама в български курорт</li>
            </ul>
            <p><strong>2-ро място:</strong></p>
            <ul>
                <li>Фланелка на Националния отбор на България по футбол</li>
            </ul>
            <p><strong>3-то място:</strong></p>
            <ul>
                <li>Футболна топка</li>
            </ul>
            <h3><u>ВАЖНО:</u></h3>
            <p>* На всички победители ще бъдат изпращани персонално имейли с информация кога и как ще
си получат наградите. Моля, участвайте в играта с реални и използвани имейли!</p>

        </div>);
}