import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
 
 export const PickerListPopup = (props) => {
    if (!props.data) return null;
    return(
    <Dialog onClose={props.onClose} aria-labelledby="dialog-title" open={props.open}
    style={{maxHeight: 500}}>
        <DialogTitle id="dialog-title">{props.title}</DialogTitle>
        <div>
          <List>
            {props.data.map(item => (
              <ListItem button onClick={() => props.onClose(item)} key={item.key}>
                <ListItemText primary={item.label} />
              </ListItem>
            ))}
          </List>
        </div>
      </Dialog>
     )
 }
 
 export default PickerListPopup;