import React from 'react';
import { StyleSheet, TextInput, View, Text, Image } from 'react-native';
// import { FontAwesome } from '@expo/vector-icons';


const input = props => {
    const errorText = props.hasError ?
    <Text style={styles.errorText}>{props.errorText}</Text> : null;

    let errorStyles = null;
    let errorInputStyles = null;
    let placeholderTextColor = props.placeholderTextColor ? 
        props.placeholderTextColor : 'darkgray';

    if (props.hasError) {
        errorStyles = {borderColor : '#FF891F'};
        errorInputStyles = {color: '#FF891F'};
        placeholderTextColor = '#FF891F';
    }

    //<FontAwesomeIcon icon="coffee" />
    // const icon = props.icon ? <FontAwesome style={[styles.iconStyle, errorInputStyles]} size={20} 
    // name={props.icon}/> : null;
    const icon = null;

    const image = props.image ? <Image style={[styles.imageStyle]} 
    source={props.image}/> : null;

    return (
        <View style={[styles.container, props.containerStyle]}>
            <View style={[styles.inputContainer, props.style, errorStyles]}>
                {image}
                {icon}
                <TextInput     
                    {...props}                    
                    style={[styles.input, props.inputStyle, errorInputStyles]}  
                    underlineColorAndroid='transparent'
                    placeholderTextColor={props.placeholderTextColor || 'white'}/>
            </View>
            {errorText}
        </View>
    );
}



const styles = StyleSheet.create({
    container: {
        marginVertical: 7,
        width: '100%'
    },
    inputContainer: {
        flexDirection: 'row',
        borderBottomWidth: 1,
        borderColor: '#bbb',        
    },
    input: {
        flex:1,
        lineHeight: 20,
        fontSize: 19,
        color: 'white'
    },
    imageStyle: {
        width:20,
        height: 20,
        marginTop: 10,
        marginBottom: 5,
        marginHorizontal: 10
    },
    iconStyle: {
        color:'#72E86C', 
        fontSize: 20, 
        marginTop: 10,
        marginBottom: 5,
        marginHorizontal: 10
    },
    errorText: {
        fontSize: 14,
        color: '#FF891F',
    }
});

export default input;