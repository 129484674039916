import { SET_TEAM_PLAYER_SHORT_INFO, SET_CAPTAIN, 
SET_VICE_CAPTAIN, SET_TEAM_PLAYER_FULL_INFO, SET_TEAM_PLAYER_STAT_FOR_ROUND } from "./actionTypes";
import { BASE_URL } from '../../Common/config/config';
import { authGetToken } from '../../Common/actions/token';
import { uiStartLoading, uiStopLoading } from '../../Common/actions/ui';
import { setError } from '../../Common/actions/errors';
import { getTeam as getTeamForTransfers } from '../../Transfers/actions/team';

export const getUserStatsForRound = (playerId, roundId, forPoints) => {
    return dispatch => {
    dispatch(authGetToken())
    .catch(err => {
        console.log('No auth token exists!');
        alert("Please login again, your session is expired!");
    })
    .then(token => {
      const typeOfPlayer = forPoints ? 'teamplayer' : 'player';
      return fetch(BASE_URL + '/users/team/'+typeOfPlayer+'/'+playerId+'/stats/round/'+roundId, {
          headers: {
              "Content-Type": "application/json; charset=utf-8",
              "Authorization": token
          }
      });
    })
    .catch(err => console.log(err))
    .then(res => res.json())
    .then(res => {
      if (res.has_error && res.status === 503) {
        dispatch(setError('Кръга е затворен!', 503));
        return;
      }

        if (!res.data.image) {
          res.data.image = BASE_URL + "/uploads/nologo.png"; 
        } else {
          res.data.image = BASE_URL + res.data.image;
        }
        res.data.guestLogo = BASE_URL + res.data.guestLogo;
        res.data.hostLogo = BASE_URL + res.data.hostLogo;
        
        dispatch(setPlayerStatForRound(res.data));
    });
  };
}

export const setPlayerStatForRound = playerInfo => {
  return {
    type: SET_TEAM_PLAYER_STAT_FOR_ROUND,
    playerInfo
  };
};

export const getUserFullProfile = playerId => {
    return dispatch => {
    dispatch(authGetToken())
    .catch(err => {
        console.log('No auth token exists!');
        alert("Please login again, your session is expired!");
    })
    .then(token => {
        return fetch(BASE_URL + '/users/team/player/'+playerId+'/profile', {
            headers: {
                "Content-Type": "application/json; charset=utf-8",
                "Authorization": token
            }
        });
    })
    .catch(err => console.log(err))
    .then(res => res.json())
    .then(res => {
      if (res.has_error && res.status === 503) {
        dispatch(setError('Кръга е затворен!', 503));
        return;
      }

      if (!res.data.image) {
        res.data.image = BASE_URL + "/uploads/nologo.png"; 
      } else {
        res.data.image = BASE_URL + res.data.image;
      }
      if (Array.isArray(res.data.rounds)) {
        res.data.rounds.forEach(round => {
            round.guestLogo = BASE_URL + round.guestLogo;
            round.hostLogo = BASE_URL + round.hostLogo;
        });
      }
      dispatch(setPlayerFullInfo(res.data))
    });
  };
}

export const setPlayerFullInfo = playerInfo => {
  return {
    type: SET_TEAM_PLAYER_FULL_INFO,
    playerInfo
  };
};


export const getPlayerInfoWithChanges = playerId => {
  return dispatch => {
    dispatch(authGetToken())
    .catch(err => {
        console.log('No auth token exists!');
        alert("Please login again, your session is expired!");
    })
    .then(token => {
        return fetch(BASE_URL + '/users/team/player/'+playerId+'/info', {
            headers: {
                "Content-Type": "application/json; charset=utf-8",
                "Authorization": token
            }
        });
    })
    .catch(err => console.log(err))
    .then(res => res.json())
    .then(res => {
      console.log(res);
      if (res.has_error && res.status === 503) {
        dispatch(setError('Кръга е затворен!', 503));
        return;
      }

      res.data.playerId = playerId;
      if (!res.data.image) {
        res.data.image = BASE_URL + "/uploads/nologo.png"; 
      } else {
        res.data.image = BASE_URL + res.data.image;
      }
      if (Array.isArray(res.data.possibleChanges)) {
        res.data.possibleChanges.forEach(change => {
            change.iconUrl = BASE_URL + change.iconUrl;
            change.playerId = change.id;
        });
      }
      if (Array.isArray(res.data.previousMatches)) {
        res.data.previousMatches.forEach(match => {
          match.teamLogo = BASE_URL + match.teamLogo;          
        });
      }

      if (Array.isArray(res.data.nextMatches)) {
        res.data.nextMatches.forEach(match => {
           match.teamLogo = BASE_URL + match.teamLogo;        
        })
      }
      
      dispatch(setPlayerShortInfo(res.data));
    });
  };
}

export const setPlayerShortInfo = playerInfo => {
  return {
    type: SET_TEAM_PLAYER_SHORT_INFO,
    playerInfo
  };
};


export const setCaptain = playerId => {
  return dispatch => {
    dispatch(authGetToken())
    .catch(err => {
        console.log('No auth token exists!');
        alert("Please login again, your session is expired!");
    })
    .then(token => {
        return fetch(BASE_URL + '/users/team/player/'+playerId+'/captain', {
            method: 'PUT',
            headers: {
                "Content-Type": "application/json; charset=utf-8",
                "Authorization": token
            }
        });
    })
    .catch(err => console.log(err))
    .then(res => res.json())
    .then(res => {      
      if (res.has_error && res.status === 503) {
        dispatch(setError('Кръга е затворен!', 503));
        return;
      }

      if (res.has_error) {
        alert(res.message);
      }
      dispatch(setPlayerCaptain(playerId));
      dispatch(getTeamForTransfers());
    });
  };
}

export const setPlayerCaptain = playerId => {
  return {
    type: SET_CAPTAIN,
    playerId
  };
};


export const setViceCaptain = playerId => {
  return dispatch => {
    dispatch(authGetToken())
    .catch(err => {
        console.log('No auth token exists!');
        alert("Please login again, your session is expired!");
    })
    .then(token => {
        return fetch(BASE_URL + '/users/team/player/'+playerId+'/vice', {
            method: 'PUT',
            headers: {
                "Content-Type": "application/json; charset=utf-8",
                "Authorization": token
            }
        });
    })
    .catch(err => console.log(err))
    .then(res => res.json())
    .then(res => {
      if (res.has_error && res.status === 503) {
        dispatch(setError('Кръга е затворен!', 503));
        return;
      }

      if (res.has_error) {
        alert(res.message);
      }
      dispatch(setPlayerViceCaptain(playerId));
      dispatch(getTeamForTransfers());
    });
  };
}

export const setPlayerViceCaptain = playerId => {
  return {
    type: SET_VICE_CAPTAIN,
    playerId
  };
};