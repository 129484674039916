import { 
  SET_NEWS, 
} from "../actions/actionTypes";
 
const initialState = {
    news: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {

    case SET_NEWS:
      return {
        ...state,
        news: action.news
      };

    default:
      return state;
  }
};

export default reducer;